<template>
  <div class="ma-3">
    <div class="title mb-3">
      Skills management <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : 'Edit' }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div class="field-width">
        <div class="title font-weight-light mb-3">
          Skill information
        </div>
        <v-select
          v-model="application"
          v-focus
          label="Application"
          :items="apps"
          class="field-width"
          outline
          :rules="[ v => !!v || 'This field is required' ]"
          @change="onAppChange"
        />
        <v-autocomplete
          v-model="skill.topic_id"
          v-focus
          :items="computedTopics"
          item-text="name"
          item-value="id"
          outline
          label="Concept"
          :rules="[ v => !!v || 'This field is required' ]"
          @change="onTopicChange"
        />
        <v-select
          v-model="skill.role"
          v-focus
          :items="isFreelances ? skillRolesFreelances : skillRolesBeesecure"
          outline
          label="Role"
          :rules="[ v => (Object.values(Consts.SKILL_ROLE).indexOf(v) > -1) || 'This field is required' ]"
          @change="onRoleChange"
        />
        <v-checkbox
          v-if="canBeFormer"
          v-model="skill.is_former"
          label="Former"
          :true-value="1"
          :false-value="0"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-settings-users-show-skills'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import GroupsMixin from "@/mixins/groups";
import GlobalMixin from "@/mixins/global";
import Consts from "@/consts";

export default {
  mixins: [GroupsMixin, GlobalMixin],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      skill: {
        topic_id: null,
        role: null,
        is_former: 0
      },
      topics: [],
      formValid: false,
      application: this.getDefaultApp(),
      Consts
    };
  },
  computed: {
    computedTopics(){
      if(!this.application){
        return []
      }

      const arr = []

      this.topics.forEach(t => {
        if(t.application === this.application){
          arr.push(t)
        }
      })

      return arr
    },
    isNew() {
      return this.$route.params.skill_id === "new";
    },
    isFreelances() {
      let is = false;

      if (this.skill.topic_id) {
        const t = this.getTopic(this.skill.topic_id);
        if (t && t.application === Consts.APPLICATION.FREELANCES) {
          is = true;
        }
      }

      return is;
    },
    canBeFormer() {
      if (this.skill.role === Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR) {
        return true;
      }
      if (this.skill.role === Consts.SKILL_ROLE.EXPERT) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetch();
    } else {
      this.fetchParams();
    }
  },
  methods: {
    onAppChange(){
      this.skill.topic_id = null
    },
    onTopicChange() {
      this.skill.role = null;
      this.skill.is_former = 0;
    },
    onRoleChange(r) {
      if (
        r !== Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR &&
        r !== Consts.SKILL_ROLE.EXPERT
      ) {
        this.skill.is_former = 0;
      }
    },
    fetch() {
      Params.get(
        `users/${this.user.id}/skills`,
        this.$route.params.skill_id
      ).then(res => {
        this.skill = res.data;
        this.application = res.data.topic.application
        this.fetchParams();
      });
    },
    fetchParams() {
      Params.getList("topics", {
        is_enabled: 1,
        current_id: this.skill.topic_id
      }).then(res => {
        this.topics = res.data;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create(`users/${this.user.id}/skills`, this.skill).then(() => {
          this.$router.push({ name: "admin-settings-users-show-skills" });
          this.$snotify.success(`Skill has been created`);
        });
      } else {
        Params.update(
          `users/${this.user.id}/skills`,
          this.skill.id,
          this.skill
        ).then(() => {
          this.$router.push({ name: "admin-settings-users-show-skills" });
          this.$snotify.success(`Skill has been updated`);
        });
      }
    },
    getTopic(id) {
      let topic = null;

      for (let i = 0; i < this.topics.length; i++) {
        const t = this.topics[i];
        if (t.id === id) {
          topic = t;
        }
      }

      return topic;
    }
  }
};
</script>
