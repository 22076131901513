<template>
  <div class="ma-3">
    <div class="title mb-3">
      Forms
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <div
        layout="column"
        class="field-width"
      >
        <div
          layout="row"
          layout-align="start center"
          class="ml-3"
        >
          <v-text-field
            v-model="filters.keywords"
            solo
            label="Keywords"
            hide-details
            @keyup.enter="fetch"
          />
          <v-btn
            depressed
            color="primary"
            @click="fetch"
          >
            Filter
          </v-btn>
          or
          <a
            href="#"
            class="ml-2 black--text"
            @click="resetFilters"
          >Cancel</a>
        </div>

        <div>
          <v-btn
            flat
            class="mt-3"
            :to="{name: 'admin-forms-edit', params: {id: 'new'}}"
          >
            <v-icon
              left
              color="primary"
            >
              mdi-plus-box
            </v-icon>
            Add a new form
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="forms"
          style="max-width: 1000px"
          :pagination.sync="pagination"
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td><router-link :to="{name: 'admin-forms-fields-index', params: {id: item.id}}">{{ item.name }}</router-link></td>
            <td layout="row">
              <v-btn
                class="mx-0"
                icon
                :to="{name: 'admin-forms-edit', params: {id: item.id}}"
                :disabled="!isAppCoord(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <delete-button
                :label="item.name"
                :disabled="!! item.has_been_used_in_evaluations || !isAppCoord(item)"
                @confirm="deleteImpl(item)"
              />
              <v-menu
                v-model="openArchiveModal[item.id]"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                :disabled="! item.has_been_used_in_evaluations || !isAppCoord(item)"
              >
                <v-btn
                  slot="activator"
                  title="Archive"
                  class="mx-0"
                  icon
                  :disabled="! item.has_been_used_in_evaluations || !isAppCoord(item)"
                >
                  <v-icon>mdi-archive-settings</v-icon>
                </v-btn>

                <v-card>
                  <v-card-title class="title">
                    Archiving this form ?
                  </v-card-title>
                  <v-card-text>Are you sure you want to archive {{item.name}} ?</v-card-text>
                  <v-card-actions
                    layout="row"
                    layout-align="end center"
                  >
                    <v-btn
                      flat
                      @click="openArchiveModal[item.id] = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      flat
                      color="error"
                      @click="archive(item)"
                    >
                      Archive
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import DeleteButton from "@/components/shared/DeleteButton.vue"
    import GlobalMixin from "@/mixins/global"

    export default {
        components: {DeleteButton},
        mixins: [GlobalMixin],
        data () {
            return {
                pagination: {
                    rowsPerPage: 10
                },
                headers: [
                    { text: 'Form', value: 'name' },
                    { text: 'Actions', sortable: false, width: "80px" }
                ],
                filters: {
                    keywords: null,
                },
                forms: [],
                openArchiveModal: []
            }
        },
        mounted () {
            this.fetch();
        },
        methods: {
            fetch () {
                Params.getList('forms', this.filters).then(res => {
                    this.forms = res.data;
                })
            },
            resetFilters () {
                this.filters = {keywords: null};
                this.fetch();
            },
            deleteImpl (item) {
                Params.delete('forms', item.id).then(() => {
                    this.fetch();
                    this.$snotify.success(`The form "${item.name}" has been deleted`)
                })
            },
            archive (item) {
              this.openArchiveModal[item.id] = false
              Params.archive('forms', item.id).then(() => {
                this.fetch();
                this.$snotify.success(`The form "${item.name}" has been archived`)
              })
            }
        }
    }
</script>
