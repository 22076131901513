<template>
  <div class="ma-3">
    <div class="title mb-3">
      Forms for BEE SECURE types of sessions
    </div>

    <div
      layout="column"
      layout-align="center center"
    >
      <v-data-table
        :headers="headers"
        :items="bstypes"
        style="max-width: 1000px"
        :pagination.sync="pagination"
      >
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td><router-link :to="{name: 'admin-forms-bstypes-edit', params: {id: item.id}}">{{ item.type_name }}</router-link></td>
          <td>
            <router-link v-if="item.daggesrapport" :to="{name: 'admin-forms-fields-index', params: {id: item.daggesrapport_form_id}}">{{ item.daggesrapport.name }}</router-link>
            <span v-else>-</span>
          </td>
          <td>
            <router-link v-if="item.evaluation" :to="{name: 'admin-forms-fields-index', params: {id: item.evaluation_form_id}}">{{ item.evaluation.name }}</router-link>
            <span v-else>-</span>
          </td>
          <td>
            <router-link v-if="item.teachers" :to="{name: 'admin-forms-fields-index', params: {id: item.teachers_form_id}}">{{ item.teachers.name }}</router-link>
            <span v-else>-</span>
          </td>
          <td layout="row">
            <v-btn
              class="mx-0"
              icon
              :to="{name: 'admin-forms-bstypes-edit', params: {id: item.id}}"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
    import Params from '@/api/params'
    import GlobalMixin from "@/mixins/global"

    export default {
        mixins: [GlobalMixin],
        data () {
          return {
            pagination: {
                rowsPerPage: -1
            },
            headers: [
                { text: 'Session type', value: 'type_name' },
                { text: 'Daggesrapport', value: 'daggesrapport.name' },
                { text: 'Individual evaluation', value: 'evaluation.name' },
                { text: 'Teachers evaluation', value: 'teachers.name' },
                { text: 'Actions', sortable: false, width: "80px" }
            ],
            bstypes: [],
            openArchiveModal: []
          }
        },
        mounted () {
           this.fetch();
        },
        methods: {
          fetch () {
              Params.getList('bstypes-forms').then(res => {
                  this.bstypes = res.data;
              })
          }
        }
    }
</script>
