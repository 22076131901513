<template>
  <div class="ma-3">
    <div class="title mb-3">
      Forms
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? 'New form' : form.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Form information
        </div>
        <v-text-field
          v-model="form.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-forms-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import GlobalMixin from "@/mixins/global";
import Auth from "@/plugins/auth";
import Consts from "@/consts";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      form: {
        name: null,
      },
      formValid: false,
      Consts,
      Auth
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      Params.get("forms", this.$route.params.id).then(res => {
        this.form = res.data;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
			if (this.isNew) {
        Params.create("forms", this.form).then(() => {
          this.$router.push({ name: "admin-forms-index" });
          this.$snotify.success(
            `The form "${this.form.name}" has been created`
          );
        });
      } else {
        Params.update("forms", this.form.id, this.form).then(() => {
          this.$router.push({ name: "admin-forms-index" });
          this.$snotify.success(
            `The form "${this.form.name}" has been updated`
          );
        });
      }
    }
  }
};
</script>
