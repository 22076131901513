<template>
  <div class="ma-3">
    <div class="title mb-3">
      Forms for BEE SECURE "{{type.type_name}}" sessions
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Forms selection
        </div>
        <v-autocomplete
          v-model="type.daggesrapport_form_id"
          label="Daggesrapport form"
          :items="forms"
          item-text="name"
          item-value="id"
          class="field-width"
          outline
          clearable
          :open-on-clear="false"
          @click:clear="clearFormField('daggesrapport_form_id')"
        />
        <v-autocomplete
          v-model="type.evaluation_form_id"
          label="Individual evaluation form"
          :items="forms"
          item-text="name"
          item-value="id"
          class="field-width"
          outline
          clearable
          :open-on-clear="false"
          @click:clear="clearFormField('evaluation_form_id')"
        />
        <v-autocomplete
          v-if="type.type === Consts.SCHOOL"
          v-model="type.teachers_form_id"
          label="Teachers evaluation form"
          :items="forms"
          item-text="name"
          item-value="id"
          class="field-width"
          outline
          clearable
          :open-on-clear="false"
          @click:clear="clearFormField('teachers_form_id')"
        />
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-forms-bstypes-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import GlobalMixin from "@/mixins/global";
import Consts from "@/consts";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      type: {
        daggesrapport_form_id: null,
        evaluation_form_id: null,
        teachers_form_id: null
      },
      formValid: false,
      forms: [],
      Consts
    };
  },
  mounted() {
    Params.getList('forms', {sortBy: 'name'}).then(res => {
        this.forms = res.data;
    })
    this.fetch();
  },
  methods: {
    clearFormField(f) {
      this.$nextTick(() => {
        this.type[f] = null
      })
    },
    fetch() {
      Params.get("bstypes-forms", this.$route.params.id).then(res => {
        this.type = res.data;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      Params.update("bstypes-forms", this.type.id, this.type).then(() => {
        this.$router.push({ name: "admin-forms-bstypes-index" });
        this.$snotify.success(
          `The forms of the sessions type "${this.type.type_name}" have been updated`
        );
      });
    }
  }
};
</script>
