<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Concepts management
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? 'New' : topic.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Concept information
        </div>
        <v-text-field
          v-model="topic.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-if="Auth.user.application == Consts.APPLICATION.ALL"
          v-model="topic.application"
          v-focus
          label="Application"
          :items="apps"
          class="field-width"
          outline
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-select
          v-model="topic.teams_ids"
          label="Teams"
          :items="teams"
          item-text="name"
          item-value="id"
          class="field-width"
          multiple
          outline
        />
        <v-text-field
          v-if="topic.application === Consts.APPLICATION.BEESECURE"
          v-model="topic.slug"
          outline
          label="Slug (for sync)"
          class="field-width"
        />
        <!-- Si application == FREELANCES-->
        <template v-if="topic.application === Consts.APPLICATION.FREELANCES">
          <!-- Gestion des formulaires | mécanique commune aux deux applications-->
          <v-autocomplete
            v-model="topic.daggesrapport_training_id"
            label="Daggesrapport form for trainings"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('daggesrapport_training_id')"
          />
          <v-autocomplete
            v-model="topic.daggesrapport_activity_id"
            label="Daggesrapport form for activities"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('daggesrapport_activity_id')"
          />
          <v-autocomplete
            v-model="topic.evaluation_training_id"
            label="Individual evaluation form for trainings"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('evaluation_training_id')"
          />
          <v-autocomplete
            v-model="topic.evaluation_activity_id"
            label="Individual evaluation form fo activities"
            :items="forms"
            item-text="name"
            item-value="id"
            class="field-width"
            outline
            clearable
            :open-on-clear="false"
            @click:clear="clearFormField('evaluation_activity_id')"
          />
          <v-textarea
            v-model="topic.description"
            label="Description"
            class="field-width"
            rows="8"
            outline
          />
          <v-menu
            :close-on-content-click="false"
            offset-y
            input-activator
            max-width="225"
          >
            <v-text-field
              slot="activator"
              v-model="topic.color.hex"
              outline
              label="Color"
              class="field-width"
              readonly
              :rules="[ v => !!v || 'This field is required' ]"
            >
              <div slot="prepend-inner">
                <v-icon :color="topic.color.hex">
                  mdi-circle
                </v-icon>
              </div>
            </v-text-field>
            <color-picker v-model="topic.color" />
          </v-menu>
          <div class="title font-weight-light mb-3">
            Morning time slots
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.morning_start"
              outline
              label="Start of work"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !v || !topic.morning_end || parseInt(topic.morning_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="morningStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.morning_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.morning_start"
                    format="24hr"
                    @change="$refs.morningStartMenu.save(topic.morning_start); topic.clock_start_text = topic.morning_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.morning_end"
              outline
              label="End of work"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.morning_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.morning_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="morningEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.morning_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.morning_end"
                    format="24hr"
                    @change="$refs.morningEndMenu.save(topic.morning_end); topic.clock_start_text = topic.morning_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.morning_participants_start"
              outline
              label="Start for participants"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !v || !topic.morning_participants_end || parseInt(topic.morning_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="morningParticipantsStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.morning_participants_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.morning_participants_start"
                    format="24hr"
                    @change="$refs.morningParticipantsStartMenu.save(topic.morning_participants_start); topic.clock_start_text = topic.morning_participants_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.morning_participants_end"
              outline
              label="End for participants"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.morning_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.morning_participants_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="morningParticipantsEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.morning_participants_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.morning_participants_end"
                    format="24hr"
                    @change="$refs.morningParticipantsEndMenu.save(topic.morning_participants_end); topic.clock_start_text = topic.morning_participants_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.morning_total_time"
              outline
              label="Total work duration"
              class="field-width"
              :rules="[v => parseFloat(v.replace(',','.')) > 0 || (isNaN(parseInt(topic.morning_start.replace(':',''))) && isNaN(parseInt(topic.morning_end.replace(':','')))) || 'This duration is not valid']"
            />
          </div>
          <div class="title font-weight-light mb-3">
            Afternoon time slots
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.afternoon_start"
              outline
              label="Start of work"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.afternoon_end || !v || parseInt(topic.afternoon_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="afternoonStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.afternoon_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.afternoon_start"
                    format="24hr"
                    @change="$refs.afternoonStartMenu.save(topic.afternoon_start); topic.clock_start_text = topic.afternoon_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.afternoon_end"
              outline
              label="End of work"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.afternoon_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.afternoon_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="afternoonEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.afternoon_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.afternoon_end"
                    format="24hr"
                    @change="$refs.afternoonEndMenu.save(topic.afternoon_end); topic.clock_start_text = topic.afternoon_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>

          <div class="d-flex">
            <v-text-field
              v-model="topic.afternoon_participants_start"
              outline
              label="Start for participants"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.afternoon_participants_end || !v || parseInt(topic.afternoon_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="afternoonParticipantsStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.afternoon_participants_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.afternoon_participants_start"
                    format="24hr"
                    @change="$refs.afternoonParticipantsStartMenu.save(topic.afternoon_participants_start); topic.clock_start_text = topic.afternoon_participants_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.afternoon_participants_end"
              outline
              label="End for participants"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.afternoon_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.afternoon_participants_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="afternoonParticipantsEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.afternoon_participants_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.afternoon_participants_end"
                    format="24hr"
                    @change="$refs.afternoonParticipantsEndMenu.save(topic.afternoon_participants_end); topic.clock_start_text = topic.afternoon_participants_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.afternoon_total_time"
              outline
              label="Total work duration"
              class="field-width"
              :rules="[v => parseFloat(v.replace(',','.')) > 0 || (isNaN(parseInt(topic.afternoon_start.replace(':',''))) && isNaN(parseInt(topic.afternoon_end.replace(':','')))) || 'This duration is not valid']"
            />
          </div>

          <div class="title font-weight-light mb-3">
            Evening time slots
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.evening_start"
              outline
              label="Start of work"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.evening_end || !v || parseInt(topic.evening_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="eveningStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.evening_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.evening_start"
                    format="24hr"
                    @change="$refs.eveningStartMenu.save(topic.evening_start); topic.clock_start_text = topic.evening_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.evening_end"
              outline
              label="End of work"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.evening_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.evening_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="eveningEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.evening_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.evening_end"
                    format="24hr"
                    @change="$refs.eveningEndMenu.save(topic.evening_end); topic.clock_start_text = topic.evening_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>

          <div class="d-flex">
            <v-text-field
              v-model="topic.evening_participants_start"
              outline
              label="Start for participants"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.evening_participants_end || !v || parseInt(topic.evening_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="eveningParticipantsStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.evening_participants_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.evening_participants_start"
                    format="24hr"
                    @change="$refs.eveningParticipantsStartMenu.save(topic.evening_participants_start); topic.clock_start_text = topic.evening_participants_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.evening_participants_end"
              outline
              label="End for participants"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.evening_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.evening_participants_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="eveningParticipantsEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.evening_participants_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.evening_participants_end"
                    format="24hr"
                    @change="$refs.eveningParticipantsEndMenu.save(topic.evening_participants_end); topic.clock_start_text = topic.evening_participants_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.evening_total_time"
              outline
              label="Total work duration"
              class="field-width"
              :rules="[v => parseFloat(v.replace(',','.')) > 0 || (isNaN(parseInt(topic.evening_start.replace(':',''))) && isNaN(parseInt(topic.evening_end.replace(':','')))) || 'This duration is not valid']"
            />
          </div>

          <div class="title font-weight-light mb-3">
            Fullday time slots
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.day_start"
              outline
              label="Start of work"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.day_end || !v || parseInt(topic.day_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="dayStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.day_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.day_start"
                    format="24hr"
                    @change="$refs.dayStartMenu.save(topic.day_start); topic.clock_start_text = topic.day_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.day_end"
              outline
              label="End of work"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.day_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.day_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="dayEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.day_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.day_end"
                    format="24hr"
                    @change="$refs.dayEndMenu.save(topic.day_end); topic.clock_start_text = topic.day_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.day_participants_start"
              outline
              label="Start for participants"
              placeholder="HH:MM"
              class="mr-3"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.day_participants_end || !v || parseInt(topic.day_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="dayParticipantsStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.day_participants_start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.day_participants_start"
                    format="24hr"
                    @change="$refs.dayParticipantsStartMenu.save(topic.day_participants_start); topic.clock_start_text = topic.day_participants_start"
                  />
                </v-menu>
              </div>
            </v-text-field>
            <v-text-field
              v-model="topic.day_participants_end"
              outline
              label="End for participants"
              placeholder="HH:MM"
              :rules="[
                v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                v => !topic.day_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(topic.day_participants_start.replace(':','')) || 'End time must be greater than start time',
              ]"
            >
              <div slot="prepend-inner">
                <v-menu
                  ref="dayParticipantsEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="topic.day_participants_end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-icon slot="activator">
                    access_time
                  </v-icon>
                  <v-time-picker
                    v-model="topic.day_participants_end"
                    format="24hr"
                    @change="$refs.dayParticipantsEndMenu.save(topic.day_participants_end); topic.clock_start_text = topic.day_participants_end"
                  />
                </v-menu>
              </div>
            </v-text-field>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="topic.day_total_time"
              outline
              label="Total work duration"
              class="field-width"
              :rules="[v => parseFloat(v.replace(',','.')) > 0 || (isNaN(parseInt(topic.day_start.replace(':',''))) && isNaN(parseInt(topic.day_end.replace(':','')))) || 'This duration is not valid']"
            />
          </div>
        </template>
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-topics-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import GlobalMixin from "@/mixins/global";
import Auth from "@/plugins/auth";
import Consts from "@/consts";

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      topic: {
        name: null,
        application: Auth.user.application,
        teams_ids: [],
        morning_start: "",
        morning_end: "",
        afternoon_start: "",
        afternoon_end: "",
        evening_start: "",
        evening_end: "",
        day_start: "",
        day_end: "",
        morning_participants_start: "",
        morning_participants_end: "",
        afternoon_participants_start: "",
        afternoon_participants_end: "",
        evening_participants_start: "",
        evening_participants_end: "",
        day_participants_start: "",
        day_participants_end: "",
        morning_total_time: "0,00",
        afternoon_total_time: "0,00",
        evening_total_time: "0,00",
        day_total_time: "0,00",
        description: "",
        color: { hex: "#000000" },
        daggesrapport_training_id: null,
        daggesrapport_activity_id: null,
        evaluation_training_id: null,
        evaluation_activity_id: null
      },
      formValid: false,
      teams: [],
      Consts,
      Auth,
      forms: []
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    Params.getList("teams", { is_enabled: 1 }).then(res => {
      this.teams = res.data;
    });
    Params.getList('forms', {sortBy: 'name'}).then(res => {
        this.forms = res.data;
    })

    if (!this.isNew) {
      this.fetch();
    }
  },
  methods: {
    clearFormField(f) {
      this.$nextTick(() => {
        this.topic[f] = null
      })
    },
    fetch() {
      Params.get("topics", this.$route.params.id).then(res => {
        this.topic = res.data;
        this.topic.color = { hex: this.topic.color };
        this.topic.teams_ids = [];
        if (this.topic && this.topic.teams && this.topic.teams.length > 0) {
          for (let i = 0; i < this.topic.teams.length; i++) {
            this.topic.teams_ids.push(this.topic.teams[i].id);
          }
        }

        const fields = ['morning_start', 'morning_end', 'afternoon_start', 'afternoon_end', 'evening_start', 'evening_end', 'day_start', 'day_end', 'morning_participants_start', 'morning_participants_end', 'afternoon_participants_start', 'afternoon_participants_end', 'evening_participants_start', 'evening_participants_end', 'day_participants_start', 'day_participants_end']

        fields.forEach(f => {
          if(this.topic[f]){
            this.topic[f] = this.topic[f]
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          else {
            this.topic[f] = "" //pour permettre les replaces dans le template (rules sur les fields)
          }
        })

        const totalTimeFields = ['morning_total_time', 'afternoon_total_time', 'evening_total_time', 'day_total_time']
        totalTimeFields.forEach(f => {
          this.topic[f] = this.topic[f].replace('.', ',')
        })
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if(this.topic.application === Consts.APPLICATION.FREELANCES){
        let hasPeriod = false
        let hasCascadingTimes = true
        if(this.topic.morning_start && this.topic.morning_end){
          hasPeriod = true
          if (!this.topic.morning_participants_start || !this.topic.morning_participants_end || parseFloat(this.topic.morning_total_time.replace(',', '.')) <= 0) {
            hasCascadingTimes = false
          }
        }
        if(this.topic.afternoon_start && this.topic.afternoon_end){
          hasPeriod = true
          if (!this.topic.afternoon_participants_start || !this.topic.afternoon_participants_end || parseFloat(this.topic.afternoon_total_time.replace(',', '.')) <= 0) {
            hasCascadingTimes = false
          }
        }
        if(this.topic.evening_start && this.topic.evening_end){
          hasPeriod = true
          if (!this.topic.evening_participants_start || !this.topic.evening_participants_end || parseFloat(this.topic.evening_total_time.replace(',', '.')) <= 0) {
            hasCascadingTimes = false
          }
        }
        if(this.topic.day_start && this.topic.day_end){
          hasPeriod = true
          if (!this.topic.day_participants_start || !this.topic.day_participants_end || parseFloat(this.topic.day_total_time.replace(',', '.')) <= 0) {
              hasCascadingTimes = false
          }
        }

        if(!hasPeriod){
          this.$snotify.error('At least one time slot must be provided')
          return
        }
        if(!hasCascadingTimes) {
          this.$snotify.error('Some participants and/or duration times are missing')
          return
        }
      }

      if (this.topic.color.hex) {
        this.topic.color = this.topic.color.hex;
      } else {
        this.topic.color = null
      }

      if (this.isNew) {
        Params.create("topics", this.topic).then(() => {
          this.$router.push({ name: "admin-sessions-topics-index" });
          this.$snotify.success(
            `The concept "${this.topic.name}" has been created`
          );
        });
      } else {
        Params.update("topics", this.topic.id, this.topic).then(() => {
          this.$router.push({ name: "admin-sessions-topics-index" });
          this.$snotify.success(
            `The concept "${this.topic.name}" has been updated`
          );
        });
      }
    }
  }
};
</script>
