<template>
  <div class="full-width">
    <div
      layout="row"
      class="no-printable mt-2 mx-2 full-width"
    >
      <div class="no-printable">
        <v-list class="sidebar no-printable">
          <template v-for="(item,k) in menu">
            <v-list-tile
              :key="k"
              @click="$vuetify.goTo('#form-'+item.link, {offset: -14})"
            >
              <v-list-tile-avatar>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title class="pr-3">
                {{ item.title }}
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </div>
      <v-divider
        vertical
        class="no-printable"
      />
      <v-form
        id="session-form"
        ref="form"
        class="px-3 pb-5 mb-5"
        flex
        layout="column"
      >
        <div
          v-if="!isTraining && session.id && (session.erliewen_organization || session.erliewen_contact || session.erliewen_target_group)"
          layout="row"
        >
          <v-card
            id="form-organization-imp"
            class="mb-3 mr-3 print-no-break"
            flex="25"
            layout="column"
          >
            <v-card-title class="subheading">
              Organization - {{ isActivity ? 'erliewen.snj.lu' : 'bee-secure.lu' }}
            </v-card-title>
            <v-card-text
              layout="column"
              class="pb-3"
              flex
            >
              <div
                class="pa-2 erliewen-block nl2br"
                flex
                v-text="session.erliewen_organization"
              />
            </v-card-text>
          </v-card>
          <div
            layout="column"
            flex
          >
            <v-card
              id="form-contact-imp"
              class="mb-3 print-no-break"
            >
              <v-card-title class="subheading">
                Contact - {{ isActivity ? 'erliewen.snj.lu' : 'bee-secure.lu' }}
              </v-card-title>
              <v-card-text class="pb-3">
                <div
                  class="pa-2 erliewen-block nl2br"
                  v-text="session.erliewen_contact"
                />
              </v-card-text>
            </v-card>
            <v-card
              v-if="isActivity || session.erliewen_target_group"
              id="form-tg-imp"
              class="mb-3 print-no-break"
            >
              <v-card-title class="subheading">
                Target group - {{ isActivity ? 'erliewen.snj.lu' : 'bee-secure.lu' }}
              </v-card-title>
              <v-card-text class="pb-3">
                <div
                  class="pa-2 erliewen-block nl2br"
                  v-text="session.erliewen_target_group"
                />
              </v-card-text>
            </v-card>
          </div>
        </div>

        <!-- Organization + Contacts -->
        <template v-if="!isTraining">
          <div
            layout="row"
            style="order: 1"
          >
            <!-- Organization -->
            <v-card
              id="form-organization"
              class="mb-3 mr-3 print-no-break"
              flex="25"
            >
              <v-card-title class="subheading">
                Organization
              </v-card-title>
              <v-card-text @keydown.tab="onTab">
                <v-autocomplete
                  v-if="!session.organization_id || !session.organization"
                  v-model="session.organization"
                  v-focus
                  :disabled="!isAdmin && !isCoord"
                  prepend-inner-icon="mdi-magnify"
                  outline
                  single-line
                  clearable
                  :filter="() => true"
                  :loading="loadingOrg"
                  :items="organizations"
                  :search-input.sync="searchOrg"
                  hide-no-data
                  return-object
                  :rules="[ v => !!v || 'This field is required' ]"
                  hide-details
                  tabindex="1"
                  @input="onSelectOrg"
                >
                  <template
                    slot="item"
                    slot-scope="{ item }"
                  >
                    <div
                      v-if="item._isNew"
                      layout-align="start center"
                      layout="row"
                    >
                      <v-icon
                        color="primary"
                        class="mr-3"
                      >
                        mdi-plus-box
                      </v-icon>
                      Create a new organization
                    </div>
                    <div v-else>
                      {{ item.name }}
                    </div>
                  </template>
                  <template
                    slot="selection"
                    slot-scope="{ item }"
                  >
                    {{ item.name }}
                  </template>
                </v-autocomplete>
                <div v-else>
                  <v-rating
                    background-color="#AAAAAA"
                    :value="parseInt(session.organization.mark)"
                    small
                    dense
                    class="mr-2"
                    readonly
                  />
                  <div>
                    <b>{{ session.organization.name }}</b>
                    <v-btn
                      v-if="isAdmin || isCoord"
                      flat
                      class="my-0 no-printable"
                      icon
                      small
                      @click="editOrg"
                    >
                      <v-icon
                        title="Edit"
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <div>
                      {{ session.organization.number }} {{ session.organization.street }}
                    </div>
                    <div>
                      {{ session.organization.zip_code }} {{ session.organization.city }}
                    </div>
                    <div class="mt-2">
                      {{ session.organization.phone }}
                    </div>
                    <div>
                      {{ session.organization.fax }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <!-- Contacts -->
            <v-card
              id="form-contacts"
              class="mb-3 print-no-break"
              flex
            >
              <v-card-title class="subheading">
                Contacts
              </v-card-title>
              <v-card-text>
                <i
                  v-if="!session.organization"
                  class="caption grey--text text--darken-3 p-3"
                >Please select an organization first.</i>
                <div v-else>
                  <div>
                    <div class="body-2 grey--text text--darken-3">
                      Main contact from {{ session.organization.name }}
                    </div>

                    <div
                      layout="row"
                      layout-align="start center"
                      @keydown.tab="onTab"
                    >
                      <v-autocomplete
                        ref="contactAutocomplete"
                        v-model="session.contact_id"
                        :disabled="!isAdmin && !isCoord"
                        :items="contacts"
                        tabindex="2"
                        item-value="id"
                        class="pa-0"
                        outline
                        single-line
                        style="max-width: 600px"
                        :filter="filterContact"
                        placeholder="Pick a contact..."
                        hide-details
                        :rules="[ v => !!v || 'This field is required' ]"
                      >
                        <template
                          slot="item"
                          slot-scope="{ item }"
                        >
                          {{ item.firstname }} {{ item.lastname }}
                        </template>
                        <div
                          slot="selection"
                          slot-scope="{ item }"
                          layout="row"
                          layout-align="start center"
                          layout-wrap
                        >
                          {{ item.firstname }} {{ item.lastname }}
                          <span v-if="item.phone">
                            <i class="mdi mdi-phone mr-1 ml-2" />{{ item.phone }}
                          </span>
                          <span v-if="item.email">
                            <i class="mdi mdi-email mr-1 ml-2" />
                            <a href="#">{{ item.email }}</a>
                          </span>
                        </div>
                      </v-autocomplete>
                      <v-btn
                        v-if="isAdmin || isCoord"
                        flat
                        class="ml-2 mr-0 no-printable"
                        small
                        @click="newContact('contact_id')"
                      >
                        <v-icon
                          color="primary"
                          class="mr-1"
                        >
                          mdi-plus-box
                        </v-icon>
                        Add a new contact to this organization
                      </v-btn>
                    </div>
                  </div>

                  <div class="pt-3">
                    <div class="body-2 grey--text text--darken-3">
                      Additional contact
                    </div>
                    <div
                      layout="row"
                      layout-align="start center"
                      @keydown.tab="onTab($event, 101)"
                    >
                      <v-autocomplete
                        ref="contact2Autocomplete"
                        v-model="session.contact2_id"
                        tabindex="3"
                        :disabled="!isAdmin && !isCoord"
                        :items="contacts"
                        class="pa-0"
                        outline
                        single-line
                        style="max-width: 600px"
                        item-value="id"
                        :filter="filterContact"
                        placeholder="Pick a contact..."
                        hide-details
                      >
                        <template
                          slot="item"
                          slot-scope="{ item }"
                        >
                          {{ item.firstname }} {{ item.lastname }}
                        </template>
                        <div
                          slot="selection"
                          slot-scope="{ item }"
                          layout="row"
                          layout-align="start center"
                          layout-wrap
                        >
                          {{ item.firstname }} {{ item.lastname }}
                          <span v-if="item.phone">
                            <i class="mdi mdi-phone mr-1 ml-2" />{{ item.phone }}
                          </span>
                          <span v-if="item.email">
                            <i class="mdi mdi-email mr-1 ml-2" />
                            <a href="#">{{ item.email }}</a>
                          </span>
                        </div>
                      </v-autocomplete>
                      <v-btn
                        v-if="isAdmin || isCoord"
                        flat
                        class="ml-2 mr-0 no-printable"
                        small
                        @click="newContact('contact2_id')"
                      >
                        <v-icon
                          color="primary"
                          class="mr-1"
                        >
                          mdi-plus-box
                        </v-icon>
                        Add a new contact to this organization
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>

        <div
          v-if="session.id && session.synced_dates"
          layout="row"
          style="order:2"
        >
          <v-card
            id="form-dates-imp"
            class="mb-3 print-no-break"
            flex
            layout="column"
          >
            <v-card-title class="subheading">
              Dates - bee-secure.lu
            </v-card-title>
            <v-card-text
              layout="column"
              class="pb-3"
              flex
            >
              <div
                class="pa-2 erliewen-block nl2br"
                flex
                v-text="session.synced_dates"
              />
            </v-card-text>
          </v-card>
        </div>

        <div
          layout="row"
          layout-wrap
          class="mb-3 full-width"
          :style="'order: ' + (isFreelances ? '3' : '2')"
        >
          <div :flex="$vuetify.breakpoint.smAndDown ? 100 : ''">
            <!-- Dates -->
            <v-card
              id="form-dates"
              class="mb-3 print-no-break"
            >
              <v-card-title
                class="pb-0"
                layout="row"
                layout-align="space-between center"
              >
                <div
                  v-if="pack"
                  class="subheading font-weight-bold"
                >
                  Dates for the package {{ pack.name }}
                </div>
                <div
                  v-else
                  class="subheading font-weight-bold"
                >
                  Dates
                </div>
                <v-btn
                  v-if="(isAdmin || isCoord) && !pack && !session.package_id"
                  flat
                  color="accent"
                  class="ma-0 no-printable"
                  @click="addDate"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>Add a new date
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div
                  v-for="(d, i) in session.dates"
                  :key="'date'+i"
                  layout="row"
                  layout-align="start center"
                  class="mb-2"
                >
                  <div class="mr-3">
                    On
                  </div>

                  <v-menu
                    ref="startDateMenu"
                    v-model="d.date_start_visible"
                    :disabled="!isAdmin && !isCoord"
                    class="no-printable"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    :return-value.sync="d.date_start"
                    transition="scale-transition"
                    offset-y
                    full-width
                  >
                    <v-icon slot="activator">
                      event
                    </v-icon>
                    <v-date-picker
                      v-model="d.date_start"
                      first-day-of-week="1"
                      @input="$refs.startDateMenu[i].save(d.date_start); d.date_start_text = americanizeDate(d.date_start)"
                      @change="loadCalendar"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.date_start_text"
                    class="mx-2"
                    label="date"
                    :rules="[ v => !!v || 'This field is required' ]"
                    style="min-width: 120px"
                    hide-details
                    :tabindex="(i+1) + '01'"
                    @change="loadCalendar"
                    @blur="d.date_start = parseDate(d.date_start_text)"
                  />

                  <v-select
                    v-if="isActivity && !pack"
                    v-model="d.moment"
                    :disabled="!isAdmin && !isCoord"
                    :items="moments"
                    class="mx-2"
                    label="Moment"
                    hide-details
                    :tabindex="(i+1) + '02'"
                    @change="onMomentChange(d)"
                    style="width: 120px"
                  />

                  <v-menu
                    ref="startClockMenu"
                    v-model="d.clock_start_visible"
                    :disabled="!isAdmin && !isCoord"
                    class="no-printable ml-2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_start"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_start_visible"
                      v-model="d.clock_start"
                      format="24hr"
                      @change="saveClockStart(d.clock_start, i); parseTime(d, true);"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_start_hour"
                    :rules="hourRules"
                    label="from (work) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '02'"
                    @blur="d.clock_start = d.clock_start_hour + ':' + d.clock_start_min"
                    @change="updateDuration(d)"
                  />
                  <span
                    class="mx-3"
                    style="margin-top: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_start_min"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    class="mr-2"
                    hide-details
                    :tabindex="(i+1) + '03'"
                    @blur="d.clock_start = d.clock_start_hour + ':' + d.clock_start_min"
                    @change="updateDuration(d)"
                  />

                  <v-menu
                    ref="endClockMenu"
                    v-model="d.clock_end_visible"
                    :disabled="!isAdmin && !isCoord"
                    class="no-printable ml-2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_end"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_end_visible"
                      v-model="d.clock_end"
                      format="24hr"
                      @change="saveClockEnd(d.clock_end, i); parseTime(d, true)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_end_hour"
                    :rules="hourRules"
                    label="to (work) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '04'"
                    @blur="d.clock_end = d.clock_end_hour + ':' + d.clock_end_min"
                    @change="updateDuration(d)"
                  />
                  <span
                    class="mx-3"
                    style="margin-top: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_end_min"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    hide-details
                    :tabindex="(i+1) + '05'"
                    @blur="d.clock_end = d.clock_end_hour + ':' + d.clock_end_min"
                    @change="updateDuration(d)"
                  />
                  <!-- intégréation du champ durée de travail -->
                  <v-text-field
                    v-model="d.total_time"
                    label="Work duration (decimals hours)"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '06'"
                  />

                  <!-- Gestion des horaires destinés aux participants -->
                  <v-menu
                    ref="participantsStartClockMenu"
                    v-model="d.clock_participants_start_visible"
                    :disabled="!isAdmin && !isCoord"
                    class="no-printable ml-2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_participants_start"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_participants_start_visible"
                      v-model="d.clock_participants_start"
                      format="24hr"
                      @change="saveClockParticipantsStart(d.clock_participants_start, i); parseTime(d)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_participants_start_hour"
                    :rules="hourRules"
                    label="from (participants) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '07'"
                    @blur="d.clock_participants_start = d.clock_participants_start_hour + ':' + d.clock_participants_start_min"
                  />
                  <span
                    class="mx-3"
                    style="margin-top: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_participants_start_min"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    class="mr-2"
                    hide-details
                    :tabindex="(i+1) + '08'"
                    @blur="d.clock_participants_start = d.clock_participants_start_hour + ':' + d.clock_participants_start_min"
                  />

                  <v-menu
                    ref="participantsEndClockMenu"
                    v-model="d.clock_participants_end_visible"
                    :disabled="!isAdmin && !isCoord"
                    class="no-printable ml-2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="d.clock_participants_end"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                  >
                    <v-icon slot="activator">
                      access_time
                    </v-icon>
                    <v-time-picker
                      v-if="d.clock_participants_end_visible"
                      v-model="d.clock_participants_end"
                      format="24hr"
                      @change="saveClockParticipantsEnd(d.clock_participants_end, i); parseTime(d)"
                    />
                  </v-menu>
                  <v-text-field
                    v-model="d.clock_participants_end_hour"
                    :rules="hourRules"
                    label="to (participants) *"
                    type="number"
                    min="00"
                    max="23"
                    maxlength="2"
                    minlength="2"
                    class="ml-2"
                    hide-details
                    :tabindex="(i+1) + '09'"
                    @blur="d.clock_participants_end = d.clock_participants_end_hour + ':' + d.clock_participants_end_min"
                  />
                  <span
                    class="mx-3"
                    style="margin-top: 12px"
                  >:</span>
                  <v-text-field
                    v-model="d.clock_participants_end_min"
                    :rules="minRules"
                    type="number"
                    min="00"
                    max="59"
                    maxlength="2"
                    minlength="2"
                    hide-details
                    :tabindex="(i+1) + '10'"
                    @blur="d.clock_participants_end = d.clock_participants_end_hour + ':' + d.clock_participants_end_min"
                  />

                  <template v-if="pack">
                    <div
                      layout="column"
                      flex="15"
                    >
                      <v-select
                        v-model="d.topic_id"
                        disabled
                        :items="topics"
                        item-text="name"
                        item-value="id"
                        class="mx-2"
                        label="Concept"
                        hide-details
                        :tabindex="(i+1) + '11'"
                      />
                    </div>
                    <div
                      layout="column"
                      flex="15"
                    >
                      <v-select
                        v-model="d.team_id"
                        :items="getTeamsByTopic(d.topic_id)"
                        item-text="name"
                        item-value="id"
                        class="mx-2"
                        label="Team"
                        hide-details
                        :tabindex="(i+1) + '12'"
                        @change="onDateTeamChange(d)"
                      />
                    </div>
                    <div
                      layout="column"
                      flex="15"
                    >
                      <v-select
                        v-model="d.season_id"
                        :items="getSeasons(d.topic_id, d.team_id)"
                        :item-text="item => formatDate(item.start) + ' - ' + formatDate(item.end)"
                        item-value="id"
                        class="mx-2"
                        label="Season"
                        hide-details
                        :tabindex="(i+1) + '13'"
                      />
                    </div>
                  </template>
                  <v-btn
                    v-else-if="(isAdmin || isCoord) && !session.package_id"
                    icon
                    color="error"
                    class="no-printable ml-3"
                    title="Remove"
                    @click="removeDate(i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>

            <template v-if="!pack">
              <!-- Trainers -->
              <v-card
                id="form-trainers"
                class="print-no-break"
                :class="{'no-printable' : !trainers.length}"
              >
                <v-card-title
                  class="pb-0"
                  layout="row"
                  layout-align="space-between center"
                >
                  <div class="subheading font-weight-bold">
                    Trainers
                  </div>
                  <div
                    v-if="isCoord || isAdmin"
                    class="no-printable"
                  >
                    <v-menu
                      v-if="usersTrainers"
                      v-model="newTrainer"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template slot="activator">
                        <v-btn
                          flat
                          color="accent"
                          class="ma-0 no-printable"
                          tabindex="1000"
                          @keydown.enter="newTrainer = true"
                        >
                          <v-icon class="mr-1">
                            mdi-plus
                          </v-icon>Add a new trainer
                        </v-btn>
                      </template>
                      <v-list
                        v-if="computedTrainers && computedTrainers.length"
                        style="max-height: 300px"
                      >
                        <v-list-tile
                          v-for="(item, index) in computedTrainers"
                          :key="index"
                          :disabled="!!disabledTrainers[item.id]"
                          class="white trainer-list-item"
                          @click="addTrainer(item)"
                        >
                          <v-list-tile-title>
                            {{ item.fullname }}
                            <span v-if="disabledTrainers[item.id]"> (no contract)</span>
                          </v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                      <div
                        v-else
                        class="pa-2 theme--light v-sheet"
                      >
                        <em class="grey--text">No trainer available</em>
                      </div>
                    </v-menu>
                  </div>
                </v-card-title>
                <v-card-text class="pt-0">
                  <v-data-table
                    v-if="session && trainers.length && showTainersTable"
                    :headers="headersTrainers"
                    :items="trainers"
                    hide-actions
                    flex
                  >
                    <template
                      slot="headerCell"
                      slot-scope="props"
                    >
                      <span v-if="props.header.text">
                        {{ props.header.text }}
                      </span>
                      <span v-else>
                        {{ props.header.date }}<br>
                        {{ props.header.start }} - {{ props.header.end }}
                      </span>
                    </template>
                    <template
                      slot="items"
                      slot-scope="props"
                    >
                      <td class="no-wrap">
                        {{ props.item.firstname }} {{ props.item.lastname }} [{{ getTrainerAllocations(props.item.user_id, session.dates, allocations).month }} | {{ getTrainerAllocations(props.item.user_id, session.dates, allocations).year }}]
                      </td>
                      <td v-if="!isFreelances">
                        <v-icon :color="colorContract(props.item.user_id)">
                          mdi-circle
                        </v-icon>
                      </td>
                      <td
                        v-for="d in props.item.dates"
                        :key="'date_' + props.item.user_id + '_' + d.date_id"
                      >
                        <v-checkbox
                          :id="'cbtrainer_' + (props.index + 1)"
                          v-model="d.is"
                          :disabled="!isAdmin && !isCoord"
                          hide-details
                          @keydown.enter.native="d.is = !d.is"
                        />
                      </td>
                      <td
                        v-if="isCoord || isAdmin"
                        class="no-printable"
                        align="right"
                      >
                        <template v-if="isNew || session.status == Consts.TOVALIDATE || session.status == Consts.RELEASE || session.status == Consts.DRAFT">
                          <v-icon
                            v-if="props.item.is_blocked"
                            :tabindex="(props.index+1) + '002'"
                            title="Unblock the trainer"
                            class="mr-2"
                            @click="props.item.is_blocked = 0"
                            @keydown.enter="props.item.is_blocked = 0"
                          >
                            mdi-lock
                          </v-icon>
                          <v-icon
                            v-else
                            :tabindex="(props.index+1) + '002'"
                            title="Block the trainer"
                            class="mr-2"
                            @click="props.item.is_blocked = 1"
                            @keydown.enter="props.item.is_blocked = 1"
                          >
                            mdi-lock-open-variant
                          </v-icon>
                        </template>
                        <v-icon
                          color="red"
                          :tabindex="(props.index+1) + '003'"
                          title="Remove trainer"
                          @click="removeTrainer(props.item, props.index)"
                          @keydown.enter="removeTrainer(props.item, props.index)"
                        >
                          mdi-close
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <!-- Visitors -->
              <v-card
                v-if="session.session_type !== Consts.EVENTS && session.session_type !== Consts.OTHER && (!isActivity || session.animator_role != Consts.ANIMATOR_ROLE.TEACHER)"
                id="form-visitors"
                class="mt-3 print-no-break"
                :class="{'no-printable' : !visitors.length}"
              >
                <v-card-title
                  class="pb-0"
                  layout="row"
                  layout-align="space-between center"
                >
                  <div class="subheading font-weight-bold">
                    {{ isTraining ? 'Participants' : (isActivity ? 'Interns' : 'Visitors') }}
                  </div>
                  <div
                    v-if="isCoord || isAdmin"
                    class="no-printable"
                  >
                    <v-menu
                      v-if="computedVisitors && computedVisitors.length"
                      v-model="newVisitor"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template slot="activator">
                        <v-btn
                          flat
                          color="accent"
                          class="ma-0 no-printable"
                          tabindex="10000"
                          @keydown.enter="newVisitor = true"
                        >
                          <v-icon class="mr-1">
                            mdi-plus
                          </v-icon>Add a new {{ isTraining ? 'participant' : (isActivity ? 'intern' : 'visitor') }}
                        </v-btn>
                      </template>
                      <v-list style="max-height: 300px">
                        <v-list-tile
                          v-for="(item, index) in computedVisitors"
                          :key="index"
                          class="white"
                          @click="addVisitor(item)"
                        >
                          <v-list-tile-title>{{ item.fullname }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card-title>
                <v-card-text class="pt-0">
                  <v-data-table
                    v-if="session && visitors.length"
                    :headers="headersVisitors"
                    :items="visitors"
                    hide-actions
                    no-data-text="no visitor"
                    flex
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                    >
                      <td class="no-wrap">
                        <div
                          layout="row"
                          layout-align="start center"
                        >
                          <v-icon
                            v-if="isQueued(props.item.user_id, session, visitors)"
                            class="mr-2"
                            title="In the queue"
                          >
                            mdi-human-queue
                          </v-icon>
                          <span :class="{'font-italic grey--text': isQueued(props.item.user_id, session, visitors)}">{{ props.item.firstname }} {{ props.item.lastname }}</span>
                        </div>
                      </td>
                      <td v-if="(isCoord || isAdmin) && isActivity">
                        <v-select
                          v-model="props.item.trainer_id"
                          :items="experts"
                          label="Expert"
                          :item-text="item => item.firstname + ' ' + item.lastname"
                          item-value="id"
                          hide-details
                          single-line
                          clearable
                          class="mt-0 pt-0"
                        />
                      </td>
                      <td v-if="isCoord || isAdmin">
                        <template v-if="isValidableParticipant(props.item)">
                          <v-menu
                            v-if="!getVisitorLink(props.item).got_role"
                          >
                            <v-btn
                              slot="activator"
                              title="Validate"
                              class="mx-0"
                              icon
                            >
                              <v-icon
                                color="black"
                              >
                                mdi-check
                              </v-icon>
                            </v-btn>
                            <v-card style="max-width: 450px">
                              <template v-if="isTraining">
                                <v-card-title class="title">
                                  Validate the training
                                </v-card-title>
                                <v-card-text>
                                  <template v-if="isQueued(props.item.user_id, session, visitors)">
                                    ATTENTION: This participant is currently in the queue
                                    <br>
                                  </template>
                                  Are you sure you want to validate the training for this participant? He/she will become an intern for the session season.
                                </v-card-text>
                              </template>
                              <template v-else>
                                <v-card-title class="title">
                                  Validate the internship
                                </v-card-title>
                                <v-card-text>
                                  <p v-if="isQueued(props.item.user_id, session, visitors)">
                                    ATTENTION: This participant is currently in the queue
                                  </p>
                                  Are you sure you want to validate the internship for this participant? He/she will become a specialized animator for the session season.
                                </v-card-text>
                              </template>
                              <v-card-actions
                                layout="row"
                                layout-align="end center"
                              >
                                <v-btn
                                  depressed
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="primary"
                                  @click="validateParticipant(props.item)"
                                >
                                  Validate
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-menu>
                          <v-icon
                            v-else
                            color="success"
                          >
                            mdi-check
                          </v-icon>
                        </template>
                      </td>
                      <td
                        v-if="isCoord || isAdmin"
                        class="no-printable"
                        align="right"
                      >
                        <template v-if="isActivity && (isNew || session.status == Consts.TOVALIDATE || session.status == Consts.RELEASE || session.status == Consts.DRAFT)">
                          <v-icon
                            v-if="props.item.is_blocked"
                            title="Unblock the intern"
                            class="mr-2"
                            @click="props.item.is_blocked = 0"
                            @keydown.enter="props.item.is_blocked = 0"
                          >
                            mdi-lock
                          </v-icon>
                          <v-icon
                            v-else
                            :tabindex="(props.index+1) + '002'"
                            title="Block the intern"
                            class="mr-2"
                            @click="props.item.is_blocked = 1"
                            @keydown.enter="props.item.is_blocked = 1"
                          >
                            mdi-lock-open-variant
                          </v-icon>
                        </template>
                        <v-icon
                          color="red"
                          :tabindex="(props.index+1) + '0002'"
                          :disabled="!canEditVisitors"
                          @click="removeVisitor(props.item, props.index)"
                          @keydown.enter="removeVisitor(props.item, props.index)"
                        >
                          mdi-close
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </template>
          </div>
        </div>

        <!-- Description -->
        <v-card
          id="form-description"
          class="mb-3 print-no-break"
          :style="'order: ' + (isFreelances ? '2' : '3')"
        >
          <v-card-title class="subheading">
            Description
          </v-card-title>

          <v-card-text
            layout="row"
            layout-align="space-around"
          >
            <div
              layout="column"
              flex="45"
            >
              <div
                v-if="session.session_type === Consts.EVENTS"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Name of the event
                </div>
                <v-text-field
                  v-model="session.event_name"
                  tabindex="100001"
                  :disabled="!isAdmin && !isCoord"
                  :rules="[RULES.Required]"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="!isInternal && !isTraining"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Ticket number
                </div>
                <v-text-field
                  v-model="session.ticket_number"
                  tabindex="100002"
                  type="number"
                  :disabled="!isAdmin && !isCoord"
                  :hint="ticketText"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                  @input="existingTicket"
                />
              </div>
              <div
                v-if="isInternal"
                layout="row"
                layout-align="start start"
                @keydown.tab="onTab"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Internal type
                </div>
                <v-select
                  v-model="session.internal_type"
                  tabindex="100003"
                  :disabled="!isAdmin && !isCoord"
                  :items="internalTypes"
                  class="pl-3 mt-0 session-field"
                  :rules="[ v => !!v || 'This field is required' ]"
                  outline
                  single-line
                />
              </div>
              <template v-if="!pack">
                <div
                  layout="row"
                  layout-align="start start"
                  @keydown.tab="onTab($event, (isInternal ? 100005 : null))"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right"
                    :class="{'mt-2': !hasTopicWarning, 'pr-2': hasTopicWarning, 'pr-3': !hasTopicWarning}"
                  >
                    <div
                      layout="row"
                      layout-align="start center"
                    >
                      <span>Concept</span>
                      <template v-if="hasTopicWarning">
                        <v-icon
                          color="primary"
                          title="A default time is missing for this topic"
                          class="ml-2"
                        >
                          mdi mdi-alert
                        </v-icon>
                        <v-btn
                          :to="{name: 'admin-sessions-topics-edit', params: {id: session.topic_id}}"
                          icon
                          class="pa-0 ma-0"
                        >
                          <v-icon>mdi mdi-cog</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                  <v-autocomplete
                    v-model="session.topic_id"
                    tabindex="100003"
                    :disabled="!isAdmin && !isCoord"
                    :items="topics"
                    item-text="name"
                    item-value="id"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                    :rules="isFreelances ? [RULES.Required] : []"
                    @change="onTopicChange"
                  >
                    <template
                      slot="item"
                      slot-scope="{ item }"
                    >
                      <div
                        layout="row"
                        layout-align="start center"
                      >
                        <span>{{ item.name }}</span>
                        <v-icon
                          v-if="item.color"
                          small
                          :color="item.color"
                          class="ml-2"
                        >
                          mdi mdi-circle
                        </v-icon>
                      </div>
                    </template>
                    <template
                      slot="selection"
                      slot-scope="{ item }"
                    >
                      <div
                        layout="row"
                        layout-align="start center"
                      >
                        <span>{{ item.name }}</span>
                        <v-icon
                          v-if="item.color"
                          small
                          :color="item.color"
                          class="ml-2"
                        >
                          mdi mdi-circle
                        </v-icon>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
                <div
                  v-if="isFreelances"
                  layout="row"
                  layout-align="start start"
                  @keydown.tab="onTab"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-3 mt-2"
                  >
                    Team
                  </div>
                  <v-select
                    ref="teamField"
                    v-model="session.team_id"
                    tabindex="100004"
                    :disabled="(!isAdmin && !isCoord) || !session.topic_id"
                    :items="computedTeams"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                    item-text="name"
                    item-value="id"
                    :rules="[RULES.Required]"
                  />
                </div>
                <div
                  v-if="isFreelances"
                  layout="row"
                  layout-align="start start"
                  @keydown.tab="onTab"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-3 mt-2"
                  >
                    Season
                  </div>
                  <v-select
                    ref="seasonField"
                    v-model="session.season_id"
                    tabindex="100005"
                    :disabled="(!isAdmin && !isCoord) || !session.team_id"
                    :items="computedSeasons"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                    :item-text="item => formatDate(item.start) + ' - ' + formatDate(item.end)"
                    item-value="id"
                    :rules="[RULES.Required]"
                  />
                </div>
              </template>
              <div
                v-if="!isTraining"
                layout="row"
                layout-align="start start"
                @keydown.tab="onTab"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Target group
                </div>
                <v-select
                  v-model="session.target_group_id"
                  tabindex="100005"
                  :disabled="!isAdmin && !isCoord"
                  :items="targetGroups"
                  item-text="name"
                  item-value="id"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="!isInternal"
                layout="row"
                layout-align="start start"
                @keydown.tab="onTab"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  <div
                    layout="row"
                    layout-align="start center"
                  >
                    Language
                    <v-tooltip
                      v-if="session.synced_language"
                      bottom
                    >
                      <template #activator="{ on }">
                        <v-icon
                          color="primary"
                          class="ml-2"
                          v-on="on"
                        >
                          mdi mdi-information
                        </v-icon>
                      </template>
                      <span>Wished language: {{ session.synced_language }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-select
                  v-model="session.lang_id"
                  tabindex="100006"
                  :disabled="!isAdmin && !isCoord"
                  :items="langs"
                  item-text="name"
                  item-value="id"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="!isInternal"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Number of participants (visitors)
                </div>
                <v-text-field
                  v-model="session.nb_participants"
                  tabindex="100007"
                  :disabled="!isAdmin && !isCoord"
                  type="number"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="isActivity"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Number of accompanying persons
                </div>
                <v-text-field
                  v-model="session.nb_accompanying"
                  tabindex="100008"
                  :disabled="!isAdmin && !isCoord"
                  type="number"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="!isTraining"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Location <span v-if="!isInternal">(if different from organization)</span>
                </div>
                <v-text-field
                  v-model="session.location"
                  tabindex="100008"
                  :disabled="!isAdmin && !isCoord"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2 mt-2"
                >
                  Comments
                </div>
                <v-textarea
                  v-model="session.comments"
                  tabindex="100009"
                  :disabled="!isAdmin && !isCoord"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>
              <div
                v-if="session.session_type !== Consts.EVENTS && session.session_type !== Consts.OTHER && !isTraining"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Special training
                </div>
                <v-switch
                  id="checkbox_100010"
                  v-model="session.special_training"
                  :readonly="visitors.length > 0"
                  inset
                  class="ml-3 mt-0"
                  style="flex-grow: 0"
                  @keydown.enter.native="session.special_training = !session.special_training"
                />
                <span
                  v-if="visitors.length > 0"
                  class="mt-2 font-italic"
                  style="flex-grow: 1"
                >Special training because the session has visitor(s)</span>
              </div>
              <div
                v-if="!isTraining"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Is online
                </div>
                <v-switch
                  id="checkbox_100011"
                  v-model="session.is_online"
                  inset
                  class="ml-3 mt-0"
                  style="flex-grow: 0"
                  @keydown.enter.native="session.is_online = !session.is_online"
                />
              </div>
              <div
                v-if="!isTraining"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Is inclusive
                </div>
                <v-switch
                  id="checkbox_100012"
                  v-model="session.is_inclusive"
                  inset
                  class="ml-3 mt-0"
                  style="flex-grow: 0"
                  @keydown.enter.native="session.is_inclusive = !session.is_online"
                />
              </div>
            </div>
            <div
              layout="column"
              flex="45"
            >
              <template v-if="session.session_type === Consts.SCHOOL">
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Class name
                  </div>
                  <v-text-field
                    v-model="session.class_name"
                    tabindex="100013"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Room
                  </div>
                  <v-text-field
                    v-model="session.room"
                    tabindex="100014"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Teacher fullname
                  </div>
                  <v-text-field
                    v-model="session.referent_fullname"
                    tabindex="100015"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Teacher phone number
                  </div>
                  <v-text-field
                    v-model="session.referent_phone"
                    tabindex="100016"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
              </template>
              <div
                v-else
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-2 mt-2"
                >
                  Extra
                </div>
                <v-text-field
                  v-model="session.class_name"
                  tabindex="100017"
                  :disabled="!isAdmin && !isCoord"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>

              <div
                v-if="isTraining || isActivity"
                layout="row"
                layout-align="start start"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2 mt-2"
                >
                  Instructions for specialized animators
                </div>
                <v-textarea
                  v-model="session.information"
                  tabindex="100010"
                  :disabled="!isAdmin && !isCoord"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>

              <div
                v-if="isActivity"
                layout="row"
                layout-align="start start"
                @keydown.tab="onTab"
              >
                <div
                  layout="column"
                  layout-align="center end"
                  flex="33"
                  class="font-weight-bold text-xs-right pr-3 mt-2"
                >
                  Animator profile
                </div>
                <v-select
                  v-model="session.animator_role"
                  tabindex="100011"
                  :disabled="!isAdmin && !isCoord"
                  :items="animatorRoles"
                  class="pl-3 mt-0 session-field"
                  outline
                  single-line
                />
              </div>

              <template v-if="session.session_type === Consts.YOUTH">
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold  text-xs-right pr-2 mt-2"
                  >
                    Educator fullname
                  </div>
                  <v-text-field
                    v-model="session.referent_fullname"
                    tabindex="100018"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Educator phone number
                  </div>
                  <v-text-field
                    v-model="session.referent_phone"
                    tabindex="100019"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
              </template>

              <template v-if="session.session_type === Consts.TEACHERS">
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Partner fullname
                  </div>
                  <v-text-field
                    v-model="session.referent_fullname"
                    tabindex="100020"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold  text-xs-right pr-2 mt-2"
                  >
                    Partner phone number
                  </div>
                  <v-text-field
                    v-model="session.referent_phone"
                    tabindex="100021"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
              </template>

              <template v-if="session.session_type === Consts.EVENTS || session.session_type === Consts.OTHER">
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Objectives
                  </div>
                  <v-textarea
                    v-model="session.objectives"
                    tabindex="100022"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Partner
                  </div>
                  <v-textarea
                    v-model="session.partner"
                    tabindex="100023"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Press and media
                  </div>
                  <v-textarea
                    v-model="session.press_media"
                    tabindex="100024"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Website news
                  </div>
                  <v-textarea
                    v-model="session.website_news"
                    tabindex="100025"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
              </template>

              <template v-if="session.session_type != Consts.EVENTS && !isInternal && !isTraining && !isActivity">
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Delivery address (in case different of the school address)
                  </div>
                  <v-textarea
                    v-model="session.delivery_address"
                    tabindex="100026"
                    :disabled="!isAdmin && !isCoord"
                    class="pl-3 mt-0 session-field"
                    outline
                    single-line
                  />
                </div>
                <div
                  layout="row"
                  layout-align="start start"
                >
                  <div
                    layout="column"
                    layout-align="center end"
                    flex="33"
                    class="font-weight-bold text-xs-right pr-2 mt-2"
                  >
                    Technical setup available
                  </div>
                  <div
                    layout="row"
                    class="pl-3"
                  >
                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="Video-projector"
                        class="ma-0 pa-0"
                      >
                        mdi-projector
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100027"
                        v-model="session.beamer"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.beamer = !session.beamer"
                      />
                    </div>

                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="Projection screen"
                        class="ma-0 pa-0"
                      >
                        mdi-projector-screen
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100028"
                        v-model="session.screen"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.screen = !session.screen"
                      />
                    </div>

                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="Speakers"
                        class="ma-0 pa-0"
                      >
                        mdi-speaker-wireless
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100029"
                        v-model="session.loudspeak"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.loudspeak = !session.loudspeak"
                      />
                    </div>

                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="Internet connection"
                        class="ma-0 pa-0"
                      >
                        mdi-web
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100030"
                        v-model="session.internet"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.internet = !session.internet"
                      />
                    </div>

                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="Power plug"
                        class="ma-0 pa-0"
                      >
                        mdi-power-plug
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100031"
                        v-model="session.socket"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.socket = !session.socket"
                      />
                    </div>

                    <div
                      layout="column"
                      layout-align="center center"
                      class="mr-2"
                    >
                      <v-icon
                        title="USB stick"
                        class="ma-0 pa-0"
                      >
                        mdi-usb
                      </v-icon>
                      <v-checkbox
                        id="checkbox_100032"
                        v-model="session.usb"
                        :disabled="!isAdmin && !isCoord"
                        class="ma-0 pa-0 setup-cb"
                        @keydown.enter.native="session.usb = !session.usb"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </v-card-text>
        </v-card>

        <template v-if="session.session_type === Consts.EVENTS">
          <div
            layout="row"
            style="order: 4"
          >
            <!-- Settings -->
            <v-card
              id="form-settings"
              class="mb-3 mr-3 print-no-break"
            >
              <v-card-title class="subheading">
                Settings
              </v-card-title>
              <v-card-text
                layout="column"
                layout-align="start center"
              >
                <v-data-table
                  v-if="settingsLoaded"
                  :items="session.events_settings"
                  :headers="headers"
                  hide-actions
                  class="settings-table"
                  style="width: 100%"
                  :pagination.sync="paginationSettings"
                >
                  <template
                    slot="items"
                    slot-scope="{ item }"
                  >
                    <td class="text-xs-right">
                      {{ item.event_setting.name }}
                    </td>
                    <td class="py-2">
                      <v-text-field
                        v-model="item.qty"
                        :disabled="!isAdmin && !isCoord"
                        outline
                        single-line
                        hide-details
                        class="session-field"
                        type="number"
                        min="0"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.comment"
                        :disabled="!isAdmin && !isCoord"
                        outline
                        single-line
                        hide-details
                        class="session-field"
                      />
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>

            <!-- Special activities -->
            <v-card
              id="form-special-activities"
              class="mb-3 print-no-break"
            >
              <v-card-title class="subheading">
                Special activities
              </v-card-title>
              <v-card-text
                layout="column"
                layout-align="start center"
              >
                <v-data-table
                  v-if="activitiesLoaded"
                  :items="session.activities"
                  :headers="headersActivities"
                  hide-actions
                  class="settings-table"
                  style="width: 100%"
                  :pagination.sync="paginationActivities"
                >
                  <template
                    slot="items"
                    slot-scope="{ item }"
                  >
                    <td class="text-xs-right">
                      {{ item.activity.name }}
                    </td>
                    <td class="py-2">
                      <v-text-field
                        v-model="item.qty"
                        :disabled="!isAdmin && !isCoord"
                        outline
                        single-line
                        hide-details
                        class="session-field"
                        type="number"
                        min="0"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.comment"
                        :disabled="!isAdmin && !isCoord"
                        outline
                        single-line
                        hide-details
                        class="session-field"
                      />
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>

          <!-- Groups -->
          <v-card
            id="form-groups"
            class="mb-3 print-no-break"
            style="order: 5"
          >
            <v-card-title class="subheading">
              Groups
            </v-card-title>
            <v-card-text>
              <template v-for="t in groups">
                <div
                  v-if="t.users.length > 0"
                  :key="t.id"
                >
                  <div class="subheading font-weight-light mb-1">
                    {{ t.name }}
                  </div>
                  <div class="mb-2 mr-2">
                    <div
                      layout="row"
                      layout-align="start start"
                      layout-wrap
                      class="pa-1"
                    >
                      <v-checkbox
                        v-for="u in t.users"
                        :key="t.id + u.id"
                        v-model="members"
                        :value="u.id"
                        :label="`${u.firstname} ${u.lastname}`"
                        hide-details
                        class="py-0 my-1 dont-break-out"
                        style="max-width: 220px; min-width: 220px"
                      />
                      <span style="flex-grow: 2" />
                    </div>
                  </div>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </template>

        <div
          layout="row"
          layout-align="end center"
          class="mt-2 mb-5 pb-5 no-printable"
          style="order: 6"
        >
          <v-btn
            tabindex="1000000002"
            depressed
            color="primary"
            class="mx-2"
            @click="sendable ? (saveModal = true) : save(false, 0)"
          >
            Save
          </v-btn>
          <div class="mx-2">
            or
          </div>
          <router-link
            tabindex="1000000003"
            :to="{name: 'sessions-index'}"
            class="mx-2 black--text"
          >
            Cancel
          </router-link>
        </div>
      </v-form>
    </div>

    <div
      class="printable pa-3"
      style="background: white"
    >
      <div layout="row">
        <div
          layout="column"
          flex
        >
          <div class="subheading font-weight-bold">
            Dates
          </div>
          <div
            v-for="(d, i) in session.dates"
            :key="'datep'+i"
            layout="row"
            layout-align="start center"
            class="mb-1"
          >
            {{ formatDate(d.start) }} {{ formatTime(d.start) }} - {{ formatTime(d.end) }}
          </div>
        </div>
        <div
          v-if="trainers && trainers.length"
          layout="column"
          flex="50"
        >
          <div class="subheading font-weight-bold">
            Trainers
          </div>
          <v-data-table
            v-if="session && trainers.length && showTainersTable"
            :headers="headersTrainers"
            :items="trainers"
            hide-actions
            class="print-table"
            flex
          >
            <template
              slot="headerCell"
              slot-scope="props"
            >
              <span v-if="props.header.text">
                {{ props.header.text }}
              </span>
              <span v-else>
                {{ props.header.date }}<br>
                {{ props.header.start }} - {{ props.header.end }}
              </span>
            </template>
            <template
              slot="items"
              slot-scope="props"
            >
              <td class="no-wrap">
                {{ props.item.firstname }} {{ props.item.lastname }}
              </td>
              <td
                v-if="!isFreelances"
                class="text-xs-center"
              >
                <v-icon :color="colorContract(props.item.user_id)">
                  mdi-circle
                </v-icon>
              </td>
              <td
                v-for="d in props.item.dates"
                :key="'date_' + props.item.user_id + '_' + d.date_id"
              >
                <v-checkbox
                  v-model="d.is"
                  :disabled="!isAdmin && !isCoord"
                  hide-details
                />
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
      <div
        v-if="!isInternal && !isTraining && session.organization"
        layout="row"
      >
        <div
          layout="column"
          flex="50"
        >
          <div class="subheading font-weight-bold">
            Organization
          </div>
          <div>
            <v-rating
              background-color="#AAAAAA"
              :value="parseInt(session.organization.mark)"
              small
              dense
              class="mr-2"
              readonly
            />
            <div>
              <b>{{ session.organization.name }}</b>
            </div>
            <div>
              <div>
                {{ session.organization.number }} {{ session.organization.street }}
              </div>
              <div>
                {{ session.organization.zip_code }} {{ session.organization.city }}
              </div>
              <div class="mt-2">
                {{ session.organization.phone }}
              </div>
              <div>
                {{ session.organization.fax }}
              </div>
            </div>
          </div>
        </div>
        <div layout="column">
          <div class="subheading font-weight-bold">
            Contacts
          </div>
          <div
            v-if="session.contact"
            layout="row"
          >
            <span class="grey--text text--darken-3 mr-2">
              Main contact:
            </span>
            {{ session.contact.firstname }} {{ session.contact.lastname }}
          </div>
          <div
            v-if="session.contact2"
            layout="row"
          >
            <span class="grey--text text--darken-3 mr-2">
              Additional contact:
            </span>
            {{ session.contact2.firstname }} {{ session.contact2.lastname }}
          </div>
        </div>
      </div>

      <div class="subheading font-weight-bold">
        Description
      </div>
      <div layout="row">
        <div
          layout="column"
          flex="50"
        >
          <div
            v-if="session.session_type === Consts.EVENTS"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3 mt-2"
            >
              Name of the event
            </div>
            <span>{{ session.event_name }}</span>
          </div>
          <div
            v-if="!isInternal && !isTraining"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3 mt-2"
            >
              Ticket number
            </div>
            <span>{{ session.ticket_number }}</span>
          </div>
          <div
            v-if="isInternal"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Internal type
            </div>
            <span>{{ internalTypeStr(session.internal_type) }}</span>
          </div>
          <div
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Lesson concept
            </div>
            <span>{{ session.topic ? session.topic.name : '' }}</span>
          </div>
          <div
            v-if="!isInternal && !isTraining"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Target group
            </div>
            <span>{{ session.target_group ? session.target_group.name : '' }}</span>
          </div>
          <div
            v-if="!isInternal"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Language
            </div>
            <span>{{ session.lang ? session.lang.name : '' }}</span>
          </div>
          <div
            v-if="!isInternal"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Number of participants (visitors)
            </div>
            <span>{{ session.nb_participants }}</span>
          </div>
          <div
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Location <span v-if="!isInternal">(if different from organization)</span>
            </div>
            <span>{{ session.location }}</span>
          </div>
          <div
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Comments
            </div>
            <span>{{ session.comments }}</span>
          </div>
          <div
            v-if="session.session_type !== Consts.EVENTS && session.session_type !== Consts.OTHER"
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-3"
            >
              Special training
            </div>
            <v-switch
              v-model="session.special_training"
              :readonly="visitors.length > 0"
              inset
              class="ml-3 mt-0"
              style="flex-grow: 0"
            />
            <span
              v-if="visitors.length > 0"
              class="mt-2 font-italic"
              style="flex-grow: 1"
            >Special training because the session has visitor(s)</span>
          </div>
        </div>
        <div
          layout="column"
          flex
        >
          <template v-if="session.session_type === Consts.SCHOOL">
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Class name
              </div>
              <span>{{ session.class_name }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Room
              </div>
              <span>{{ session.room }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Teacher fullname
              </div>
              <span>{{ session.referent_fullname }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Teacher phone number
              </div>
              <span>{{ session.referent_phone }}</span>
            </div>
          </template>
          <div
            v-else
            layout="row"
            layout-align="start start"
          >
            <div
              layout="column"
              layout-align="center end"
              flex="33"
              class="font-weight-bold text-xs-right pr-2"
            >
              Extra
            </div>
            <span>{{ session.class_name }}</span>
          </div>

          <template v-if="session.session_type === Consts.YOUTH">
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold  text-xs-right pr-2"
              >
                Educator fullname
              </div>
              <span>{{ session.referent_fullname }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Educator phone number
              </div>
              <span>{{ session.referent_phone }}</span>
            </div>
          </template>

          <template v-if="session.session_type === Consts.TEACHERS">
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Partner fullname
              </div>
              <span>{{ session.referent_fullname }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold  text-xs-right pr-2"
              >
                Partner phone number
              </div>
              <span>{{ session.referent_phone }}</span>
            </div>
          </template>

          <template v-if="session.session_type === Consts.EVENTS || session.session_type === Consts.OTHER">
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Objectives
              </div>
              <span>{{ session.objectives }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Partner
              </div>
              <span>{{ session.partner }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Press and media
              </div>
              <span>{{ session.press_media }}</span>
            </div>
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Website news
              </div>
              <span>{{ session.website_news }}</span>
            </div>
          </template>

          <template v-if="session.session_type != Consts.EVENTS && !isInternal">
            <div
              layout="row"
              layout-align="start start"
            >
              <div
                layout="column"
                layout-align="center end"
                flex="33"
                class="font-weight-bold text-xs-right pr-2"
              >
                Delivery address (in case different of the school address)
              </div>
              <span>{{ session.delivery_address }}</span>
            </div>

            <div class="font-weight-bold mt-2">
              Technical setup available
            </div>

            <div layout="row">
              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="Video-projector"
                  class="ma-0 pa-0"
                >
                  mdi-projector
                </v-icon>
                <v-checkbox
                  v-model="session.beamer"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>

              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="Projection screen"
                  class="ma-0 pa-0"
                >
                  mdi-projector-screen
                </v-icon>
                <v-checkbox
                  v-model="session.screen"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>

              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="Speakers"
                  class="ma-0 pa-0"
                >
                  mdi-speaker-wireless
                </v-icon>
                <v-checkbox
                  v-model="session.loudspeak"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>

              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="Internet connection"
                  class="ma-0 pa-0"
                >
                  mdi-web
                </v-icon>
                <v-checkbox
                  v-model="session.internet"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>

              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="Power plug"
                  class="ma-0 pa-0"
                >
                  mdi-power-plug
                </v-icon>
                <v-checkbox
                  v-model="session.socket"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>

              <div
                layout="column"
                layout-align="center center"
                class="mr-2"
              >
                <v-icon
                  title="USB stick"
                  class="ma-0 pa-0"
                >
                  mdi-usb
                </v-icon>
                <v-checkbox
                  v-model="session.usb"
                  :disabled="!isAdmin && !isCoord"
                  class="ma-0 pa-0 setup-cb"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

      <template v-if="session.session_type === Consts.EVENTS">
        <div
          layout="row"
          class="mt-1"
        >
          <!-- Settings -->
          <div
            layout="column"
            flex="50"
            class="pr-1 mr-1"
          >
            <div class="subheading font-weight-bold">
              Settings
            </div>
            <v-data-table
              v-if="settingsLoaded"
              :items="session.events_settings"
              :headers="headers"
              hide-actions
              class="print-table"
              style="width: 100%"
              :pagination.sync="paginationSettings"
            >
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td class="text-xs-right">
                  {{ item.event_setting.name }}
                </td>
                <td>{{ item.qty }}</td>
                <td>{{ item.comment }}</td>
              </template>
            </v-data-table>
          </div>

          <!-- Special activities -->
          <div
            layout="column"
            flex
          >
            <div class="subheading font-weight-bold">
              Special activities
            </div>
            <v-data-table
              v-if="activitiesLoaded"
              :items="session.activities"
              :headers="headersActivities"
              hide-actions
              class="print-table"
              style="width: 100%"
              :pagination.sync="paginationActivities"
            >
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td class="text-xs-right">
                  {{ item.activity.name }}
                </td>
                <td>{{ item.qty }}</td>
                <td>{{ item.comment }}</td>
              </template>
            </v-data-table>
          </div>
        </div>

        <!-- Groups -->
        <div>
          <div class="subheading font-weight-bold">
            Groups
          </div>
          <div
            layout="row"
            layout-wrap
          >
            <template v-for="t in groups">
              <div
                v-if="t.users.length > 0"
                :key="t.id"
              >
                <div class="subheading font-weight-light mb-1">
                  {{ t.name }}
                </div>
                <div class="mb-2 mr-2">
                  <div
                    layout="row"
                    layout-align="start start"
                    layout-wrap
                    class="pa-1"
                  >
                    <v-checkbox
                      v-for="u in t.users"
                      :key="t.id + u.id"
                      v-model="members"
                      :value="u.id"
                      :label="`${u.firstname} ${u.lastname}`"
                      hide-details
                      class="py-0 my-1 dont-break-out"
                      style="max-width: 220px; min-width: 220px"
                    />
                    <span style="flex-grow: 2" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <!-- Visitors -->
      <div
        v-if="session.session_type !== Consts.EVENTS && session.session_type !== Consts.OTHER"
        class="print-no-break"
        :class="{'no-printable' : !visitors.length}"
      >
        <div class="subheading font-weight-bold">
          Visitors
        </div>
        <v-data-table
          v-if="session && visitors.length"
          :headers="headersVisitors"
          :items="visitors"
          hide-actions
          no-data-text="no visitor"
          class="print-table"
          flex
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <td class="no-wrap">
              {{ props.item.firstname }} {{ props.item.lastname }}
            </td>
          </template>
        </v-data-table>
      </div>
    </div>

    <organization-dialog
      :visible.sync="organizationDialog"
      :info="session.erliewen_organization"
      :client-no="session.client_no"
      @created="onOrgCreated"
    />
    <contact-dialog
      :visible.sync="contactDialog"
      :organization="session.organization_id"
      :info="session.erliewen_contact"
      @created="onContactCreated"
    />
    <v-dialog
      v-model="saveModal"
      width="500"
    >
      <v-card>
        <v-card-title class="title">
          Save session
        </v-card-title>
        <v-card-text>
          Save session and send an email to trainers to notify them of changes made to the session?
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end center"
        >
          <v-btn
            depressed
            color="error"
            @click="saveModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            color="success"
            @click="save(0,0)"
          >
            Save without notifying
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="save(0,1)"
          >
            Save and notify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Sessions from "@/api/sessions";
import Auth from "@/plugins/auth";
import Consts from "@/consts";
import moment from "moment";
import Organizations from "@/api/organizations";
import OrganizationDialog from "@/components/shared/OrganizationDialog.vue";
import ContactDialog from "@/components/shared/ContactDialog.vue";
import { Calendar } from "dayspan";
import { Units } from "dayspan";
import Vue from "vue";
import Params from "@/api/params";
import IsoLangs from "@/api/isolangs";
import SessionMixin from "@/mixins/sessions";
import DatesMixin from "@/mixins/date";
import RulesMixin from "@/mixins/rules";
import EventBus from "@/plugins/eventbus";

export default {
  components: { OrganizationDialog, ContactDialog },
  mixins: [RulesMixin, SessionMixin, DatesMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isTrainer: false,
      isCoord: false,
      isAdmin: false,
      cont: false,
      step: 0,
      isNew: true,
      searchOrg: null,
      loadingOrg: false,
      organizations: [],
      organizationDialog: false,
      contactDialog: false,
      contacts: [],
      pack: null,
      types: [
        {
          id: "D",
          label: "Day",
          shortcut: "D",
          type: Units.DAY,
          size: 1,
          focus: 0.4999,
          repeat: true,
          listTimes: true,
          updateRows: true,
          schedule: false
        }
      ],
      events: [],
      calendar: Calendar.days(),
      showCalendar: true,
      topics: [],
      teams: [],
      seasons: [],
      targetGroups: [],
      langs: [],
      groups: [],
      members: [],
      settings: [],
      activities: [],
      headers: [
        { text: "Setting", value: "event_setting.name", sortable: false },
        { text: "Qty", value: "qty", sortable: false, width: 100 },
        { text: "Comment", sortable: false }
      ],
      headersActivities: [
        { text: "Activity", value: "activity.name", sortable: false },
        { text: "Qty", value: "qty", sortable: false, width: 100 },
        { text: "Comment", sortable: false }
      ],
      settingsLoaded: false,
      activitiesLoaded: false,
      paginationSettings: {
        sortBy: "event_setting.name",
        descending: false
      },
      paginationActivities: {
        sortBy: "activity.name",
        descending: false
      },

      list: [],

      trainers: [],
      allocations: {},
      usersTrainers: [],
      newTrainer: null,
      showTainersTable: true,

      visitors: [],
      usersTrainersCoordination: [],
      newVisitor: null,

      ticketText: "",
      internalTypes: [
        { value: Consts.INTERNALTRAINER, text: "Trainer" },
        { value: Consts.INTERNALSTUDENT, text: "Student" }
      ],

      Consts,

      saveModal: false,
      hourRules: [
        v => !!v || "Hour is required",
        v => (v && v.length == 2) || "Invalid hour",
        v => (v && v >= 0 && v <= 23) || "Invalid hour"
      ],
      minRules: [
        v => !!v || "Time is required",
        v => (v && v.length == 2) || "Invalid time",
        v => (v && v >= 0 && v <= 59) || "Invalid time"
      ],
      contracts: {}
    };
  },
  computed: {
    session: {
      get() {
        return this.value;
      },
      set(x) {
        this.$emit("input", x);
      }
    },
    canEditVisitors(){
      if(!this.isFreelances){
        return true
      }
      if(this.session.status == Consts.TOEVALUATE || this.session.status == Consts.CLOSED){
        return false
      }

      return true
    },
    headersVisitors () {
      const arr = [
        { text: "Name", value: "name", sortable: false, width: '50%' },
      ]

      if((this.isCoord || this.isAdmin) && this.isActivity){
        arr.push({ text: "Expert", value: "expert", sortable: false })
      }
      if((this.isCoord || this.isAdmin) && (this.isTraining || this.isActivity)){
        arr.push({ text: this.isTraining ? "Becomes intern?" : "Becomes AS ?", value: "got_role", sortable: false })
      }
      if(this.isAdmin || this.isCoord){
        arr.push({ text: "", value: "", sortable: false, align: 'right' })
      }

      return arr
    },
    experts(){
      var users = []
      const tIds = this.trainers.map(function (el) { return el.user_id })

      // Trainer
      this.usersTrainers.forEach(u => {
        if(tIds.indexOf(u.id) > -1){
          users.push(u)
        }
      })

      users = users.sort(
        (a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
      )

      const arr = []

      // Expert
      if(this.session.topic_id){
        users.forEach(u => {
          if(u.skills){
            let found = false
            u.skills.forEach(s => {
              if(s.topic_id === this.session.topic_id){
                if(s.role == Consts.SKILL_ROLE.EXPERT){
                  found = true
                }
              }
            })

            if(found){
              arr.push(u)
            }
          }
        })
      }

      return arr
    },
    disabledTrainers() {
      let res = {}
      // Pour Freelances, on désactive les trainer qui n'ont pas de contract actif
      this.usersTrainers.forEach(u => {
        if (this.isFreelances) {
          if (this.session.dates.length <= 0) {
            res[u.id] = true
          } else {
            const y = moment(this.session.dates[0].start).format("YYYY");
            let nop = !(
              this.contracts[y] &&
              this.contracts[y][u.id] &&
              this.contracts[y][u.id].fl_label &&
              this.contracts[y][u.id].fl_criminal_records &&
              this.contracts[y][u.id].fl_resume
            )

            if(nop && this.session.topic_id){
              if(u.skills){
                u.skills.forEach(s => {
                  if(s.topic_id === this.session.topic_id){
                      if(s.role == Consts.SKILL_ROLE.TEACHER){
                        nop = false
                      }
                  }
                })
              }
            }

            res[u.id] = nop
          }
        }
      })

      return res
    },
    computedTrainers(){
      var users = []
      const tIds = this.trainers.map(function (el) { return el.user_id })

      this.usersTrainers.forEach(u => {
        if(tIds.indexOf(u.id) === -1){
          users.push(u)
        }
      })

      users = users.sort(
        (a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
      )

      let enabled = []
      let disabled = []
      users.forEach(u => {
        if(this.disabledTrainers[u.id]){
          disabled.push(u)
        }else{
          enabled.push(u)
        }
      })

      users = enabled.concat(disabled)

      if(this.isTraining){
        const arr = []

        if(this.session.topic_id){
          users.forEach(u => {
            if(u.skills){
              u.skills.forEach(s => {
              if(s.topic_id === this.session.topic_id){
                  if(s.role == Consts.SKILL_ROLE.TEACHER){
                    arr.push(u) // Enseignant
                  } else if(s.is_former && s.users_seasons){
                    s.users_seasons.forEach(us => {
                      if(us.season_id === this.session.season_id){
                        arr.push(u) // A la saison
                      }
                    })
                  }
                }
              })
            }
          })
        }

        return arr
      } else if(this.isActivity){
        const arr = []

        if(this.session.topic_id){
          users.forEach(u => {
            if(u.skills){
              let found = false
              u.skills.forEach(s => {
                if(s.topic_id === this.session.topic_id){
                  let err = true
                  if(s.role != Consts.SKILL_ROLE.TEACHER){
                    let season = null
                    s.users_seasons.forEach(us => {
                      if(us.season_id === this.session.season_id){
                        season = us // A la saison
                      }
                    })
                    if(season){
                      err = false
                    }
                  } else {
                    err = false // Teacher pas besoin de saison
                  }

                  if(!err){
                    switch(this.session.animator_role){
                      case Consts.ANIMATOR_ROLE.TEACHER:
                          if(s.role == Consts.SKILL_ROLE.TEACHER){
                              found = true
                          }
                      break
                      case Consts.ANIMATOR_ROLE.SPECIALIZED:
                          if(s.role == Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR){
                              found = true
                          }
                          if(s.role == Consts.SKILL_ROLE.EXPERT){
                              found = true
                          }
                      break
                      case Consts.ANIMATOR_ROLE.MIXED:
                          if(s.role == Consts.SKILL_ROLE.TEACHER){
                              found = true
                          }
                          if(s.role == Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR){
                              found = true
                          }
                          if(s.role == Consts.SKILL_ROLE.EXPERT){
                              found = true
                          }
                      break
                    }
                  }
                }
              })

              if(found){
                arr.push(u)
              }
            }
          })
        }

        return arr
      }

      return users
    },
    computedVisitors(){
      var users = []
      const tIds = this.visitors.map(function (el) { return el.user_id })

      this.usersTrainersCoordination.forEach(u => {
        if(tIds.indexOf(u.id) === -1){
          users.push(u)
        }
      })

      users = users.sort(
        (a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
      )

      if(this.isActivity){
         const arr = []

        if(this.session.topic_id){
          users.forEach(u => {
            if(u.skills){
              let found = false
              u.skills.forEach(s => {
                if(s.topic_id === this.session.topic_id){
                  if(s.role == Consts.SKILL_ROLE.INTERN){
                    found = true
                  }
                }
              })

              if(found){
                arr.push(u)
              }
            }
          })
        }

        return arr
      }

      return users
    },
    computedSeasons() {
      const arr = []
      if(this.isFreelances && this.session.team_id && this.session.topic_id){
        this.seasons.forEach(s => {
          if(s.team_id === this.session.team_id && s.topic_id === this.session.topic_id && (s.id === this.session.season_id || moment(s.end).isSameOrAfter(moment()))){
            arr.push(s)
          }
        })
      }

      arr.sort(
        (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
      )

      return arr
    },
    topicTeamIds () {
      const ids = []
      if(this.isFreelances && this.session.topic_id){
        let teams = []
        this.topics.forEach(t => {
          if(t.id === this.session.topic_id){
            teams = t.teams
          }
        })

        if(teams.length){
          teams.forEach(c => {
            ids.push(c.id)
          })
        }
      }

      return ids
    },
    computedTeams(){
      const arr = []

      this.teams.forEach(c => {
        if(this.topicTeamIds.indexOf(c.id) > -1 || !c.is_enabled){
          arr.push(c)
        }
      })

      return arr
    },
    isFreelances() {
      if (this.session.application == Consts.APPLICATION.FREELANCES) {
        return true;
      }

      return false;
    },
    hasTopicWarning(){
      if(!this.isAdmin){
        return false
      }
      if(!this.isActivity){
        return false
      }
      if(!this.session.topic_id){
        return false
      }

      let topic = null
      this.topics.forEach(t => {
        if(t.id == this.session.topic_id){
          topic = t
        }
      })

      if(!topic){
        return false
      }

      const fields = ['morning_start', 'morning_end', 'afternoon_start', 'afternoon_end', 'evening_start', 'evening_end', 'day_start', 'day_end']
      let error = false

      fields.forEach(f => {
        if(!topic[f] || topic[f] == '00:00:00'){
          error = true
        }
      })

      return error
    },
    isInternal() {
      if (parseInt(this.session.session_type) == Consts.INTERNAL) {
        return true;
      }
      return false;
    },
    isTraining() {
      if (parseInt(this.session.session_type) == Consts.FREELANCES_TRAINING) {
        return true;
      }
      return false;
    },
    isActivity() {
      if (parseInt(this.session.session_type) == Consts.ACTIVITY) {
        return true;
      }
      return false;
    },
    headersTrainers() {
      let headers = [
        {
          value: 0,
          text: "Name",
          sortable: false,
          width: '50%'
        }
      ];

      if (!this.isFreelances) {
        headers.push({ text: "Contract", value: 0, sortable: false });
      }

      let dates = this.session.dates;
      dates = dates.filter(
        d =>
          d.date_start !== undefined &&
          d.clock_start !== undefined &&
          d.clock_end !== undefined
      );
      dates.sort(
        (a, b) =>
          moment(a.date_start + " " + a.clock_start).format(
            "YYYY-MM-DD HH:mm"
          ) -
          moment(b.date_start + " " + b.clock_start).format("YYYY-MM-DD HH:mm")
      );

      // Parse dates into split date/time
      for (let i = 0; i < dates.length; ++i) {
        headers.push({
          date: dates[i].date_start,
          start: dates[i].clock_start,
          end: dates[i].clock_end,
          value: dates[i].id,
          sortable: false
        });
      }

      if (this.isCoord || this.isAdmin) {
        headers.push({ value: 0, text: " ", sortable: false, align: 'right' });
      }

      return headers;
    },
    menu() {
      let out = [];

      if (!this.isInternal && !this.isTraining) {
        out.push({
          title: "Organization",
          link: "organization",
          icon: "domain"
        });
        out.push({
          title: "Contacts",
          link: "contacts",
          icon: "card-account-phone"
        });
      }

      if(!this.isFreelances){
        out.push({ title: "Dates", link: "dates", icon: "calendar-multiselect" });
        out.push({ title: "Trainers", link: "trainers", icon: "human-male-board" });

        if (
          this.session.session_type !== Consts.EVENTS &&
          this.session.session_type !== Consts.OTHER
        ) {
          out.push({
            title: 'Visitors',
            link: "visitors",
            icon: "account-search"
          });
        }
      }

      out.push({
        title: "Description",
        link: "description",
        icon: "card-text"
      });

      if (this.session.session_type === Consts.EVENTS) {
        out.push({ title: "Settings", link: "settings", icon: "tune" });
        out.push({
          title: "Special activities",
          link: "special-activities",
          icon: "star-box"
        });
        out.push({ title: "Groups", link: "groups", icon: "account-multiple" });
      }

      if(this.isFreelances){
        out.push({ title: "Dates", link: "dates", icon: "calendar-multiselect" });
        out.push({ title: "Trainers", link: "trainers", icon: "human-male-board" });
        out.push({
          title: this.isActivity ? 'Interns' : 'Participants',
          link: "visitors",
          icon: "account-search"
        });
      }

      return out;
    },
    sendable() {
      if (
        this.session.status != Consts.ONHOLD &&
        this.session.status != Consts.DRAFT &&
        (this.trainers.length || this.session.id)
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    searchOrg(val) {
      val &&
        val !== this.session.organization_id &&
        this.queryOrganizations(val);
    },
    visitors() {
      if (this.visitors.length) {
        this.session.special_training = true;
      }
    }
  },
  async mounted() {
    if (this.$route.params.id === "new") {
      if(this.$route.query.package_id && this.$route.query.start_date){
        const topicsP = Params.getList("topics", {
          is_enabled: 1,
          application: this.session.application
        })
        const teamsP = Params.getList("teams", {
          is_enabled: 1,
        })
        const seasonsP = Params.getList("seasons", {
          is_enabled: 1,
        })
        const packageP = Params.get('packages', this.$route.query.package_id)


        const data = await Promise.all([topicsP, teamsP, seasonsP, packageP])

        const [topics, teams, seasons, pack] = data
        this.topics = topics.data
        this.teams = teams.data
        this.seasons = seasons.data
        this.pack = pack.data

        const lines = []
        let count = 0
        this.pack.days.forEach(day => {
          const d = moment(this.$route.query.start_date).add(count, 'days').format('YYYY-MM-DD')
          const ms = day.morning_start.substring(0, 5)
          const me = day.morning_end.substring(0, 5)
          const as = day.afternoon_start.substring(0, 5)
          const ae = day.afternoon_end.substring(0, 5)
          const mps = day.morning_participants_start.substring(0, 5)
          const mpe = day.morning_participants_end.substring(0, 5)
          const aps = day.afternoon_participants_start.substring(0, 5)
          const ape = day.afternoon_participants_end.substring(0, 5)
          const mt = day.morning_topic_id
          const at = day.afternoon_topic_id

          lines.push({
            date: d,
            start: ms,
            end: me,
            total_time: day.morning_total_time,
            participants_start: mps,
            participants_end: mpe,
            topic_id: mt
          })

          lines.push({
            date: d,
            start: as,
            end: ae,
            total_time: day.afternoon_total_time,
            participants_start: aps,
            participants_end: ape,
            topic_id: at
          })

          count++
        })

        lines.forEach(l => {
          const cId = (this.getTeamsByTopic(l.topic_id).length === 1) ? this.getTeamsByTopic(l.topic_id)[0].id : null
          this.session.dates.push({
            date_start_text: moment(l.date).format("DD.MM.YYYY"),
            date_start: moment(l.date).format("YYYY-MM-DD"),
            total_time: l.total_time,
            date_start_visible: false,
            clock_start_text: l.start,
            clock_start: l.start,
            clock_start_visible: false,
            clock_start_hour: l.start.substring(0, 2),
            clock_start_min: l.start.substring(3, 5),
            clock_end_text: l.end,
            clock_end: l.end,
            clock_end_visible: false,
            clock_end_hour: l.end.substring(0, 2),
            clock_end_min: l.end.substring(3, 5),

            clock_participants_start_text: l.participants_start,
            clock_participants_start: l.participants_start,
            clock_participants_start_visible: false,
            clock_participants_start_hour: l.participants_start.substring(0, 2),
            clock_participants_start_min: l.participants_start.substring(3, 5),
            clock_participants_end_text: l.participants_end,
            clock_participants_end: l.participants_end,
            clock_participants_end_visible: false,
            clock_participants_end_hour: l.participants_end.substring(0, 2),
            clock_participants_end_min: l.participants_end.substring(3, 5),

            session_id: this.session.id,
            id: this.uuid(),
            moment: null,
            topic_id: l.topic_id,
            team_id: cId,
            season_id: (cId && this.getSeasons(l.topic_id, cId).length === 1) ? this.getSeasons(l.topic_id, cId)[0].id : null
          })
        })
      } else {
        this.addDate();
      }
      this.$nextTick(() => {
        this.loadState();
      });
    } else {
      this.isNew = false;

      if (this.session.organization_id) {
        this.fetchOrganizationContacts();
      }

      if (this.session.dates.length > 0) {
        let dates = this.session.dates;
        dates.sort(
          (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
        );

        // Parse dates into split date/time
        for (let i = 0; i < this.session.dates.length; ++i) {
          let d = this.session.dates[i];

          d.date_start = moment(d.start).format("YYYY-MM-DD");
          d.date_start_text = moment(d.start).format("DD.MM.YYYY");
          d.clock_start = moment(d.start).format("HH:mm");
          d.clock_start_text = moment(d.start).format("HH:mm");
          d.clock_end = moment(d.end).format("HH:mm");
          d.clock_end_text = moment(d.end).format("HH:mm");

          d.total_time = d.total_time.replace('.', ',');

          d.clock_participants_start = moment(d.participants_start).format("HH:mm");
          d.clock_participants_start_text = moment(d.participants_start).format("HH:mm");
          d.clock_participants_end = moment(d.participants_end).format("HH:mm");
          d.clock_participants_end_text = moment(d.participants_end).format("HH:mm");
          d = this.parseTime(d);

          this.session.dates[i] = d;
        }

        if (
          this.session.trainers_presences &&
          this.session.trainers_presences.length > 0
        ) {
          for (let i = 0; i < this.session.trainers_presences.length; i++) {
            this.list[this.session.trainers_presences[i].user_id] = {
              user_id: this.session.trainers_presences[i].user_id,
              firstname: this.session.trainers_presences[i].user.firstname,
              lastname: this.session.trainers_presences[i].user.lastname,
              dates: [],
              is_blocked: this.session.trainers_presences[i].is_blocked
            };
            for (let j = 0; j < this.session.dates.length; j++) {
              let is = this.session.trainers_presences[i].presences
                .map(function(e) {
                  return e.session_date_id;
                })
                .indexOf(this.session.dates[j].id);
              this.list[this.session.trainers_presences[i].user_id].dates.push({
                date_id: this.session.dates[j].id,
                start: this.session.dates[j].start,
                en: this.session.dates[j].end,
                // is: (is > -1 && this.session.trainers_presences[i].presences[is].is_blocked)
                is: is > -1 && this.session.trainers_presences[i].presences[is]
              });
            }
          }

          for (let i in this.list) {
            this.trainers.push(this.list[i]);
          }
        }

        if (this.session.session_visitor && this.session.session_visitor.length > 0) {
          for (let i = 0; i < this.session.session_visitor.length; i++) {
            this.visitors.push({
              user_id: this.session.session_visitor[i].user_id,
              is_blocked: this.session.session_visitor[i].is_blocked,
              trainer_id: this.session.session_visitor[i].trainer_id,
              firstname: this.session.session_visitor[i].user.firstname,
              lastname: this.session.session_visitor[i].user.lastname
            });
          }
        }

        this.$nextTick(() => {
          this.loadCalendar();
        });
      } else {
        this.addDate();
        this.$nextTick(() => {
          this.loadState();
        });
      }

      let arr = [];
      if (this.session.users_groups) {
        for (let i = 0; i < this.session.users_groups.length; i++) {
          arr.push(this.session.users_groups[i].user_id);
        }
      }
      this.members = arr;

      if(!this.session.ticket_number && this.session.external_id){
        this.session.ticket_number = this.session.external_id
      }
      if(!this.session.organization_id && this.session.client_no){
        Organizations.getList({client_no: this.session.client_no}).then(res => {
          if(res.data && res.data.length === 1){
            const o = res.data[0]
            this.onOrgCreated(o)
            this.session.organization_id = this.session.organization.id;
            this.fetchOrganizationContacts();
          }
        })
      }
    }

    this.loadUser();
    this.fetchParams();
    this.$refs.form.resetValidation();

    EventBus.$on("session-updated", () => {
      this.sendable ? (this.saveModal = true) : this.save(false, 0);
    });
  },
  destroyed() {
    EventBus.$off("session-updated");
  },
  updated() {
    [
      100027,
      100028,
      100029,
      100030,
      100031,
      100032,
      100010,
      100011,
      100012
    ].forEach(i => {
      let el = document.getElementById("checkbox_" + i);
      if (el) {
        el.setAttribute("tabindex", i);
      }
    });
  },
  methods: {
    editOrg(){
      this.session.organization = null
      this.session.organization_id = null
      this.contacts = []
    },
    onDateTeamChange(d){
      d.season_id = null
      if(this.getSeasons(d.topic_id, d.team_id).length === 1){
        d.season_id = this.getSeasons(d.topic_id, d.team_id)[0].id
      }
    },
    getSeasons(tId, cId){
      const arr = []
      this.seasons.forEach(s => {
        if(s.team_id === cId && s.topic_id === tId && moment(s.end).isSameOrAfter(moment())){
          arr.push(s)
        }
      })

      arr.sort(
        (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
      )

      return arr
    },
    getTeamsByTopic(id){
      const ids = []
      const arr = []
      let teams = []
      this.topics.forEach(t => {
        if(t.id === id){
          teams = t.teams
        }
      })

      if(teams.length){
        teams.forEach(c => {
          ids.push(c.id)
        })
      }

      this.teams.forEach(c => {
        if(ids.indexOf(c.id) > -1 || !c.is_enabled){
          arr.push(c)
        }
      })

      return arr
    },
    validateParticipant(p){
       Sessions.validateParticipant(this.session.id, this.getVisitorLink(p).id).then(() => {
        for(let i = 0; i < this.session.session_visitor.length; i++){
          if(this.session.session_visitor[i].user_id === p.user_id){
            this.session.session_visitor[i].got_role = 1
          }
        }

        if(this.isTraining){
          this.$snotify.success('Training has been validated')
        }else{
          this.$snotify.success('Internship has been validated')
        }
      })
    },
    isValidableParticipant(p){
      if(!this.isTraining && !this.isActivity){
        return false
      }
      if(this.session.status == Consts.BLOCKED || this.session.status == Consts.TOEVALUATE || this.session.status == Consts.CLOSED){
        if(this.getVisitorLink(p)){
          return true
        }
      }

      return false
    },
    getVisitorLink(p){
      let link = null

      if(this.session.session_visitor && this.session.session_visitor.length){
        this.session.session_visitor.forEach(l => {
          if(l.user_id === p.user_id){
            link = l
          }
        })
      }

      return link
    },
    onTopicChange(){
      if(this.isFreelances){
        this.$nextTick(() => {
          this.session.team_id = null
          if(this.computedTeams.length === 1){
            this.session.team_id = this.computedTeams[0].id
          }
        })
      }
    },
    onTab(elem, t = null) {
      if (elem.shiftKey) {
        return;
      }

      let index = elem.target.attributes.tabindex.value;

      if (!index) {
        return;
      }

      let target = null;

      if (t) {
        target = parseInt(t);
      } else {
        target = parseInt(index) + 1;
      }

      let elems = document.querySelectorAll('[tabindex="' + target + '"]');

      if (elems.length != 1) {
        return;
      }

      this.$nextTick(() => {
        elems[0].focus();
      });

      elem.preventDefault();
      elem.stopPropagation();
    },
    saveClockStart(date, i) {
      this.$refs.startClockMenu[i].save(date);
    },
    saveClockEnd(date, i) {
      this.$refs.endClockMenu[i].save(date);
    },
    saveClockParticipantsStart(date, i) {
      this.$refs.participantsStartClockMenu[i].save(date);
    },
    saveClockParticipantsEnd(date, i) {
      this.$refs.participantsEndClockMenu[i].save(date);
    },
    parseTime(date, update_duration = false) {
      date.clock_start_hour = date.clock_start[0] + date.clock_start[1];
      date.clock_start_min = date.clock_start[3] + date.clock_start[4];
      date.clock_end_hour = date.clock_end[0] + date.clock_end[1];
      date.clock_end_min = date.clock_end[3] + date.clock_end[4];

      date.clock_participants_start_hour = date.clock_participants_start[0] + date.clock_participants_start[1]
      date.clock_participants_start_min = date.clock_participants_start[3] + date.clock_participants_start[4]
      date.clock_participants_end_hour = date.clock_participants_end[0] + date.clock_participants_end[1]
      date.clock_participants_end_min = date.clock_participants_end[3] + date.clock_participants_end[4]

      if(update_duration) {
        this.updateDuration(date)
      }

      return date;
    },
    existingTicket() {
      Sessions.existingTicket(this.session).then(res => {
        if (res.data && res.data != 0) {
          this.ticketText = "A session with this ticket number already exusts";
        } else {
          this.ticketText = "";
        }
      });
    },
    save(draft, toSend = 0) {
      if (!this.session.dates || this.session.dates.length < 1) {
        this.$snotify.error("The date is required");
        return;
      }

      let error = false;
      for (let i = 0; i < this.session.dates.length; i++) {
        if (
          !this.session.dates[i].date_start ||
          !this.session.dates[i].clock_start ||
          !this.session.dates[i].clock_end ||
          !this.session.dates[i].clock_participants_start ||
          !this.session.dates[i].clock_participants_end
        ) {
          error = true;
          break;
        }
        if(this.pack){
          if (
            !this.session.dates[i].team_id ||
            !this.session.dates[i].season_id ||
            !this.session.dates[i].topic_id
          ) {
            error = true;
            break;
          }
        }
      }

      if (error) {
        this.$snotify.error("There is a problem with one of your dates");
        return;
      }

      if (!this.$refs.form.validate()) {
        this.$snotify.error("Please verify the required fields");
        return;
      }

      this._prepare();

      error = false;
      for (let i = 0; i < this.session.dates.length; i++) {
        let start = moment(this.session.dates[i].start);
        if (!start.isValid()) {
          error = true;
          break;
        }

        let end = moment(this.session.dates[i].end);
        if (!end.isValid()) {
          error = true;
          break;
        }

        let participants_start = moment(this.session.dates[i].participants_start);
        if (!participants_start.isValid()) {
          error = true;
          break;
        }

        let participants_end = moment(this.session.dates[i].participants_end);
        if (!participants_end.isValid()) {
          error = true;
          break;
        }
      }

      if (error) {
        this.$snotify.error("One of your dates is invalid");
        return;
      }

      if (this.members) {
        this.session["users_groups"] = this.members;
      }
      if (!this.session.users_groups || this.session.users_groups.length == 0) {
        this.session.users_groups = 1;
      }

      let paramsTrainers = [];
      for (let t = 0; t < this.trainers.length; t++) {
        let d = {};
        for (let p = 0; p < this.trainers[t].dates.length; p++) {
          if (this.trainers[t].dates[p].is) {
            d[this.trainers[t].dates[p].date_id] = true;
          }
        }
        paramsTrainers.push({
          user_id: this.trainers[t].user_id,
          is_blocked: this.trainers[t].is_blocked,
          dates: d
        });
      }
      this.session.trainers = paramsTrainers;

      let paramsVisitors = [];
      for (let t = 0; t < this.visitors.length; t++) {
        paramsVisitors.push({
          user_id: this.visitors[t].user_id,
          is_blocked: this.visitors[t].is_blocked,
          trainer_id: this.visitors[t].trainer_id ? this.visitors[t].trainer_id : null,
        });
      }
      this.session.visitors = paramsVisitors;

      // Clean keys that shouldn't be sent to server
      let session = Object.assign({}, this.session);
      delete session.organization;
      delete session.contact;
      delete session.contact2;

      if (draft && (this.isNew || this.session.status === Consts.DRAFT)) {
        session.draft = true;
      }

      session.to_send = toSend;

      // Create/update
      if (!this.session.id) {
        if(this.pack){
          session.start_date = this.$route.query.start_date
          Sessions.package(this.pack.id, session).then(() => {
            this.$snotify.success("Sessions have been created");
            this.$emit("save");
            this.saveModal = false;
          });
        } else {
          Sessions.create(session).then(() => {
            this.$snotify.success("Session has been created");
            this.$emit("save");
            this.saveModal = false;
          });
        }
      } else {
        Sessions.update(session.id, session).then(() => {
          this.$snotify.success("Session has been updated");
          this.$emit("save");
          this.saveModal = false;
        });
      }
    },
    loadUser() {
      if (Auth.user.is_admin) {
        this.isAdmin = true;
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true;
      }
      if (Auth.user.roles.indexOf(Consts.TRAINER) > -1) {
        this.isTrainer = true;
      }
      if (this.isTrainer && !(this.isCoord || this.isAdmin)) {
        this.$router.push({ name: "sessions-index" });
      }
    },
    queryOrganizations(val) {
      this.loadingOrg = true;

      Organizations.getList({ keywords: val }).then(res => {
        this.organizations = res.data;
        this.organizations.push({ _isNew: true });

        this.loadingOrg = false;
      });
    },

    fetchOrganizationContacts() {
      if(!this.session.organization_id){
        this.contacts = []
        return
      }

      Organizations.getContactList(this.session.organization_id, {
        is_enabled: 1
      }).then(res => {
        this.contacts = res.data;
        this.$refs.contactAutocomplete.focus();
      });
    },

    onSelectOrg(val) {
      if(!val){
        this.session.organization_id = null
        this.fetchOrganizationContacts();
      } else if (val._isNew) {
        this.organizationDialog = true;
        this.session.organization = null;
      } else {
        this.session.organization_id = this.session.organization.id;
        this.fetchOrganizationContacts();
        this.$nextTick(() => {
          this.$refs.contactAutocomplete.focus();
        });
      }
    },

    onOrgCreated(val) {
      this.session.organization = val;
      this.onSelectOrg(val);
    },

    newContact(field) {
      this.contactDialog = true;
      this.newContactField = field;
    },

    onContactCreated(val) {
      this.fetchOrganizationContacts();
      this.session[this.newContactField] = val.id;
    },

    filterContact(item, val) {
      if (!val) {
        return true;
      }

      const lcVal = val.toLowerCase();

      return (
        item.firstname.toLowerCase().indexOf(lcVal) >= 0 ||
        item.lastname.toLowerCase().indexOf(lcVal) >= 0
      );
    },
    _prepare() {
      for (let i = 0; i < this.session.dates.length; ++i) {
        const d = this.session.dates[i];

        d.start = d.date_start + " " + d.clock_start;
        d.end = d.date_start + " " + d.clock_end;
        d.participants_start = d.date_start + " " + d.clock_participants_start;
        d.participants_end = d.date_start + " " + d.clock_participants_end;

        this.session.dates[i] = d;
      }
    },
    onMomentChange(d){
      if(this.session && this.session.topic_id){
        this.topics.forEach(t => {
          if(t.id === this.session.topic_id){
            const sf = d.moment + '_start'
            const ef = d.moment + '_end'
            const tt = d.moment + '_total_time'
            const sfp = d.moment + '_participants_start'
            const efp = d.moment + '_participants_end'

            if(t[sf]){
              d.clock_start_text = t[sf].substring(0, 5)
              d.clock_start = t[sf].substring(0, 5)
              d.clock_start_hour = t[sf].substring(0, 2)
              d.clock_start_min = t[sf].substring(3, 5)

            }
            if(t[sfp]) {
              d.clock_participants_start_text = t[sfp].substring(0, 5)
              d.clock_participants_start = t[sfp].substring(0, 5)
              d.clock_participants_start_hour = t[sfp].substring(0, 2)
              d.clock_participants_start_min = t[sfp].substring(3, 5)
            }
            if(t[ef]){
              d.clock_end_text = t[ef].substring(0, 5)
              d.clock_end = t[ef].substring(0, 5)
              d.clock_end_hour = t[ef].substring(0, 2)
              d.clock_end_min = t[ef].substring(3, 5)

            }
            if(t[efp]) {
              d.clock_end_text = t[efp].substring(0, 5)
              d.clock_end = t[efp].substring(0, 5)
              d.clock_end_hour = t[efp].substring(0, 2)
              d.clock_end_min = t[efp].substring(3, 5)
            }

            d.total_time = (t[tt] ? t[tt] : this.getDecimalHours(d.clock_start, d.clock_end)).replace('.', ',')
          }
        })
      }
    },
    addDate() {
      let date = {
        date_start_text: moment().format("DD.MM.YYYY"),
        date_start: moment().format("YYYY-MM-DD"),
        date_start_visible: false,
        clock_start_text: "08:00",
        clock_start: "08:00",
        clock_start_visible: false,
        clock_start_hour: "08",
        clock_start_min: "00",
        clock_end_text: "18:00",
        clock_end: "18:00",
        total_time: "10",
        clock_end_visible: false,
        clock_end_hour: "18",
        clock_end_min: "00",
        clock_participants_start_text: "08:00",
        clock_participants_start: "08:00",
        clock_participants_start_visible: false,
        clock_participants_start_hour: "08",
        clock_participants_start_min: "00",
        clock_participants_end_text: "18:00",
        clock_participants_end: "18:00",
        clock_participants_end_visible: false,
        clock_participants_end_hour: "18",
        clock_participants_end_min: "00",
        session_id: this.session.id,
        id: this.uuid(),
        moment: null
      };
      this.session.dates.push(date);

      for (let i = 0; i < this.trainers.length; i++) {
        this.trainers[i].dates.push({
          date_id: date.id,
          start: date.date_start + " " + date.clock_start,
          end: date.date_start + " " + date.clock_end,
          is: false
        });
      }
    },
    uuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    removeDate(index) {
      // remove from sessions dates
      const id = this.session.dates[index].id;
      this.session.dates.splice(index, 1);

      // remove from trainers dates
      for (let i = 0; i < this.trainers.length; i++) {
        let trainerIndex = this.trainers[i].dates.findIndex(d => d.id === id);
        this.trainers[i].dates.splice(trainerIndex, 1);
      }
    },
    parseDate(date) {
      if (!date) return null;

      if (date.indexOf("-") >= 0) {
        return date;
      }

      return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
    },
    americanizeDate(date) {
      if (!date) return null;

      if (date.indexOf(".") >= 0) {
        return date;
      }

      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    parseHour(date) {
      if (!date) return null;

      const [hour, minute] = date.split(":");
      return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:00`;
    },
    loadCalendar() {
      this.showCalendar = false;
      if (
        this.session.dates[this.session.dates.length - 1] &&
        this.session.dates[this.session.dates.length - 1].date_start
      ) {
        this.events = [];
        let d =
          this.session.dates[this.session.dates.length - 1].date_start +
          " 00:00:00";
        let e =
          this.session.dates[this.session.dates.length - 1].date_start +
          " 23:59:59";

        Sessions.getList({ planning: true, start: d, end: e }).then(res => {
          this.events = res.data;
          let day = moment(d).format("D");
          let month = moment(d).format("M");
          let year = moment(d).format("YYYY");
          let hour = moment(d).format("HH");

          this.calendar.start.month = [month - 1];
          this.calendar.start.dayOfMonth = [day];
          this.calendar.start.year = year;
          this.calendar.start.times = [hour];
          this.calendar.start.date = moment(d);

          this.calendar.start.dayIdentifier = null;
          this.calendar.start.dayOfWeek = null;
          this.calendar.start.dayOfYear = null;
          this.calendar.start.fullWeekOfMonth = null;
          this.calendar.start.fullWeekOfYear = null;
          this.calendar.start.hour = null;
          this.calendar.start.lastDayOfMonth = null;
          this.calendar.start.lastFullWeekOfMonth = null;
          this.calendar.start.lastFullWeekOfYear = null;
          this.calendar.start.lastWeekspanOfMonth = null;
          this.calendar.start.lastWeekspanOfYear = null;
          this.calendar.start.millis = null;
          this.calendar.start.minute = null;
          this.calendar.start.monthIdentifier = null;
          this.calendar.start.quarter = null;
          this.calendar.start.quarterIdentifier = null;
          this.calendar.start.seconds = null;
          this.calendar.start.timeIdentifier = null;
          this.calendar.start.week = null;
          this.calendar.start.weekIdentifier = null;
          this.calendar.start.weekOfMonth = null;
          this.calendar.start.weekOfYear = null;
          this.calendar.start.weekspanOfMonth = null;
          this.calendar.start.weekspanOfYear = null;

          this.calendar.end.month = [month - 1];
          this.calendar.end.dayOfMonth = [day];
          this.calendar.end.year = year;
          this.calendar.end.date = moment(e);

          this.loadState();
        });
      } else {
        this.events = [];
        this.loadState();
      }
      this.showCalendar = true;
    },
    getCalendarTime(calendarEvent) {
      let sa = calendarEvent.start.format("a");
      let ea = calendarEvent.end.format("a");
      let sh = calendarEvent.start.format("h");
      let eh = calendarEvent.end.format("h");
      if (calendarEvent.start.minute !== 0) {
        sh += calendarEvent.start.format(":mm");
      }
      if (calendarEvent.end.minute !== 0) {
        eh += calendarEvent.end.format(":mm");
      }
      return sa === ea ? sh + " - " + eh + ea : sh + sa + " - " + eh + ea;
    },
    saveState() {
      let state = this.calendar.toInput(true);
      let json = JSON.stringify(state);
      localStorage.setItem(this.storeKey, json);
    },
    loadState() {
      let state = {};
      state.events = this.events;
      state.events.forEach(ev => {
        let defaults = this.$dayspan.getDefaultEventDetails();
        ev.data = Vue.util.extend(defaults, ev.data);
      });
      if (this.$refs.app) {
        this.$refs.app.setState(state);
        let bloc = document.getElementsByClassName("v-content");
        bloc[0].style.padding = "0 0 0 0";
      }
    },
    fetchParams() {
      if(!this.pack){
        Params.getList("topics", {
          is_enabled: 1,
          current_id: this.session.topic_id ? this.session.topic_id : null,
          application: this.session.application
        }).then(res => {
          this.topics = res.data;
        });
        Params.getList("teams", {
          is_enabled: 1,
          current_id: this.session.team_id ? this.session.team_id : null
        }).then(res => {
          this.teams = res.data;
        });
        Params.getList("seasons", {
          is_enabled: 1,
          current_id: this.session.season_id ? this.session.season_id : null
        }).then(res => {
          this.seasons = res.data;
        });
      }

      Params.getList("targets-groups", {
        is_enabled: 1,
        session_type: this.session.session_type
      }).then(res => {
        this.targetGroups = res.data;
      });

      IsoLangs.getSpoken().then(res => {
        this.langs = res.data;
      });

      Params.getList("groups", { is_enabled: 1, is_event: 1 }).then(res => {
        this.groups = res.data;
      });

      Params.getList("events-settings", { is_enabled: 1 }).then(res => {
        this.settings = res.data;
        this.loadSettings();
      });

      Params.getList("activities", { is_enabled: 1 }).then(res => {
        this.activities = res.data;
        this.loadActivities();
      });

      Params.getList("groups", {
        role: [Consts.TRAINER, Consts.COORDINATION],
        is_enabled: 1
      }).then(res => {
        let groups = res.data;
        let trainers = []
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.visitors
                .map(function(e) {
                  return e.user_id;
                })
                .indexOf(groups[i].users[j].id);

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] +
                  " " +
                  groups[i].users[j]["lastname"];
                this.usersTrainersCoordination.push(groups[i].users[j]);
              }

              if (groups[i].users[j].user_roles.find(e => e.role === Consts.TRAINER)) {
                if (groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] + " " + groups[i].users[j]["lastname"];
                  trainers.push(groups[i].users[j]);
                }
              }
            }
          }
        }

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(
          this.usersTrainersCoordination.map(e => JSON.stringify(e))
        );
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.usersTrainersCoordination = result;

        this.usersTrainers = Array.from(new Set(trainers.map(e => JSON.stringify(e)))).map(e => JSON.parse(e));
      });

      Params.getList("users/allocations").then(res => {
        this.allocations = res.data;
      });

      Params.getList("contracts", { "no-year": 1 }).then(res => {
        this.contracts = res.data;
      });
    },
    loadSettings() {
      if (this.settingsLoaded == false) {
        if (!this.session.events_settings) {
          this.session.events_settings = [];
        }
        for (let i = 0; i < this.settings.length; ++i) {
          let s = this.settings[i];
          let is = this.session.events_settings
            .map(function(e) {
              return e.event_setting_id;
            })
            .indexOf(s.id);

          if (is == -1) {
            this.session.events_settings.push({
              event_setting_id: s.id,
              event_setting: {
                name: s.name,
                id: s.id
              },
              qty: 0,
              comment: ""
            });
          }
        }
        this.settingsLoaded = true;
      }
    },
    loadActivities() {
      if (this.activitiesLoaded == false) {
        if (!this.session.activities) {
          this.session.activities = [];
        }
        for (let i = 0; i < this.activities.length; ++i) {
          let a = this.activities[i];
          let is = this.session.activities
            .map(function(e) {
              return e.activity_id;
            })
            .indexOf(a.id);

          if (is == -1) {
            this.session.activities.push({
              activity_id: a.id,
              activity: {
                name: a.name,
                id: a.id
              },
              qty: 0,
              comment: ""
            });
          }
        }
        this.activitiesLoaded = true;
      }
    },
    removeTrainer(item, index) {
      let u = item;
      u.fullname = u.firstname + " " + u.lastname;
      this.trainers.splice(index, 1);
      EventBus.$emit("trainer-deleted", item);
    },
    removeVisitor(item, index) {
      this.visitors.splice(index, 1);
      EventBus.$emit("visitor-deleted", item);
    },
    formatDate(x) {
      return moment(x).format("DD.MM.YYYY");
    },
    formatTime(x) {
      return moment(x).format("HH:mm");
    },
    formatDateHuman(date) {
      return moment(date).format("ddd, MMM DD YYYY");
    },
    getDates(start, end) {
      if (this.formatDate(start) == this.formatDate(end)) {
        return this.formatDateHuman(start) + "  until " + this.formatTime(end);
      } else {
        return this.formatDateHuman(start) + " - " + this.formatDateHuman(end);
      }
    },
    addTrainer(user) {
      let dates = [];
      for (let j = 0; j < this.session.dates.length; j++) {
        dates.push({
          date_id: this.session.dates[j].id,
          start: this.session.dates[j].start,
          en: this.session.dates[j].end,
          is:
            this.session.status == Consts.BLOCKED ||
            this.session.status == Consts.TOEVALUATE ||
            this.session.status == Consts.CLOSED
              ? true
              : false
        });
      }
      this.trainers.push({
        user_id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        dates: dates,
        is_blocked: 0
      });

      this.$nextTick(() => {
        let el = document.getElementById("cbtrainer_" + this.trainers.length);
        if (el) {
          el.setAttribute("tabindex", 1000 + this.trainers.length);
        }
      });

      EventBus.$emit("trainer-added", user);
    },
    addVisitor(user) {
      this.visitors.push({
        user_id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        trainer_id: null,
        is_blocked: 0
      });
      EventBus.$emit("visitor-added", user);
    },
    internalTypeStr(x) {
      switch (x) {
        case Consts.INTERNALTRAINER:
          return "Trainer";
        case Consts.INTERNALSTUDENT:
          return "Student";
      }
    },
    colorContract(userId) {
      if (this.session.dates.length <= 0) {
        return "grey";
      } else {
        const y = moment(this.session.dates[0].start).format("YYYY");
        if (this.contracts[y] && this.contracts[y][userId]) {
          return this.contracts[y][userId].bs_label &&
            this.contracts[y][userId].bs_criminal_records &&
            this.contracts[y][userId].bs_resume
            ? "success"
            : "warning";
        } else {
          return "error";
        }
      }
    },
    updateDuration (d) {
      d.total_time = (""+Math.round(this.getDecimalHours(`${d.clock_start_hour}:${d.clock_start_min}`, `${d.clock_end_hour}:${d.clock_end_min}`)*100)/100).replace('.', ',')
    }
  }
};
</script>
<style scoped>
#dayspan {
  margin-top: 0;
}

.erliewen-block{
  background-color: #EEEEEE;
}

.printable {
  display: none;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

@media print {
  .no-printable {
    display: none;
  }

  .printable {
    display: block;
  }

  @page {
    margin: 16px 0;
    size: portrait;
  }

  body {
    height: 100%;
    width: 100%;
  }

  .print-no-break {
    page-break-inside: avoid;
  }
}
</style>
<style lang="scss">
.sidebar {
  background: transparent !important;
  padding: 0;
  position: sticky;
  top: 16px;
}

#dayspan > .v-content.ds-expand {
  padding: 0 !important;
}

#dayspan > .ds-week-header {
  display: none;
}

.v-toolbar--absolute,
.v-toolbar--fixed {
  top: unset;
  position: relative;
}

.v-navigation-drawer[data-booted="true"]:not(.menu) {
  width: 0;
  display: none;
}

.v-toolbar__content > :first-child,
.v-toolbar__extension > :first-child {
  width: 0;
  display: none;
}

.v-content[data-booted="true"] {
  padding: 0 0 0 0;
}

.container.fill-height
  > .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  display: none;
}

.session-field > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

.setup-cb
  > .v-input__control
  > .v-input__slot
  > .v-input--selection-controls__input {
  margin-right: 0px;
}

.settings-table > .v-table__overflow > table.v-table thead tr {
  height: 36px;
}

#session-form {
  .v-input__slot {
    min-height: 30px !important;
  }

  .v-text-field--box.v-text-field--single-line input,
  .v-text-field--outline.v-text-field--single-line input {
    margin-top: 0;
  }

  .v-text-field.v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field.v-text-field--enclosed .v-input__append-inner {
    margin-top: 5px;
  }

  .height-40 {
    height: 40px;
  }

  .session-field {
    padding-top: 0;
  }

  .v-card {
    .v-card__title {
      padding-top: 8px;
      padding-bottom: 0;
      font-weight: bold;
      color: #333;
    }

    .v-card__text {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .date-field {
    &.v-text-field--outline {
      .v-label {
        top: 10px;
      }

      input {
        margin-top: 15px;
      }
    }
  }

  .ds-week-header {
    .ds-day {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f4f4f4;

      .ds-week-weekday {
        font-size: 1rem;
      }

      .ds-week-date {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}
.print-table > div > table > tbody > tr > td,
.print-table > div > table > thead > tr {
  height: auto;
}
.trainer-list-item a {
  height: 30px;
}
</style>
