import Consts from "@/consts";

export default {
  computed: {
    skillRolesFreelances () {
      return [
        {value: Consts.SKILL_ROLE.TRAINING, text: 'Waiting for training'},
        {value: Consts.SKILL_ROLE.TEACHER, text: 'Teacher'},
        {value: Consts.SKILL_ROLE.INTERN, text: 'Intern'},
        {value: Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR, text: 'Specialized animator'},
        {value: Consts.SKILL_ROLE.EXPERT, text: 'Expert'}
      ]
    },
    skillRolesBeesecure () {
      return [
        {value: Consts.SKILL_ROLE.BS_CANDIDATE, text: 'Candidate'},
        {value: Consts.SKILL_ROLE.BS_TRAINER, text: 'Trainer'}
      ]
    }
  },
  methods: {
    getSkillRoleLabel (r) {
      switch (parseInt(r)) {
        case Consts.SKILL_ROLE.TRAINING:
          return 'Waiting for training'
        case Consts.SKILL_ROLE.TEACHER:
          return 'Teacher'
        case Consts.SKILL_ROLE.INTERN:
          return 'Intern'
        case Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR:
          return 'Specialized animator'
        case Consts.SKILL_ROLE.EXPERT:
          return 'Expert'
        case Consts.SKILL_ROLE.BS_CANDIDATE:
          return 'Candidate'
        case Consts.SKILL_ROLE.BS_TRAINER:
          return 'Trainer'
      }
    },
    roleName (role) {
      switch (role) {
        case Consts.COORDINATION:
          return 'Coordination';
        case Consts.TRAINER:
          return 'Trainer / Animator';
        default:
          return role;
      }
    },
  }
}
