<template>
  <div>
    <v-subheader>
      Trainers
    </v-subheader>
    <div
      v-if="session.trainers_presences && session.trainers_presences.length > 0"
      layout="column"
      layout-align="start start"
    >
      <table>
        <tr>
          <th />
          <th
            v-for="d in session.dates"
            :key="d.id"
          >
            {{ formatDateHuman(d.start) }} until {{ formatTime(d.end) }}
          </th>
        </tr>
        <tr
          v-for="t in trainers"
          :key="'trainer_'+t.user_id"
        >
          <td>{{ t.firstname }} {{ t.lastname }}</td>
          <td
            v-for="d in t.dates"
            :key="'td_'+t.user_id+d.date_id"
          >
            <v-checkbox
              v-if="(isCoord || isAdmin) && !d.is_blocked"
              class="ml-5 mr-5"
              value="false"
              disabled
            />
            <v-checkbox
              v-else-if="isTrainer && (!isCandidate(session) || isFreelances) && !(isCoord || isAdmin)"
              v-model="d.is"
              class="ml-5 mr-5"
              disabled
            >
              <div slot="label">
                <v-icon :color="d.is_blocked ? 'error' : 'success'">
                  {{ d.is_blocked ? 'mdi-lock': 'mdi-lock-open' }}
                </v-icon>
              </div>
            </v-checkbox>
            <v-checkbox
              v-else
              v-model="d.is"
              class="ml-5 mr-5"
              disabled
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import dateMixin from '@/mixins/date'
import globalMixin from '@/mixins/global'
import Consts from '@/consts'
export default {
	mixins: [dateMixin, globalMixin],
	props: {
		session: {
			type: Object,
			required: true,
			default: () => {}
		},
		trainers: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		isFreelances() {
      if (this.session.application == Consts.APPLICATION.FREELANCES) {
        return true;
      }

      return false;
    },
	},
	mounted() {
    this.loadUser()
  },
}
</script>
<style scoped>
	.v-subheader {
		padding: 0 10px;
	}
</style>
