export default {

	//Session status
	CANCELLED: 0,
	RELEASE: 2,
	TOVALIDATE: 3,
	BLOCKED: 4,
	TOEVALUATE: 5,
	CLOSED: 6,
	DRAFT: 7,
	ONHOLD: 8,
	CONFIRMED: 9,

	//Group roles
	COORDINATION: 1,
	TRAINER: 2,

	//Session types
	EVENTS: 1,
	ADULTS: 2,
	SCHOOL: 3,
	TEACHERS: 4,
	YOUTH: 5,
	OTHER: 6,
	INTERNAL: 7,
	FREELANCES_TRAINING: 8,
	ACTIVITY: 9,

	//Internal types
	INTERNALTRAINER: '1',
	INTERNALSTUDENT: '2',

	APPLICATION: {
		ALL: 0,
		BEESECURE: 1,
		FREELANCES: 2
	},

	SKILL_ROLE: {
		TRAINING: 0,
		TEACHER: 1,
		INTERN: 2,
		SPECIALIZED_ANIMATOR: 3,
		EXPERT: 4,
		BS_CANDIDATE: 5,
		BS_TRAINER: 6,
	},

	ANIMATOR_ROLE: {
		TEACHER: 1,
		SPECIALIZED: 2,
		MIXED: 3
	},
	FIELD_TYPES: {
		SIMPLE_TEXT: 1,
		LONG_TEXT: 2,
		DATE: 3,
		EMAIL: 4,
		NUMBER: 5,
		SINGLE_CHOICE: 6,
		MULTIPLE_CHOICES: 7,
		RADIO_GROUP: 8,
		SEPARATOR: 9
	},
	FIELD_DEFAULTS_VALUE: {
		NAME: 'Please fill in the label of the new field here...',
		TIP: 'You can write a tip that will describe the field and its expectations here ...'
	}
}
