import AuthApi from "@/api/auth";
import Consts from "@/consts";
import EventBus from "@/plugins/eventbus";

const storedAuth = localStorage.getItem("user");
let resumeAuthentication = false;
let isCoord = false;
let isOnlyCandidate = false;
let resumeUser = null;

if (storedAuth) {
  resumeAuthentication = true;

  try {
    resumeUser = JSON.parse(storedAuth);

    if (resumeUser.is_admin) {
      isCoord = true;
    }
    if (resumeUser.roles.indexOf(Consts.COORDINATION) > -1) {
      isCoord = true;
    }
    if(resumeUser.roles.indexOf(Consts.TRAINER) > -1) {
      //on est candidat si on n'a aucun skill ou qu'aucun de nos skill n'est pas candidate
      isOnlyCandidate =  ! (resumeUser.skills && resumeUser.skills.length > 0 && resumeUser.skills.find(s => s.role !== Consts.SKILL_ROLE.BS_CANDIDATE) === undefined)
    }
  } catch (e) {
    resumeAuthentication = false;
  }
}

export default {
  // Authentication status
  authenticated: resumeAuthentication,
  ct: isCoord,
  isOnlyCandidate,

  // Current user
  user: resumeUser,

  autologin(userdataB64) {
    const user = atob(userdataB64);
    localStorage.setItem("user", user);
    this.authenticated = true;
    this.user = JSON.parse(user);

    if (this.user.is_admin) {
      this.ct = true;
    }
    if (this.user.roles.indexOf(Consts.COORDINATION) > -1) {
      this.ct = true;
    }
    EventBus.$emit("login");
  },

  getAutologinBlob() {
    return btoa(JSON.stringify(this.user));
  },

  login(username, password) {
    return new Promise((resolve, reject) => {
      AuthApi.login(username, password).then(
        res => {
          localStorage.clear();
          const user = res.data;
          localStorage.setItem("user", JSON.stringify(user));
          this.authenticated = true;
          this.user = user;

          if (this.user.is_admin) {
            this.ct = true;
          }
          if (this.user.roles.indexOf(Consts.COORDINATION) > -1) {
            this.ct = true;
          }

          EventBus.$emit("login");
          resolve(user);
        },
        e => {
          reject(e);
        }
      );
    });
  },

  refresh() {
    return new Promise((resolve, reject) => {
      AuthApi.info().then(
        res => {
          const user = res.data;
          localStorage.setItem("user", JSON.stringify(user));
          this.user = user;

          if (this.user.is_admin) {
            this.ct = true;
          }
          if (this.user.roles.indexOf(Consts.COORDINATION) > -1) {
            this.ct = true;
          }

          resolve(user);
        },
        e => {
          reject(e);
        }
      );
    });
  },

  logout () {
    AuthApi.logout().then(() => {
      this.localLogout();
    }).catch(() => {
      this.localLogout();
    })
  },
  localLogout () {
    localStorage.clear();
    this.authenticated = false;
    this.ct = false;
    this.user = null;
    EventBus.$emit("logout");
  }
};
