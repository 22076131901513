<template>
  <div class="ma-3">
    <div class="title mb-3">
      Settings <v-icon>mdi-chevron-right</v-icon> Users
      <v-icon>mdi-chevron-right</v-icon>
      {{ isNew ? "New" : user.firstname + " " + user.lastname }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      autocomplete="off"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          User information
        </div>
        <v-text-field
          v-model="user.firstname"
          outline
          label="First name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.lastname"
          outline
          label="Last name"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.email"
          outline
          label="E-mail"
          class="field-width"
          type="email"
        />
        <v-text-field
          v-model="user.phone"
          outline
          label="Phone number"
          class="field-width"
        />
        <v-text-field
          v-model="user.comment"
          outline
          label="Comment"
          class="field-width"
        />

        <div class="title font-weight-light mb-3">
          Login information
        </div>
        <v-text-field
          v-model="user.login"
          outline
          label="Login"
          class="field-width"
          :rules="[v => !!v || 'This field is required']"
        />
        <v-text-field
          v-model="user.password"
          outline
          label="Password"
          class="field-width"
          type="password"
          autocomplete="new-password"
        />

        <div class="title font-weight-light mb-3">
          Group information
        </div>
        <v-select
          v-model="user.groups"
          v-focus
          multiple
          outline
          label="Groups"
          :items="groups"
          item-value="id"
          item-text="name"
        />

        <v-select
          v-model="user.user_roles"
          v-focus
          outline
          multiple
          label="Role"
          :rules="[ (v) => v.length > 0 || 'This field is required' ]"
          :items="roles"
          @change="clearCTOptions()"
        />

        <template v-if="isCT">
          <v-select
            v-model="user.application"
            v-focus
            outline
            label="Application"
            :items="applications"
            :rules="[v => !!v || v === 0 || 'This field is required']"
          />
          <v-checkbox
            v-model="user.is_admin"
            label="This is an administrator (can access the administration)"
            :false-value="0"
            :true-value="1"
          />
          <v-checkbox
            v-model="user.is_team_responsible"
            label="This is a team responsible (allows indemnities' managment)"
            :false-value="0"
            :true-value="1"
            @change="clearResponsibleOf"
          />

           <v-select
            v-if="user.is_team_responsible"
            v-model="user.responsible_of_team_id"
            v-focus
            outline
            clearable
            label="in charge of the team"
            :rules="[v => !!v || 'This field is required' ]"
            :items="teams"
            item-value="id"
            item-text="name"
          />
        </template>
      </div>

      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{ name: 'admin-settings-users-index' }"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import Consts from "@/consts";
import GlobalMixin from "@/mixins/global";

export default {
  mixins: [GlobalMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name;
    });
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      formValid: false,
      groups: [],
      teams: [],
      prevRoute: null,
      roles: [
          { text: 'Coordination', value: Consts.COORDINATION },
          { text: 'Trainer / Animator', value: Consts.TRAINER },
      ],
      user: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        comment: "",
        login: "",
        password: "",
        groups: [],
        user_roles: [],
        application: null,
        is_admin: false,
        is_team_responsible: false,
        responsible_of_team_id: null
      }
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    },
    isTra() {
      if(this.user.user_roles && this.user.user_roles.length > 0) {
        return this.user.user_roles.find(e => e == Consts.TRAINER) !== undefined
      }
      return false;
    },
    isCT() {
      if(this.user.user_roles && this.user.user_roles.length > 0) {
        return this.user.user_roles.find(e => e == Consts.COORDINATION) !== undefined
      }
      return false;
    }
  },
  mounted() {
    const prGroups = Params.getList("groups", { is_enabled: 1, sortBy: "name" })
    const prTeams = Params.getList("teams", { is_enabled: 1, sortBy: "name"})
    Promise.all([prGroups, prTeams]).then(res => {
      this.groups = res[0].data;
      this.teams = res[1].data;
      if(this.$route.params.id && this.$route.params.id != 'new') {
        this.fetch()
      }
    })
  },
  methods: {
    fetch() {
      Params.get("users", this.$route.params.id).then(res => {
        this.user = res.data;

        if (this.user && this.user.groups && this.user.groups.length > 0) {
          for (let i = 0; i < this.user.groups.length; i++) { //petite transformation des données pour n'avoir que le rôle
            this.user.groups[i] = this.user.groups[i].id
          }
        }
        if (this.user && this.user.user_roles && this.user.user_roles.length > 0) {
          for (let i = 0; i < this.user.user_roles.length; i++) { //petite transformation des données pour n'avoir que le rôle
            this.user.user_roles[i] = this.user.user_roles[i].role
          }
        }
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("users", this.user).then(() => {
          this.$router.push({
            name:
              this.prevRoute !== null
                ? this.prevRoute
                : "admin-settings-users-index"
          });
          this.$snotify.success(
            `The user "${this.user.firstname} ${this.user.lastname}" has been created`
          );
        });
      } else {
        Params.update("users", this.user.id, this.user).then(() => {
          this.$router.push({
            name:
              this.prevRoute !== null
                ? this.prevRoute
                : "admin-settings-users-index"
          });
          this.$snotify.success(
            `The user "${this.user.firstname} ${this.user.lastname}" has been updated`
          );
        });
      }
    },
    clearCTOptions() {
      if(! this.isCT) {
        this.user.application = null
        this.user.is_admin = false
        this.user.is_team_responsible = false
        this.user.responsible_of_team_id = null
      }
    },
    clearResponsibleOf() {
      if(!this.user.is_team_responsible) {
        this.user.responsible_of_team_id = null
      }
    }
  }
};
</script>
