<template>
  <div class="full-width">
    <div
      layout="row"
      class="title pl-3 pt-3"
    >
      <span class="mr-2">Planning</span>
      <v-menu
        v-if="isTrainer"
        v-model="icsModal"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        offset-y
        @input="onIcsChange"
      >
        <template slot="activator">
          <v-icon title="ICalendar publishing link">
            mdi-access-point
          </v-icon>
        </template>
        <v-card style="max-width: 300px">
          <v-card-title class="title">
            ICalendar publishing link
          </v-card-title>
          <v-card-text>
            <p>
              You can use the following URL to publish an ICS version of
              your planning.
            </p>
            <v-text-field
              id="ics-url"
              v-model="icsUrl"
              solo
              readonly
              label="URL"
              append-icon="mdi mdi-content-copy"
              @click:append="copyURL"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              flat
              @click="icsModal = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
    <div
      id="days-slider"
      layout="row"
      class="my-2 full-width"
    >
      <v-btn
        icon
        class="arrow-btn ml-auto"
        @click="prev"
      >
        <v-icon large>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <transition-group
        :name="(slide == 'next') ? 'slide-next' : 'slide-prev'"
        layout="row"
      >
        <div
          v-for="d in days"
          :key="d"
          layout="column"
          layout-align="start center"
        >
          <v-btn
            fab
            :outline="date != d"
            :color="date == d ? 'primary' : ''"
            small
            @click="goTo(d)"
          >
            {{ moment(d).format('DD') }}
          </v-btn>
          <div>{{ moment(d).format('dd') }}</div>
        </div>
      </transition-group>
      <v-btn
        icon
        class="arrow-btn mr-auto"
        @click="next"
      >
        <v-icon large>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
    <div
      layout="row"
      class="full-width px-3"
    >
      <v-divider />
    </div>
    <div
      layout="row"
      layout-align="start center"
    >
      <v-btn
        flat
        class="basic-text"
        @click="showFilters = !showFilters"
      >
        <v-icon class="mr-1">
          mdi-filter-variant
        </v-icon>
        Filters
        <v-chip
          v-if="nbFilters"
          color="red"
          text-color="white"
          small
          class="ml-1"
        >
          {{ nbFilters }}
        </v-chip>
      </v-btn>
      <span flex />
      <v-btn
        icon
        @click="today"
      >
        <v-icon>
          mdi-backup-restore
        </v-icon>
      </v-btn>
      <span class="mr-4">{{ formatDayHuman(date) }}</span>
    </div>
    <div
      v-show="showFilters"
      layout="column"
      class="full-width px-3 mb-2"
    >
      <div
        layout="row"
        class="title font-weight-light pl-2 mb-2"
      >
        <span flex />
        <v-icon
          v-if="isAdmin"
          title="Planning settings"
          @click="$router.push({ name: 'admin-planning-periodtypes-index' })"
        >
          mdi-cog
        </v-icon>
        <v-icon
          title="Reset filters"
          @click="reset"
        >
          mdi-reload
        </v-icon>
        <v-icon
          title="Hide filters"
          @click="showFilters = false"
        >
          mdi-eye-off
        </v-icon>
      </div>
      <div
        layout="row"
        class="mb-2"
      >
        <v-text-field
          v-model="keywords"
          prepend-inner-icon="mdi-magnify"
          placeholder="Keywords: organization, contact..."
          outline
          single-line
          hide-details
          @keypress.enter="fetch"
        />
      </div>
      <div
        layout="row"
        class="mb-2"
      >
        <v-select
          v-model="params.application"
          v-focus
          :items="applications"
          hide-details
          single-line
          label="Application"
          class="pt-0 mb-2 mx-2"
          @input="
            params.session_type = [];
            fetch();
          "
        />
      </div>
      <v-expansion-panel
        v-model="expandStatus"
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Status
          </div>
          <div
            v-for="s in status"
            :key="`status_${s.value}`"
            layout="row"
            layout-align="start center"
            class="px-3 pb-3"
          >
            <v-avatar
              :title="s.text"
              :color="sessionStatusColor(s.value)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(s.value) }}
            </v-avatar>
            <v-checkbox
              v-model="params.status[s.value]"
              hide-details
              :label="s.text"
              class="my-0"
              @change="fetch"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-model="expandTypes"
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Session type
          </div>
          <div
            v-for="s in sessionTypes"
            :key="`session_type_${s.value}`"
            layout="row"
            layout-align="start center"
            class="px-3 pb-3"
          >
            <v-checkbox
              v-model="params.session_type[s.value]"
              hide-details
              class="my-0 pt-0"
              style="flex: unset"
              @change="fetch"
            />
            <v-chip
              :color="sessionTypeColor(s.value)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(s.value) }}
            </v-chip>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-model="expandTargetGroups"
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Target group
          </div>
          <div class="px-3 pb-3">
            <v-checkbox
              v-for="tg in targetGroups"
              :key="`tg_${tg.id}`"
              v-model="params.target_group_id"
              hide-details
              class="my-0 pt-0"
              :value="tg.id"
              style="flex: unset"
              :label="tg.name"
              @change="fetch"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Period types
          </div>
          <div class="px-3 pb-3">
            <span
              v-for="p in periodsTypes"
              :key="'period_' + p.id"
              class="ml-2"
            >
              <v-avatar
                small
                :size="20"
                :color="p.color"
              />&nbsp;{{
                p.name
              }}
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-model="expandLangs"
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Languages
          </div>
          <div class="px-3 pb-3">
            <v-checkbox
              v-for="l in langs"
              :key="`lang_${l.id}`"
              v-model="params.langs"
              hide-details
              :label="l.name"
              :value="l.id"
              @change="fetch"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-model="expandTeams"
        popout
        expand
      >
        <v-expansion-panel-content>
          <div slot="header">
            Team
          </div>
          <div class="px-3 pb-3">
            <v-checkbox
              v-for="c in teams"
              :key="`team_${c.id}`"
              v-model="params.team_id"
              hide-details
              class="my-0 pt-0"
              :value="c.id"
              style="flex: unset"
              :label="c.name"
              @change="fetch"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>
    <div
      layout="row"
      class="full-width px-3 mb-2"
    >
      <v-divider />
    </div>
    <div
      v-show="loading"
      class="pa-5 text-xs-center full-width"
    >
      <v-progress-circular
        indeterminate
        :size="32"
        color="accent"
      />
    </div>
    <template v-if="!loading">
      <template v-if="Object.values(sessions).length > 0">
        <div
          v-for="(ss, h) in sessions"
          :key="h"
          layout="column"
          class="px-3"
        >
          <div
            layout="row"
            layout-align="start center"
            class="full-width"
          >
            <div>{{ h }}</div>
            <div
              layout="column"
              flex
            >
              <v-divider />
            </div>
          </div>
          <div
            v-for="s in ss"
            :key="h + s.data.ref + s.data.date_id"
            class="session"
            layout="column"
            :style="{
              backgroundColor: s.data.color
            }"
            @click="openSession(s)"
          >
            <div
              layout="row"
              class="full-width"
            >
              <v-avatar
                :color="s.data.status.color"
                :size="21"
                class="black--text font-weight-medium mr-1"
                :class="{ 'black--text': s.data.session.status == 0 }"
              >
                {{ s.data.status.str }}
              </v-avatar>
              <v-icon
                slot="badge"
                small
                class="mr-1"
              >
                <template
                  v-if="
                    s.data.session.application ===
                      Consts.APPLICATION.BEESECURE
                  "
                >
                  icon-bee48
                </template>
                <template v-else>
                  icon-kayak48
                </template>
              </v-icon>
              #{{ s.data.ref }} - {{ s.data.hours }}
              <template v-if="s.data.city">
                <span class="mx-1">/</span>
                <b class="overflow-line">{{ s.data.city }}</b>
              </template>
              <span flex />
              <v-checkbox
                v-if="isTrainer && (!isCandidate(s.data.session) || isFreelancesObj(s.data.session)) && (s.data.session.status == Consts.RELEASE || s.data.session.status == Consts.TOVALIDATE || s.data.session.status == Consts.BLOCKED || s.data.session.status == Consts.TOEVALUATE) && canTrain(s.data.session, s.data)"
                v-model="s.data.is_trainer"
                class="presence-toggle"
                hide-details
                :ripple="false"
                :disabled="!canTrain(s.data.session, s.data)"
                @click.prevent.stop="setPresence(s.data)"
              />
              <template v-else>
                <v-checkbox
                  v-if="canParticipate(s.data.session)"
                  v-model="s.data.is_intern"
                  class="presence-toggle"
                  hide-details
                  :ripple="false"
                  @click.prevent.stop="setIntern(s.data)"
                />
                <v-checkbox
                  v-else-if="canUnparticipate(s.data.session)"
                  v-model="s.data.is_intern"
                  class="presence-toggle"
                  hide-details
                  :ripple="false"
                  @click.prevent.stop="setIntern(s.data)"
                />
                <v-checkbox
                  v-else-if="s.data.is_intern"
                  v-model="s.data.is_intern"
                  class="presence-toggle"
                  hide-details
                  :ripple="false"
                  disabled
                  color="grey"
                />
                <v-checkbox
                  v-else-if="s.data.is_trainer"
                  v-model="s.data.is_trainer"
                  class="presence-toggle"
                  hide-details
                  :ripple="false"
                  disabled
                  color="grey"
                />
              </template>
            </div>
            <div
              v-if="s.data.location"
              layout="row"
              class="overflow-line"
            >
              {{ s.data.location }}
            </div>
            <div
              v-if="s.data.session.team"
              layout="row"
              class="overflow-line"
            >
              {{ s.data.session.team.name }}
            </div>
            <div
              v-if="s.data.session.topic"
              layout="row"
              class="overflow-line"
            >
              {{ s.data.session.topic.name }}
            </div>
            <div
              v-if="s.data.trainers && s.data.trainers.length"
              layout="row"
              layout-wrap
              class="mt-2"
            >
              <span
                v-for="(t, ti) in s.data.trainers"
                :key="'trainer_' + h + s.data.ref + t.id"
              >
                {{ (ti > 0) ? ', ' : '' }}{{ t.firstname }} {{ t.lastname }}
              </span>
            </div>
            <div layout="row">
              <comment-icon
                :item="s.data.session"
                small
                class="info-icon-list"
              />
              <special-training-icon
                :item="s.data.session"
                small
                class="info-icon-list"
              />
              <div
                v-if="s.data.session.is_inclusive"
                class="v-menu info-icon-list v-menu--inline"
              >
                <div
                  layout="column"
                  flex
                >
                  <v-icon
                    class="my-auto"
                    title="Is inclusive"
                    small
                    color="primary"
                  >
                    mdi-all-inclusive
                  </v-icon>
                </div>
              </div>
              <div
                v-if="s.data.session.is_online"
                class="v-menu info-icon-list v-menu--inline"
              >
                <div
                  layout="column"
                  flex
                >
                  <v-icon
                    class="my-auto"
                    title="Is online"
                    small
                    color="primary"
                  >
                    mdi-cast-education
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="mx-2 my-3 pa-2 text-xs-center font-italic grey--text text--darken-2"
      >
        No session on this day
      </div>
    </template>
    <v-dialog
      v-model="sessionDialog"
      max-width="100%"
    >
      <v-card
        v-if="sessionDialog"
        class="ds-calendar-event-popover-card"
      >
        <v-toolbar
          extended
          flat
          height="76"
          extension-height="32"
          :color="displayedSession.session ? sessionTypeColorLighten(displayedSession.session.session_type) : null"
        >
          <div
            layout="column"
            class="d-flex"
            style="width: 100%"
          >
            <div
              layout="row"
              layout-align="start center"
              class="mt-2"
            >
              <v-avatar
                v-if="displayedSession.status"
                :color="displayedSession.status.color"
                class="black--text title mr-2 font-weight-medium"
                :class="{'white--text' : (displayedSession.session.status == 0)}"
                :size="32"
              >
                {{ displayedSession.status.str }}
              </v-avatar>
              <span class="title">Session</span>
              <v-badge
                class="text-no-wrap mr-3 ml-2"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                  class="mr-1"
                >
                  <template v-if="displayedSession.session.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <span class="title">#{{ displayedSession.session.reference }}</span>
              </v-badge>
            </div>
            <div
              layout="row"
              class="pl-1 pt-2"
            >
              {{ formatDayHuman(date) }} / {{ displayedSession.from }} - {{ displayedSession.to }}
            </div>
          </div>
          <v-toolbar-title
            slot="extension"
            style="display: flex; width: 100%"
            layout="row"
            layout-align="start center"
          >
            <span flex />
            <div
              v-if="displayedSession.session.is_inclusive"
              class="v-menu info-icon-dialog v-menu--inline"
            >
              <div
                layout="column"
                flex
              >
                <v-icon
                  class="my-auto"
                  title="Is inclusive"
                  small
                  color="primary"
                >
                  mdi-all-inclusive
                </v-icon>
              </div>
            </div>
            <div
              v-if="displayedSession.session.is_online"
              class="v-menu info-icon-dialog v-menu--inline"
            >
              <div
                layout="column"
                flex
              >
                <v-icon
                  class="my-auto"
                  title="Is online"
                  small
                  color="primary"
                >
                  mdi-cast-education
                </v-icon>
              </div>
            </div>
            <v-chip
              v-if="displayedSession.session_type"
              :color="sessionTypeColorLight(displayedSession.session.session_type)"
              small
            >
              {{ displayedSession.session_type.str }}
            </v-chip>
          </v-toolbar-title>
          <v-btn
            v-if="displayedSession.session"
            color="secondary"
            small
            absolute
            bottom
            left
            fab
            icon
            style="display: block; width: 40px"
            :to="{ name: 'sessions-show-info', params: { id: displayedSession.session.id } }"
          >
            <v-icon>mdi mdi-{{ (!isAdmin && !isCoord) ? 'eye' : 'pencil' }}</v-icon>
          </v-btn>
          <v-btn
            v-if="isTrainer && (!isCandidate(displayedSession.session) || isFreelancesObj(displayedSession.session)) && (displayedSession.session.status == Consts.RELEASE || displayedSession.session.status == Consts.TOVALIDATE || displayedSession.session.status == Consts.BLOCKED || displayedSession.session.status == Consts.TOEVALUATE) && canTrain(displayedSession.session, displayedSession)"
            color="secondary"
            small
            absolute
            bottom
            left
            fab
            icon
            style="display: block; width: 40px"
            class="ml-5"
            @click.prevent.stop="setPresence(displayedSession)"
          >
            <v-icon>mdi-checkbox-{{ displayedSession.is_trainer ? 'marked' : 'blank-outline' }}</v-icon>
          </v-btn>
          <template v-else>
            <v-btn
              v-if="canParticipate(displayedSession.session)"
              color="secondary"
              small
              absolute
              bottom
              left
              fab
              icon
              style="display: block; width: 40px"
              class="ml-5"
              @click.prevent.stop="setIntern(displayedSession)"
            >
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>
            <v-btn
              v-else-if="canUnparticipate(displayedSession.session)"
              color="secondary"
              small
              absolute
              bottom
              left
              fab
              icon
              style="display: block; width: 40px"
              class="ml-5"
              @click.prevent.stop="setIntern(displayedSession)"
            >
              <v-icon>mdi-checkbox-marked</v-icon>
            </v-btn>
            <v-btn
              v-else-if="displayedSession.is_trainer || displayedSession.is_intern"
              color="grey lighten-2"
              small
              absolute
              bottom
              left
              fab
              icon
              style="display: block; width: 40px"
              class="ml-5"
            >
              <v-icon color="grey">
                mdi-checkbox-marked
              </v-icon>
            </v-btn>
          </template>
          <v-spacer />
          <v-btn
            icon
            class="mb-auto"
            @click="sessionDialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-alert
            v-if="
              displayedSession.session.special_training ||
                displayedSession.session.location ||
                (displayedSession.session.visitors && displayedSession.session.visitors.length)
            "
            :value="true"
            color="warning"
            class="session mt-2"
          >
            <div
              layout="row"
              layout-align="start center"
            >
              <v-icon
                color="white"
                class="mr-2"
              >
                mdi-alert
              </v-icon>
              <div>
                <template v-if="displayedSession.session.special_training">
                  Special training
                </template>
                <template v-else-if="displayedSession.session.location">
                  This session has other location
                </template>
                <template v-else>
                  This session has visitor(s)
                </template>
              </div>
            </div>
          </v-alert>
          <div
            class="session mt-2 grey lighten-4"
            layout="column"
          >
            <div class="subheading font-weight-bold mb-2">
              Info
            </div>
            <div
              v-if="displayedSession.location"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-bank
              </v-icon>
              <span>{{ displayedSession.location }}</span>
            </div>
            <div
              v-if="displayedSession.session.team"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-domain
              </v-icon>
              <span>{{ displayedSession.session.team.name }}</span>
            </div>
            <div
              v-if="displayedSession.session.topic"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-tag
              </v-icon>
              <span>{{ displayedSession.session.topic.name }}</span>
            </div>
            <div
              v-if="displayedSession.address"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-map-marker
              </v-icon>
              <span>
                <a
                  :href="'https://www.google.com/maps/search/?api=1&query=' + displayedSession.address"
                  target="_blank"
                >{{ displayedSession.address }}</a>
              </span>
            </div>
            <div
              v-if="displayedSession.contact"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-account
              </v-icon>
              <span>{{ displayedSession.contact }} <span v-if="displayedSession.session.contact && displayedSession.session.contact.phone">(<a :href="`tel:${displayedSession.session.contact.phone}`">{{ displayedSession.session.contact.phone }}</a>)</span></span>
            </div>
            <div
              v-if="displayedSession.session.referent_fullname || displayedSession.session.class_name || displayedSession.session.room || displayedSession.session.lang_id"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-school
              </v-icon>
              <span v-if="displayedSession.session.referent_fullname">{{ displayedSession.session.referent_fullname }} - </span>
              <span class="mr-1">{{ displayedSession.session.class_name }}</span>
              <span
                v-if="displayedSession.session.room"
                class="mr-1"
              >({{ displayedSession.session.room }})</span>
              <span
                v-if="displayedSession.session.lang_id"
                class="font-weight-bold text-uppercase"
              >[{{ displayedSession.session.lang_id }}]</span>
            </div>
            <div
              v-if="displayedSession.session.nb_participants"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-account-group
              </v-icon>
              <span>{{ displayedSession.session.nb_participants }} pupil{{ displayedSession.session.nb_participants > 1 ? 's': '' }}</span>
            </div>
            <div
              v-if="displayedSession.session.organization && displayedSession.session.organization.parking"
              layout="row"
              layout-align="start center"
            >
              <v-icon class="mr-2">
                mdi-parking
              </v-icon>
              <span>{{ displayedSession.session.organization.parking }}</span>
            </div>
          </div>
          <div
            v-if="displayedSession.session.comments"
            class="session mt-2 grey lighten-4"
            layout="column"
          >
            <div class="subheading font-weight-bold mb-2">
              Comments
            </div>
            <div
              class="nl2br"
              v-text="displayedSession.session.comments"
            />
          </div>
          <div
            v-if="displayedSession.trainers && displayedSession.trainers.length"
            class="session mt-2 grey lighten-4"
            layout="column"
          >
            <div class="subheading font-weight-bold mb-2">
              Trainers
            </div>
            <ul>
              <li
                v-for="t in displayedSession.trainers"
                :key="t.if"
              >
                {{ t.firstname }} {{ t.lastname }}
              </li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import Isolangs from "@/api/isolangs";
import GlobalMixin from "@/mixins/global";
import Params from "@/api/params";
import DateMixin from "@/mixins/date";
import SessionsMixin from "@/mixins/sessions";
import PlanningMixin from "@/mixins/planning";
import Sessions from "@/api/sessions";
import Consts from "@/consts";
import CommentIcon from "@/components/shared/CommentIcon";
import SpecialTrainingIcon from "@/components/shared/SpecialTrainingIcon";
import Auth from "@/plugins/auth";

export default {
	components: {CommentIcon, SpecialTrainingIcon},
	mixins: [GlobalMixin, SessionsMixin, DateMixin, PlanningMixin],
	data() {
		return {
			start: null,
			end: null,
			date: null,
			params: {
        trainers: [],
        status: [],
        session_type: [],
        langs: [],
        target_group_id: [],
        team_id: [],
        application: '',
        reference: null,
      },
      langs: [],
      groups: [],
      targetGroups: [],
      teams: [],
			keywords: null,
			expandStatus: [],
      expandTrainers: [],
      expandLangs: [],
      expandTargetGroups: [],
      expandTeams: [],
      expandTypes: [],
      trainers: [],
      icsModal: false,
      moment,
      Consts,
      periodsTypes: [],
      periods: [],
			paramsStoreKey: 'mobile-planning-params',
			dateStoreKey: 'mobile-planning-date',
			loading: false,
			showFilters: false,
			sessions: {},
			displayedSession: {},
			sessionDialog: false,
			touchstartX: null,
			touchstartY: null,
			touchendX: null,
			touchendY: null,
			slide: 'next'
		}
	},
	computed: {
		sessionTypes() {
      const bs = [
        { value: Consts.EVENTS, text: this.sessionTypeStr(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStr(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStr(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStr(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStr(Consts.YOUTH) },
        { value: Consts.INTERNAL, text: this.sessionTypeStr(Consts.INTERNAL) },
        { value: Consts.OTHER, text: this.sessionTypeStr(Consts.OTHER) }
      ];

      const fl = [
        {
          value: Consts.FREELANCES_TRAINING,
          text: this.sessionTypeStr(Consts.FREELANCES_TRAINING)
        },
        { value: Consts.ACTIVITY, text: this.sessionTypeStr(Consts.ACTIVITY) }
      ];

      switch (this.params.application) {
        case Consts.APPLICATION.BEESECURE:
          return bs;
        case Consts.APPLICATION.FREELANCES:
          return fl;
        case Consts.APPLICATION.ALL:
        default:
          return bs.concat(fl);
      }
    },
    status() {
      let out = [
        { value: Consts.CANCELLED, text: "Cancelled" },
        { value: Consts.RELEASE, text: "Release" },
        { value: Consts.TOVALIDATE, text: "To validate" },
        { value: Consts.BLOCKED, text: "Blocked" },
        { value: Consts.TOEVALUATE, text: "To evaluate" },
        { value: Consts.CLOSED, text: "Closed" }
      ];
      if (this.isCoord || this.isAdmin) {
        out = [
          ...[
            { value: Consts.ONHOLD, text: "On hold" },
            { value: Consts.DRAFT, text: "Draft" },
            { value: Consts.DRAFT, text: "Confirmed" }
          ],
          ...out
        ];
      }
      return out;
    },
		days() {
				var dates = []

				if (this.start && this.end) {
						var now = moment(this.start).clone()

						while (now.isSameOrBefore(this.end)) {
								dates.push(now.format('YYYY-MM-DD'))
								now.add(1, 'days')
						}
				}

				return dates
		},
		filtersArray() {
      let params = Object.assign(
        {},
        this.params,
        { keywords: this.keywords },
				{ planning: true },
				{ start: this.date },
				{ end: this.date },
      );

      params.status = [];
      params.session_type = [];

      for (let i in this.params.status) {
        const enabled = this.params.status[i];

        if (enabled) {
          params.status.push(i);
        }
      }

      for (let i in this.params.session_type) {
        const enabled = this.params.session_type[i];

        if (enabled) {
          params.session_type.push(i);
        }
      }

      return params;
		},
		nbFilters(){
			let nb = 0;
			for(let k in this.filtersArray){
				if(Array.isArray(this.filtersArray[k]) && this.params[k].length){
					nb += this.filtersArray[k].length
				}
			}

			return nb
		}
	},
	beforeMount() {
		moment.updateLocale('en', {
			week: {
				dow: 1, // First day of week is Monday
				doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
			}
		})
	},
	mounted() {
		this.params.application = this.getStoredApp()

		let storedParams = JSON.parse(localStorage.getItem(this.paramsStoreKey));
		if (storedParams) {
			this.params = storedParams;
			this.params.application = this.getStoredApp();
			if (this.params.date) {
				this.date = this.params.date;
			}

			if (storedParams.session_type.length) {
				let et = [];
				for (let i in storedParams.session_type) {
					const enabled = storedParams.session_type[i];
					if (enabled) {
						et.push(i);
					}
				}
				if (et.length) {
					this.expandTypes = [...Array(et).keys()].map(() => true);
				}
			}
			if (storedParams.status.length) {
				let et = [];
				for (let i in storedParams.status) {
					const enabled = storedParams.status[i];
					if (enabled) {
						et.push(i);
					}
				}
				if (et.length) {
					this.expandStatus = [...Array(et).keys()].map(() => true);
				}
			}
			if (storedParams.trainers.length) {
				this.expandTrainers = [...Array(storedParams.trainers).keys()].map(
					() => true
				);
			}
			if (storedParams.langs.length) {
				this.expandLangs = [...Array(storedParams.langs).keys()].map(() => true);
			}
			if (storedParams.target_group_id.length) {
				this.expandTargetGroups = [
					...Array(storedParams.target_group_id).keys()
				].map(() => true);
			}
			if (storedParams.team_id.length) {
				this.expandTeams = [...Array(storedParams.team_id).keys()].map(
					() => true
				);
			}
		}

		let storedDate = JSON.parse(localStorage.getItem(this.dateStoreKey));
		if(storedDate){
			this.date = storedDate
			var now = moment(storedDate)
			this.start = now.clone().weekday(0).format('YYYY-MM-DD')
			this.end = now.clone().weekday(6).format('YYYY-MM-DD')
			this.fetch()
		} else {
			this.today()
		}

		this.fetchParams();
		this.loadUser();

		document.getElementById('days-slider').addEventListener('touchstart',  (event) => {
				this.touchstartX = event.changedTouches[0].screenX;
				this.touchstartY = event.changedTouches[0].screenY;
		}, false);

		document.getElementById('days-slider').addEventListener('touchend',  (event) => {
				this.touchendX = event.changedTouches[0].screenX;
				this.touchendY = event.changedTouches[0].screenY;
				this.handleGesture();
		}, false);
	},
	destroyed() {
		document.getElementById('days-slider').removeEventListener('touchstart')
		document.getElementById('days-slider').removeEventListener('touchend')
	},
	methods: {
		handleGesture(){
			if (this.touchendX < this.touchstartX) {
				this.next()
			}
			if (this.touchendX > this.touchstartX) {
				this.prev()
			}
		},
		openSession(s){
			this.displayedSession = s.data
			this.sessionDialog = true
		},
		goTo(d){
			this.date = d
			this.fetch()
		},
		reset() {
      this.params = {
        trainers: [],
        langs: [],
        status: [],
        session_type: [],
        target_group_id: [],
        team_id: [],
        application: this.getDefaultApp(),
        reference: null
			};
			this.keywords = null

      this.fetch();
    },
		today() {
				var now = moment()
				this.start = now.clone().weekday(0).format('YYYY-MM-DD')
				this.end = now.clone().weekday(6).format('YYYY-MM-DD')
				this.date = now.clone().format('YYYY-MM-DD')
				this.fetch()
		},
		next() {
				this.start = moment(this.start).add(7, 'days').format('YYYY-MM-DD')
				this.end = moment(this.end).add(7, 'days').format('YYYY-MM-DD')
				this.date = moment(this.date).add(7, 'days').format('YYYY-MM-DD')
				this.slide = 'next'
				this.fetch()
		},
		prev() {
				this.start = moment(this.start).subtract(7, 'days').format('YYYY-MM-DD')
				this.end = moment(this.end).subtract(7, 'days').format('YYYY-MM-DD')
				this.date = moment(this.date).subtract(7, 'days').format('YYYY-MM-DD')
				this.slide = 'prev'
				this.fetch()
		},
		fetch() {
			this.loading = true;
			this.sessions = {}

			Sessions.getList(this.filtersArray).then(res => {
				let sessions = {}
				for (let i = 0; i < res.data.length; i++) {
					let e = res.data[i];
					const from = e.data.from
					if(sessions[from]){
						sessions[from].push(e)
					}else{
						sessions[from] = [e]
					}
				}

				this.sessions = sessions
				this.loading = false;
				localStorage.setItem(this.paramsStoreKey, JSON.stringify(this.params));
				localStorage.setItem(this.dateStoreKey, JSON.stringify(this.date));
				this.setStoredApp(this.params.application);
			});
    },
    fetchParams() {
      Params.getList("groups", { role: Consts.TRAINER }).then(res => {
        this.groups = res.data;

        let groups = res.data;
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.trainers
                .map(function(e) {
                  return e.user_id;
                })
                .indexOf(groups[i].users[j].id);

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] +
                  " " +
                  groups[i].users[j]["lastname"];
                this.trainers.push(groups[i].users[j]);
              }
            }
          }
        }
        this.trainers = this.trainers.sort((a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
        );

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.trainers.map(e => JSON.stringify(e)));
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.trainers = result;
      });
      Params.getList("periods-types", { is_enabled: 1 }).then(res => {
        this.periodsTypes = res.data;
      });
      Params.getList("targets-groups", { is_enabled: 1 }).then(res => {
        this.targetGroups = res.data;
      });
      Params.getList("teams", { is_enabled: 1 }).then(res => {
        this.teams = res.data;
        this.teams.unshift({ id: null, name: "None" });
      });
      Isolangs.getSpoken().then(res => {
        this.langs = res.data;
      });
		},
		setIntern(event){
      if (
        event.is_blocked
      ) {
        return;
      }

      this.loading = true
      this.participate(event.session, () => {
        this.fetch()
        this.loading = false
      })
    },
    setPresence(event) {
      if (
        event.is_blocked ||
        event.status.val == Consts.BLOCKED ||
        event.status.val == Consts.TOEVALUATE
      ) {
        return;
      }

      this.loading = true;

      event.is_trainer = !event.is_trainer;
      let s = event.session;
      s.presences = {};

      if (s.dates) {
        for (let k = 0; k < s.dates.length; k++) {
          s.presences[s.dates[k].id] = {
            start: s.dates[k].start,
            end: s.dates[k].end,
            is: false,
            is_blocked: false
          };
        }
      }

      if (s.trainers_presences && s.trainers_presences.length) {
        let is = s.trainers_presences
          .map(function(e) {
            return e.user_id;
          })
          .indexOf(Auth.user.id);

        if (
          is > -1 &&
          s.trainers_presences[is].presences &&
          s.trainers_presences[is].presences.length
        ) {
          for (let l = 0; l < s.trainers_presences[is].presences.length; l++) {
            // La date pourrait avoir été supprimée
            if (
              s.presences[s.trainers_presences[is].presences[l].session_date_id]
            ) {
              s.presences[
                s.trainers_presences[is].presences[l].session_date_id
              ].is = true;
              s.presences[
                s.trainers_presences[is].presences[l].session_date_id
              ].is_blocked = s.trainers_presences[is].presences[l].is_blocked
                ? true
                : false;
            }
          }
        }
      }

      let pres = {};

      for (let i in s.presences) {
        if (i == event.date_id) {
          s.presences[i].is = !s.presences[i].is;
        }
        if (s.presences[i].is == true) {
          pres[i] = true;
        }
      }

      Sessions.updatePresences(s.id, { presences: pres }).then(
        () => {
          this.fetch();
          this.$snotify.success("The presence has been updated");
        },
        () => {
          this.loading = false;
          this.fetch();
        }
      );
		},
		onIcsChange(v){
			if(v && !this.icsToken){
				this.fetchIcsToken();
			}
		}
	},
}
</script>
<style scoped>
.arrow-btn{
	width: 40px;
	height: 40px;
}
.session{
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 10px;
	cursor: pointer;
}
.presence-toggle {
  flex: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}
.slide-prev-leave-active,
.slide-prev-enter-active,
.slide-next-leave-active,
.slide-next-enter-active {
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.slide-next-enter {
  transform: translate(500%, 0);
}
.slide-next-leave-to {
  transform: translate(-500%, 0);
}
.slide-prev-enter {
  transform: translate(-500%, 0);
}
.slide-prev-leave-to {
  transform: translate(500%, 0);
}
#days-slider {
  zoom: 50%;
  -moz-transform: scale(0.5);
}
@media screen and (min-width: 300px) {
  #days-slider {
    zoom: 60%;
    -moz-transform: scale(0.6);
  }
}
@media screen and (min-width: 350px) {
  #days-slider {
    zoom: 70%;
    -moz-transform: scale(0.7);
  }
}
@media screen and (min-width: 370px) {
  #days-slider {
    zoom: 80%;
    -moz-transform: scale(0.8);
  }
}
</style>
<style>
.info-icon-list > div, .info-icon-dialog > div{
	background-color: white;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  margin-right: 4px !important;
  margin-left: 0px !important;
}
.info-icon-list > div {
  margin-top: 8px !important;
}
.info-icon-dialog > div {
  margin-top: 0px !important;
}
</style>
