<template>
  <div class="field-container">
    <div :class="{grip: true, bigger: true, 'grip-disabled': editingField.id === null}"></div>
    <div class="field-content">
      <div :class="{label: true, 'need-save': hasSomethingToChange}">
        <v-icon
        v-if="editingField.has_been_used_in_evaluations"
        class="ml-2 locked"
        small
        title="This field is already used in an evaluation"
        >
          mdi-lock
        </v-icon>
        <div class="chevron">
          <v-btn
            icon
            @click="contentHidden = !contentHidden"
            :disabled="!canBeARealField"
          >
            <v-icon v-if="contentHidden">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
        <div class="name-container">
          <div
            class="name-of-field"
            ref="name-of-field"
            :contenteditable="nameIsBeeingEdited && ! editingField.has_been_used_in_evaluations"
            @click="select"
            @keydown.enter.stop.prevent="updateName"
            @blur="updateName"
            v-html="editingField.name"
          >
          </div>
          <div v-if="contentHidden && canBeARealField && editingField.id" class="mini-type">
            <em v-if="editingField.type == Consts.FIELD_TYPES.SIMPLE_TEXT">(Simple text field)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.LONG_TEXT">(Long text field)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.DATE">(Date field)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.EMAIL">(Email field)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.NUMBER">(Number field)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.SINGLE_CHOICE">(Single choice)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.MULTIPLE_CHOICES">(Multiple choices)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.RADIO_GROUP">(Group of radio buttons)</em>
            <em v-else-if="editingField.type == Consts.FIELD_TYPES.SEPARATOR">(Separator)</em>
          </div>
        </div>
        <div v-if="editingField.required" class="asterisque" >*</div>
        <div class="is-required">
          <v-checkbox
            v-model="editingField.required"
            label="is required ?"
            @change="checkForm()"
            :disabled="editingField.type === Consts.FIELD_TYPES.SEPARATOR || editingField.has_been_used_in_evaluations"
          />
        </div>
        <div class="field-actions">
          <v-btn
            icon
            title="Save the field"
            :disabled="! canBeARealField || ! hasSomethingToChange || editingField.has_been_used_in_evaluations"
            @click="saveField"
          >
            <v-icon color="primary">mdi-content-save</v-icon>
          </v-btn>
          <v-menu
            v-model="openResetModal"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            :disabled="! canBeARealField || ! hasSomethingToChange || editingField.has_been_used_in_evaluations"
          >
            <v-btn
              slot="activator"
              title="Reset field to its initial values"
              icon
              :disabled="! canBeARealField || ! hasSomethingToChange || editingField.has_been_used_in_evaluations"
            >
              <v-icon>mdi-arrow-u-down-left-bold</v-icon>
            </v-btn>

            <v-card>
              <v-card-title class="title">
                Reset the field ?
              </v-card-title>
              <v-card-text>Are you sure you want to reset this field to its initial values ?</v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  flat
                  @click="openResetModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  flat
                  color="error"
                  @click="resetField"
                >
                  Reset
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu
            v-if="! editingField.has_been_used_in_evaluations"
            v-model="openDeleteModal"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <v-btn
              slot="activator"
              title="Delete the field"
              icon
            >
              <v-icon color="error">mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-card>
              <v-card-title class="title">
                Delete the field ?
              </v-card-title>
              <v-card-text>Are you sure you want to delete this field ?</v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  flat
                  @click="openDeleteModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  flat
                  color="error"
                  @click="deleteField"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu
            v-else
            v-model="openArchiveModal"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x

          >
            <v-btn
              slot="activator"
              title="Archive"
              class="mx-0"
              icon
            >
              <v-icon>mdi-archive-settings</v-icon>
            </v-btn>

            <v-card>
              <v-card-title class="title">
                Archiving this field ?
              </v-card-title>
              <v-card-text>Are you sure you want to archive this field ?</v-card-text>
              <v-card-actions
                layout="row"
                layout-align="end center"
              >
                <v-btn
                  flat
                  @click="openArchiveModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  flat
                  color="error"
                  @click="archive"
                >
                  Archive
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
      <v-form
        v-if="canBeARealField && !contentHidden"
        ref="myFieldForm"
        @submit.stop.prevent=""
      >
        <div class="field">
          <v-btn
            icon
            large
            elevation="4"
            title="Previous type of field"
            @click="prevTypeOfField"
            :disabled="editingField.has_been_used_in_evaluations"
            >
            <v-icon color="primary">mdi-arrow-left-box</v-icon>
          </v-btn>
          <div class="effective-field">
            <div>
              <template v-if="editingField.type == Consts.FIELD_TYPES.SIMPLE_TEXT">
                <v-text-field
                  v-model="lambda.simple_txt"
                  key="simple_txt"
                  label="Simple text field"
                  placeholder="This is a simple text field"
                  outline
                  :rules="[v => !!v || !editingField.required || 'This field is required']"
                ></v-text-field>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.LONG_TEXT">
                <v-textarea
                  v-model="lambda.long_txt"
                  key="long_txt"
                  label="Long text field"
                  placeholder="This is a long text field"
                  outline
                  :rules="[v => !!v || !editingField.required || 'This field is required']"
                ></v-textarea>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.DATE">
                <v-text-field
                  v-model="lambda.date_txt"
                  key="date_txt"
                  label="Date field"
                  outline
                  @blur="lambda.date = parseDate(lambda.date_txt)"
                  :rules="[v => !!v || !editingField.required || 'This field is required']"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      ref="dateField"
                      :close-on-content-click="false"
                      :return-value.sync="lambda.date"
                      transition="scale-transition"
                      offset-y
                      full-width
                      lazy
                    >
                      <v-icon slot="activator">
                        event
                      </v-icon>
                      <v-date-picker
                        v-model="lambda.date"
                        first-day-of-week="1"
                        @input="$refs.dateField.save(lambda.date); lambda.date_txt = americanizeDate(lambda.date)"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.EMAIL">
                <v-text-field
                  v-model="lambda.email"
                  key="email"
                  label="Email field"
                  placeholder="This is an email field"
                  outline
                  prepend-inner-icon="email"
                  :rules="[
                    v => !!v || !editingField.required || 'This field is required',
                    v => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.NUMBER">
                <v-text-field
                  v-model="lambda.number"
                  key="number"
                  label="Number field"
                  placeholder="This is a numeric field"
                  outline
                  prepend-inner-icon="mdi-sort-numeric-variant"
                  :rules="[
                    v => !!v || !editingField.required || 'This field is required',
                    v => !v || /^[0-9]+$/.test(String(v).replace(/[.,]/, '').replaceAll(' ', '')) || 'The value of this field must be a number'
                  ]"
                ></v-text-field>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.SINGLE_CHOICE">
                <div class="select-container">
                  <div class="select-box">
                    <v-select
                      v-model="lambda.single_choice"
                      key="single_choice"
                      :items="editingField.options"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      label="Single choice"
                      placeholder="This is a select field with a single choice"
                      outline
                      :rules="[
                        v => !!v || !editingField.required || 'This field is required',
                      ]"
                    />
                    <div class="tip-container">
                      <v-icon>mdi-information-slab-box</v-icon>
                      <div
                        ref="tip"
                        class="field-tip"
                        :contenteditable="tipIsBeeingEdited && ! editingField.has_been_used_in_evaluations"
                        v-html="editingField.tip"
                        @blur="updateTip"
                        @keydown.enter.ctrl.exact="updateTip"
                        @keydown.enter.meta.exact="updateTip"
                        @click="selectTip"
                      ></div>
                    </div>
                  </div>
                  <div v-if="! editingField.has_been_used_in_evaluations" class="select-options-manager">
                    <v-text-field
                      ref="currentOptionEditor"
                      v-model="currentOptionEditor"
                      outline
                      hide-details
                      clearable
                      label="Fill in the name of an option here"
                      @keyup.enter.stop.prevent="addOption"
                    />
                    <div class="options-list">
                      <ul>
                        <draggable
                          :list="editingField.options"
                          ghost-class="ghost"
                        >
                          <li v-for="opt in editingField.options" :key="opt.id">
                            <div class="grip"></div>
                            <div
                              class="option-name"
                              contenteditable="true"
                              v-html="opt.name"
                              @blur="updateOptionName($event, opt)"
                              @keydown.enter.stop.prevent="updateOptionName($event, opt)"
                            ></div>
                            <v-btn
                              icon
                              title="Remove option from the list"
                              @click="dropOption(opt)"
                            >
                              <v-icon color="error">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </li>
                        </draggable>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.MULTIPLE_CHOICES">
                <div class="select-container">
                  <div class="select-box">
                    <v-select
                      v-model="lambda.multi_choices"
                      key="multi_choices"
                      :items="editingField.options"
                      item-text="name"
                      item-value="id"
                      :clearable="true"
                      label="Multiple choices"
                      placeholder="This is a select field with multiple choices"
                      outline
                      multiple
                      :rules="[
                        v => v.length > 0 || !editingField.required || 'This field is required',
                      ]"
                    />
                    <div class="tip-container">
                      <v-icon>mdi-information-slab-box</v-icon>
                      <div
                        ref="tip"
                        class="field-tip"
                        :contenteditable="tipIsBeeingEdited && ! editingField.has_been_used_in_evaluations"
                        v-html="editingField.tip"
                        @blur="updateTip"
                        @keydown.enter.ctrl.exact="updateTip"
                        @keydown.enter.meta.exact="updateTip"
                        @click="selectTip"
                      ></div>
                    </div>
                  </div>
                  <div v-if="! editingField.has_been_used_in_evaluations" class="select-options-manager">
                    <v-text-field
                      ref="currentOptionEditor"
                      v-model="currentOptionEditor"
                      outline
                      hide-details
                      clearable
                      label="Fill in the name of an option here"
                      @keyup.enter.stop.prevent="addOption"
                    />
                    <div class="options-list">
                      <ul>
                        <draggable
                          :list="editingField.options"
                          ghost-class="ghost"
                        >
                          <li v-for="opt in editingField.options" :key="opt.id">
                            <div class="grip"></div>
                            <div
                              class="option-name"
                              contenteditable="true"
                              v-html="opt.name"
                              @blur="updateOptionName($event, opt)"
                              @keydown.enter.stop.prevent="updateOptionName($event, opt)"
                            ></div>
                            <v-btn
                              icon
                              title="Remove option from the list"
                              @click="dropOption(opt)"
                            >
                              <v-icon color="error">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </li>
                        </draggable>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.RADIO_GROUP">
                <div class="select-container">
                  <div class="select-box">
                    <div v-if="editingField.options.length > 0" class="radio-group-container">
                      <v-radio-group
                        v-model="lambda.radio_group"
                        :mandatory="editingField.required"
                        key="radio_group"
                        row
                        :rules="[
                          v => !!v || !editingField.required || 'This field is required',
                        ]"
                      >
                        <v-radio
                          v-for="item in editingField.options"
                          :key="item.id"
                          :value="item.id"
                          :clearable="true"
                          :label="item.name"
                        ></v-radio>
                      </v-radio-group>
                      <a @click="lambda.radio_group = null">Empty !</a>
                    </div>
                    <em v-else>Please add options before seeing the radio buttons</em>
                    <div class="tip-container">
                      <v-icon>mdi-information-slab-box</v-icon>
                      <div
                        ref="tip"
                        class="field-tip"
                        :contenteditable="tipIsBeeingEdited && ! editingField.has_been_used_in_evaluations"
                        v-html="editingField.tip"
                        @blur="updateTip"
                        @keydown.enter.ctrl.exact="updateTip"
                        @keydown.enter.meta.exact="updateTip"
                        @click="selectTip"
                      ></div>
                    </div>
                  </div>
                  <div v-if="! editingField.has_been_used_in_evaluations" class="select-options-manager">
                    <v-text-field
                      ref="currentOptionEditor"
                      v-model="currentOptionEditor"
                      outline
                      hide-details
                      clearable
                      label="Fill in the name of an option here"
                      @keyup.enter.stop.prevent="addOption"
                    />
                    <div class="options-list">
                      <ul>
                        <draggable
                          :list="editingField.options"
                          ghost-class="ghost"
                        >
                          <li v-for="opt in editingField.options" :key="opt.id">
                            <div class="grip"></div>
                            <div
                              class="option-name"
                              contenteditable="true"
                              v-html="opt.name"
                              @blur="updateOptionName($event, opt)"
                              @keydown.enter.stop.prevent="updateOptionName($event, opt)"
                            ></div>
                            <v-btn
                              icon
                              title="Remove option from the list"
                              @click="dropOption(opt)"
                            >
                              <v-icon color="error">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </li>
                        </draggable>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="editingField.type == Consts.FIELD_TYPES.SEPARATOR">
                <div class="legend">Separator field</div>
                <div class="separator">
                  <h3>{{editingField.name}}</h3>
                  <hr/>
                </div>
              </template>
            </div>
            <div
              v-if="editingField.type != Consts.FIELD_TYPES.MULTIPLE_CHOICES && editingField.type != Consts.FIELD_TYPES.SINGLE_CHOICE && editingField.type != Consts.FIELD_TYPES.RADIO_GROUP && editingField.type != Consts.FIELD_TYPES.SEPARATOR"
              class="tip-container"
            >
              <v-icon>mdi-information-slab-box</v-icon>
              <div
                ref="tip"
                class="field-tip"
                :contenteditable="tipIsBeeingEdited && ! editingField.has_been_used_in_evaluations"
                v-html="editingField.tip"
                @blur="updateTip"
                @keydown.enter.ctrl.exact="updateTip"
                @keydown.enter.meta.exact="updateTip"
                @click="selectTip"
              ></div>
            </div>
          </div>
          <v-btn
            icon
            large
            elevation="4"
            title="Next type of field"
            @click="nextTypeOfField"
            :disabled="editingField.has_been_used_in_evaluations"
            >
              <v-icon color="primary">mdi-arrow-right-box</v-icon>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
  import Consts from '@/consts'
  import dateMixin from '@/mixins/date'
  import moment from 'moment'
  import draggable from 'vuedraggable'

  const TypesAsArray = Object.values(Consts.FIELD_TYPES)

  export default {
    mixins: [dateMixin],
    components: {draggable},
    props: {
      form: {
        type: Object,
        required: true
      },
      field: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        editingField: {
          id: null,
          name: Consts.FIELD_DEFAULTS_VALUE.NAME,
          required: false,
          tip: Consts.FIELD_DEFAULTS_VALUE.TIP,
          type: Consts.FIELD_TYPES.SIMPLE_TEXT,
          options: [],
          has_been_used_in_evaluations: false
        },
        Consts,
        nameIsBeeingEdited: false,
        tipIsBeeingEdited: false,
        currentTypeOfFieldIdx: 0,
        lambda: {
          date_txt: this.americanizeDate(moment().format("YYYY-MM-DD")),
          date: moment().format("YYYY-MM-DD"),
          email: '',
          simple_txt: '',
          long_txt: '',
          number: "42",
          single_choice: null,
          multi_choices: [],
          radio_group: null
        },
        currentOptionEditor: '',
        fakeOptionID: 1,
        openResetModal: false,
        openDeleteModal: false,
        openArchiveModal: false,
        contentHidden: true
      }
    },
    mounted () {
      this.resetField()
    },
    methods: {
      updateName (e) {
        if(this.nameIsBeeingEdited) {
          const val = e.target.innerText.trim().replaceAll('\n\n', ' ')
          if(val !== '' && this.editingField.name != val) {
            this.editingField.name = val
            this.contentHidden =false
          }
          else if(val === ''){
            return false
          }

          this.nameIsBeeingEdited = false
          if(e.type == 'keydown') {
            e.target.blur()
          }
        }
      },
      select() {
        if( ! this.nameIsBeeingEdited && ! this.editingField.has_been_used_in_evaluations) {
          this.nameIsBeeingEdited = true
          this.$nextTick(() => {
            selectElementContents(this.$refs['name-of-field'])
          })
        }
      },
      selectTip() {
        if( ! this.tipIsBeeingEdited && ! this.editingField.has_been_used_in_evaluations) {
          this.tipIsBeeingEdited = true
          this.$nextTick(() => {
            selectElementContents(this.$refs['tip'])
          })
        }
      },
      updateTip (e) {
        if(this.tipIsBeeingEdited) {
          this.editingField.tip = e.target.innerText.trim().replaceAll('\n', '</br>')
          this.tipIsBeeingEdited = false
          if(e.type == 'keydown') {
            e.target.blur()
          }
        }
      },
      prevTypeOfField () {
        if(this.currentTypeOfFieldIdx === 0) {
          this.currentTypeOfFieldIdx = TypesAsArray.length - 1
        }
        else {
          this.currentTypeOfFieldIdx--
        }
        this.editingField.type = TypesAsArray[this.currentTypeOfFieldIdx]
        if(this.editingField.type === Consts.FIELD_TYPES.SEPARATOR) {
          this.editingField.required = false
        }
        this.$nextTick(() => {
          this.$refs.myFieldForm.validate()
        })
      },
      nextTypeOfField () {
        if(this.currentTypeOfFieldIdx === TypesAsArray.length - 1) {
          this.currentTypeOfFieldIdx = 0
        }
        else {
          this.currentTypeOfFieldIdx++
        }
        this.editingField.type = TypesAsArray[this.currentTypeOfFieldIdx]
        if(this.editingField.type === Consts.FIELD_TYPES.SEPARATOR) {
          this.editingField.required = false
        }
        this.$nextTick(() => {
          this.$refs.myFieldForm.validate()
        })
      },
      americanizeDate(date) {
        if (!date) return null;

        if (date.indexOf(".") >= 0) {
          return date;
        }

        return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
      },
      parseDate(date) {
        if (!date) return null;

        if (date.indexOf("-") >= 0) {
          return date;
        }

        return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
      },
      addOption() {
        if(this.currentOptionEditor.trim() !== "") {
          this.editingField.options.push({id: this.fakeOptionID, name: this.currentOptionEditor, position: this.editingField.options.length+1})
          this.fakeOptionID++
          this.currentOptionEditor = ""
          this.$refs.currentOptionEditor.focus()
        }
      },
      updateOptionName(e, option) {
        option.name = e.target.innerText.trim().replaceAll('\n\n', ' ')
        if(e.type == 'keydown') {
          e.target.blur()
        }
      },
      dropOption(option) {
        this.editingField.options.splice(this.editingField.options.indexOf(option), 1)
      },
      checkForm () {
        if(this.canBeARealField) {
          this.$refs.myFieldForm.validate()
        }
      },
      saveField () {
        this.$emit('save', this.editingField)
      },
      resetField () {
        this.editingField = structuredClone(this.field)
        this.editingField.has_been_used_in_evaluations = !!this.editingField.has_been_used_in_evaluations

        this.currentTypeOfFieldIdx = TypesAsArray.indexOf(this.editingField.type)
        if(this.currentTypeOfFieldIdx < 0) {
          this.currentTypeOfFieldIdx = 0
        }
        this.openResetModal = false
      },
      deleteField () {
        this.$emit('delete', this.editingField)
        this.openDeleteModal = false
      },
      archive () {
        this.$emit('archive', this.editingField)
        this.openArchiveModal = false
      }
    },
    computed: {
      canBeARealField () {
        return this.editingField.id != null || this.editingField.name != Consts.FIELD_DEFAULTS_VALUE.NAME
      },
      hasSomethingToChange () {
        return this.field.name != this.editingField.name
            || this.field.required != this.editingField.required
            || this.field.type != this.editingField.type
            || this.field.tip != this.editingField.tip
            || (
              ( this.editingField.type == Consts.FIELD_TYPES.SINGLE_CHOICE
                || this.editingField.type == Consts.FIELD_TYPES.MULTIPLE_CHOICES
                || this.editingField.type == Consts.FIELD_TYPES.RADIO_GROUP
              ) && this.hasOptionsDifference
            )
      },
      hasOptionsDifference() {
        if(this.field.options.length != this.editingField.options.length) {
          return true
        }
        for(let i=0; i < this.field.options.length ; i++) {
          if( ! this.editingField.options[i] || this.editingField.options[i].id != this.field.options[i].id || this.editingField.options[i].name != this.field.options[i].name) {
            return true
          }
        }
        return false
      }
    }
  }

  function selectElementContents(el) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
</script>
<style scoped lang="sass">
  .field-container
    border-radius: 5px
    border: 1px solid #F1F1F1
    display: flex
    flex-direction: row
    background-color: white

    .field-content
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px
      flex-grow: 1
      padding: 5px

  .field-container + .field-container
    margin-top: 10px

  .label
    background-color: #F1F1F1
    border-radius: 3px
    display: flex
    flex-direction: row

    &.need-save
      background: linear-gradient(135deg, #fcf4d6 12.5%, #fffbeb 12.5%, #fffbeb 37.5%, #fcf4d6 37.5%, #fcf4d6 62.5%, #fffbeb 62.5%, #fffbeb 87.5%, #fcf4d6 87.5%)
      background-size: 100px 100px
      background-position: 50px 50px

    .chevron
      align-self: center
      width: 35px

    .name-container
      align-self: center
      flex-grow: 1

      .name-of-field
        padding: 5px 15px
        font-size: 1.3rem
        outline: none
        font-weight: bold

      .mini-type
        color: #555555
        padding-left: 18px
        font-size: 0.9rem

    .is-required
      align-self: center
      margin-right: 20px

    .field-actions
      align-self: center

    .asterisque
      width: 30px
      color: tomato
      font-size: 3rem
      align-self: center

  .field
    margin-top: 20px
    display: flex
    flex-direction: row

    .effective-field
      flex-grow: 1


  .select-container
    display: flex
    flex-direction: row
    flex-wrap: wrap

    .select-box
      flex-grow: 1

    .select-options-manager
      min-width: 40%
      padding-left: 15px
      font-size: 1.1rem

      .options-list
        margin-top: 10px

        ul
          list-style: none
          list-style-type: none
          margin: 0
          padding: 0

          li
            height: 40px
            display: flex
            flex-direction: row
            border-radius: 3px
            margin-bottom: 3px
            cursor: pointer

            &:nth-child(odd)
              background-color: #F5F5F5

            .option-name
              padding-left: 10px
              line-height: 40px
              outline: none
              flex-grow: 1

  .ghost
    opacity: 0.5
    background: #fef1bf

  .tip-container
    display: flex
    flex-direction: row
    align-items: flex-start

    .field-tip
      flex-grow: 1
      margin-left: 5px
      margin-bottom: 10px
      background-color: #F1F1F1
      border-radius: 3px
      font-style: italic
      color: #555555
      font-size: 0.9rem
      outline: none
      min-height: 40px
      padding: 10px


  .grip
    width: 15px
    background-color: #f4b944
    cursor: grab
    cursor: -webkit-grab
    display: flex

    &.bigger
      width: 25px

    &.grip-disabled
      background-color: #CCCCCC
      cursor: auto
      cursor: -webkit-auto

    > v-icon
      align-self: center

  .radio-group-container
    display: flex
    flex-direction: row

    > *
      align-self: center

    .v-input--radio-group
      flex-shrink: 1
      flex-grow: 0

    a
      display: inline-block
      margin-bottom: 5px
      margin-left: 10px
      text-decoration: underline

  .separator
    display: flex
    flex-direction: row
    justify-content: center

    hr
      flex-grow: 1
      align-self: center

    h3
      margin-right: 20px

  .legend
    color: #555555
    font-size: 0.9rem

  .locked
    color: #DDDDDD
</style>
