var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"layout":"column","layout-align":"center center"}},[_c('div',{staticClass:"field-width",attrs:{"layout":"column"}},[_c('div',{attrs:{"layout":"row","layout-align":"start center"}},[_c('v-select',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"mr-2",attrs:{"solo":"","single-line":"","hide-details":"","label":"Application","items":[
          {value: '', text: 'All'},
          {value: 'null', text: 'None'},
          {value: _vm.Consts.APPLICATION.BEESECURE, text: 'BEE SECURE'},
          {value: _vm.Consts.APPLICATION.FREELANCES, text: 'FREELANCES'}
        ]},on:{"change":_vm.fetch},model:{value:(_vm.filters.application),callback:function ($$v) {_vm.$set(_vm.filters, "application", $$v)},expression:"filters.application"}}),_c('v-select',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"mr-2",attrs:{"solo":"","single-line":"","hide-details":"","label":"Concept","items":_vm.concepts,"item-value":"id","item-text":"name"},on:{"change":_vm.fetch},model:{value:(_vm.filters.topic_id),callback:function ($$v) {_vm.$set(_vm.filters, "topic_id", $$v)},expression:"filters.topic_id"}}),_c('v-text-field',{attrs:{"solo":"","label":"Keywords","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fetch.apply(null, arguments)}},model:{value:(_vm.filters.keywords),callback:function ($$v) {_vm.$set(_vm.filters, "keywords", $$v)},expression:"filters.keywords"}}),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.fetch}},[_vm._v(" Filter ")]),_vm._v(" or "),_c('a',{staticClass:"ml-2 black--text",attrs:{"href":"#"},on:{"click":_vm.resetFilters}},[_vm._v("Cancel")])],1),_c('div',[_c('v-btn',{staticClass:"mt-3",attrs:{"flat":"","to":{ name: 'admin-settings-users-edit', params: { id: 'new' } }}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v(" mdi-plus-box ")]),_vm._v(" Add a new user ")],1)],1),_c('v-data-table',{staticStyle:{"max-width":"1000px"},attrs:{"headers":_vm.headers,"items":_vm.users,"pagination":_vm.pagination,"custom-sort":_vm.customSort},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('td',{staticClass:"text-xs-center"},[_c('v-icon',{attrs:{"color":item.is_enabled
                ? 'success'
                : item.validation_waiting
                  ? 'warning'
                  : 'error',"title":item.is_enabled
                ? 'Enabled'
                : item.validation_waiting
                  ? 'Waiting'
                  : 'Disabled'}},[_vm._v(" mdi-circle-outline ")])],1),_c('td',{staticClass:"text-xs-center"},[([_vm.Consts.APPLICATION.ALL, _vm.Consts.APPLICATION.BEESECURE].includes(item.application))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black","small":""}},[_vm._v(" icon-bee48 ")]):_vm._e(),([_vm.Consts.APPLICATION.ALL, _vm.Consts.APPLICATION.FREELANCES].includes(item.application))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black","small":""}},[_vm._v(" icon-kayak48 ")]):_vm._e()],1),_c('td',[_c('router-link',{attrs:{"to":{
              name: 'admin-settings-users-show',
              params: { id: item.id }
            }}},[_vm._v(" "+_vm._s(item.firstname)+" ")])],1),_c('td',[_vm._v(_vm._s(item.lastname))]),_c('td',_vm._l((item.groups),function(t){return _c('div',{key:item.id + t.id},[_vm._v(" "+_vm._s(t.name)+" ")])}),0),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',{attrs:{"layout":"row"}},[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","to":{
              name: 'admin-settings-users-show-info',
              params: { id: item.id }
            }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(item.validation_waiting)?[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","title":"Validate"},on:{"click":function($event){return _vm.toggle(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-account-check ")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","title":"Refuse"},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-account-remove ")])],1)]:[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","disabled":_vm.Auth.user.id == item.id},on:{"click":function($event){return _vm.toggle(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.is_enabled ? "mdi-lock" : "mdi-lock-open")+" ")])],1)]],2)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }