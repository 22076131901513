<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions <v-icon>mdi-chevron-right</v-icon> Events packages management <v-icon>mdi-chevron-right</v-icon> {{ isNew ? 'New' : pack.name }}
    </div>

    <v-form
      ref="form"
      v-model="formValid"
      layout="column"
      layout-align="center center"
      @submit.prevent="save"
    >
      <div>
        <div class="title font-weight-light mb-3">
          Package information
        </div>
        <v-text-field
          v-model="pack.name"
          outline
          label="Name"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-text-field
          v-model="pack.code"
          outline
          label="Code"
          class="field-width"
          :rules="[ v => !!v || 'This field is required' ]"
        />
        <v-text-field
          v-model="numDay"
          type="number"
          outline
          label="Number of days"
          class="field-width"
          :rules="[
            v => /^[0-9]+$/.test(v) || 'This field must be a number',
            v => (!!v && v > 0) || 'This field is required'
          ]"
          @input="changeNbDay"
        />

        <div class="title font-weight-light mb-3">
          Package days
        </div>
        <v-data-table
          :headers="headers"
          :items="pack.days"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>{{ item.day }}</td>
            <td style="width:415px;">
              <v-select
                v-model="item.morning_topic_id"
                :items="topics"
                item-text="name"
                item-value="id"
                label="Concept"
                outline
                class="mt-2"
                hide-details
                @change="updateConceptHours(item, 'morning')"
              />
              <div class="d-inline-flex">
                <v-text-field
                  v-model="item.morning_start"
                  outline
                  hide-details
                  class="mt-2"
                  label="Start (work)"
                  placeholder="HH:MM"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.morning_end || !v || parseInt(item.morning_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                  ]"
                  @change="updateDuration(item, 'morning')"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'morningStartMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.morning_start"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.morning_start"
                        format="24hr"
                        @click:minute="$refs['morningStartMenu'+item.day].save(item.morning_start); item.clock_start_text = item.morning_start; updateDuration(item, 'morning')"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
                <v-text-field
                  v-model="item.morning_end"
                  outline
                  hide-details
                  class="mt-2 mb-2"
                  label="End (work)"
                  placeholder="HH:MM"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.morning_start || !v || parseInt(v.replace(':','')) >= parseInt(item.morning_start.replace(':','')) || 'End time must be greater than start time',
                  ]"
                  @change="updateDuration(item, 'morning')"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'morningEndMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.morning_end"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.morning_end"
                        format="24hr"
                        @change="$refs['morningEndMenu'+item.day].save(item.morning_end); item.clock_start_text = item.morning_end; updateDuration(item, 'morning')"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="item.morning_total_time"
                  label="Work duration (decimals hours)"
                  hide-details
                />
              </div>
              <div class="d-inline-flex">
                <v-text-field
                  v-model="item.morning_participants_start"
                  outline
                  hide-details
                  class="mt-2"
                  label="Start (participants)"
                  placeholder="HH:MM"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.morning_participants_end || !v || parseInt(item.morning_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                  ]"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'morningParticipantsStartMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.morning_participants_start"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.morning_participants_start"
                        format="24hr"
                        @change="$refs['morningParticipantsStartMenu'+item.day].save(item.morning_participants_start); item.clock_start_text = item.morning_participants_start"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
                 <v-text-field
                  v-model="item.morning_participants_end"
                  outline
                  hide-details
                  class="mt-2 mb-2"
                  label="End (participants)"
                  placeholder="HH:MM"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.morning_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(item.morning_participants_start.replace(':','')) || 'End time must be greater than start time',
                  ]"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'morningParticipantsEndMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.morning_participants_end"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.morning_participants_end"
                        format="24hr"
                        @change="$refs['morningParticipantsEndMenu'+item.day].save(item.morning_participants_end); item.clock_start_text = item.morning_participants_end"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
              </div>

            </td>
            <td style="width:415px;">
              <v-select
                v-model="item.afternoon_topic_id"
                :items="topics"
                item-text="name"
                item-value="id"
                label="Concept"
                outline
                class="mt-2"
                hide-details
                @change="updateConceptHours(item, 'afternoon')"
              />
              <div class="d-inline-flex">
                <v-text-field
                  v-model="item.afternoon_start"
                  outline
                  label="Start (work)"
                  placeholder="HH:MM"
                  hide-details
                  class="mt-2"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.afternoon_end || !v || parseInt(item.afternoon_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                  ]"
                  @change="updateDuration(item, 'afternoon')"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'afternoonStartMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.afternoon_start"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.afternoon_start"
                        format="24hr"
                        @change="$refs['afternoonStartMenu'+item.day].save(item.afternoon_start); item.clock_start_text = item.afternoon_start; updateDuration(item, 'afternoon')"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
                <v-text-field
                  v-model="item.afternoon_end"
                  outline
                  label="End (work)"
                  placeholder="HH:MM"
                  hide-details
                  class="mt-2 mb-2"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.afternoon_start || !v || parseInt(v.replace(':','')) >= parseInt(item.afternoon_start.replace(':','')) || 'End time must be greater than start time',
                  ]"
                  @change="updateDuration(item, 'afternoon')"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'afternoonEndMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.afternoon_end"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.afternoon_end"
                        format="24hr"
                        @change="$refs['afternoonEndMenu'+item.day].save(item.afternoon_end); item.clock_start_text = item.afternoon_end; updateDuration(item, 'afternoon')"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="item.afternoon_total_time"
                  label="Work duration (decimals hours)"
                  hide-details
                />
              </div>
              <div class="d-inline-flex">
                <v-text-field
                  v-model="item.afternoon_participants_start"
                  outline
                  label="Start (participants)"
                  placeholder="HH:MM"
                  hide-details
                  class="mt-2"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.afternoon_participants_end || !v || parseInt(item.afternoon_participants_end.replace(':','')) >= parseInt(v.replace(':','')) || 'End time must be greater than start time',
                  ]"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'afternoonStartParticipantsMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.afternoon_participants_start"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.afternoon_participants_start"
                        format="24hr"
                        @change="$refs['afternoonStartParticipantsMenu'+item.day].save(item.afternoon_participants_start); item.clock_start_text = item.afternoon_participants_start"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
                <v-text-field
                  v-model="item.afternoon_participants_end"
                  outline
                  label="End (participants)"
                  placeholder="HH:MM"
                  hide-details
                  class="mt-2 mb-2"
                  :rules="[
                    v => !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'The time format is not valid (ex: HH:MM)',
                    v => !item.afternoon_participants_start || !v || parseInt(v.replace(':','')) >= parseInt(item.afternoon_participants_start.replace(':','')) || 'End time must be greater than start time',
                  ]"
                >
                  <div slot="prepend-inner">
                    <v-menu
                      :ref="'afternoonParticipantsEndMenu'+item.day"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.afternoon_participants_end"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-icon slot="activator">
                        access_time
                      </v-icon>
                      <v-time-picker
                        v-model="item.afternoon_participants_end"
                        format="24hr"
                        @change="$refs['afternoonParticipantsEndMenu'+item.day].save(item.afternoon_participants_end); item.clock_start_text = item.afternoon_participants_end"
                      />
                    </v-menu>
                  </div>
                </v-text-field>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
      <v-btn
        depressed
        color="primary"
        @click="save"
      >
        Save
      </v-btn>
      <router-link
        :to="{name: 'admin-sessions-packages-index'}"
        class="grey--text"
      >
        Cancel
      </router-link>
    </v-form>
  </div>
</template>

<script>
import Params from "@/api/params";
import Dates from "@/mixins/date";
import Consts from "@/consts";

export default {
  mixins: [Dates],
  data() {
    return {
      pack: {
        name: null,
        code: null,
        days: []
      },
      numDay: 3,
      formValid: false,
      headers: [
        { text: "Day", value: "day", sortable: false },
        { text: "Morning", value: "morning", sortable: false },
        { text: "Afternoon", value: "afternoon", sortable: false }
      ],
      Consts,
      topics: []
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === "new";
    }
  },
  mounted() {
    Params.getList("topics", {
      application: this.Consts.APPLICATION.FREELANCES,
      is_enabled: 1
    }).then(res => {
      this.topics = res.data.map(p => {
        if (p.morning_start) {
          p.morning_start = p.morning_start
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.morning_end) {
          p.morning_end = p.morning_end
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.afternoon_start) {
          p.afternoon_start = p.afternoon_start
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.afternoon_end) {
          p.afternoon_end = p.afternoon_end
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.morning_participants_start) {
          p.morning_participants_start = p.morning_participants_start
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.morning_participants_end) {
          p.morning_participants_end = p.morning_participants_end
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.afternoon_participants_start) {
          p.afternoon_participants_start = p.afternoon_participants_start
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if (p.afternoon_participants_end) {
          p.afternoon_participants_end = p.afternoon_participants_end
            .split(":")
            .slice(0, 2)
            .join(":");
        }
        if(p.morning_total_time) {
            p.morning_total_time = p.morning_total_time.replace('.', ',')
          }
          if(p.afternoon_total_time) {
            p.afternoon_total_time = p.afternoon_total_time.replace('.', ',')
          }
        return p;
      });
      this.topics.push({
        id: "",
        name: "",
        morning_start: "",
        morning_end: "",
        morning_total_time: "",
        afternoon_start: "",
        afternoon_end: "",
        afternoon_total_time: "",
        morning_participants_start: "",
        morning_participants_end: "",
        afternoon_participants_start: "",
        afternoon_participants_end: ""
      });
      this.topics.sort(
        (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
      );
    });
    if (this.isNew) {
      this.numDay = 3;
      this.changeNbDay();
    } else {
      this.fetch();
    }
  },
  methods: {
    fetch() {
      Params.get("packages", this.$route.params.id).then(res => {
        this.pack = res.data;
        this.pack.days.map(p => {
          if (p.morning_start) {
            p.morning_start = p.morning_start
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.morning_end) {
            p.morning_end = p.morning_end
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.afternoon_start) {
            p.afternoon_start = p.afternoon_start
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.afternoon_end) {
            p.afternoon_end = p.afternoon_end
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.morning_participants_start) {
            p.morning_participants_start = p.morning_participants_start
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.morning_participants_end) {
            p.morning_participants_end = p.morning_participants_end
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.afternoon_participants_start) {
            p.afternoon_participants_start = p.afternoon_participants_start
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if (p.afternoon_participants_end) {
            p.afternoon_participants_end = p.afternoon_participants_end
              .split(":")
              .slice(0, 2)
              .join(":");
          }
          if(p.morning_total_time) {
            p.morning_total_time = p.morning_total_time.replace('.', ',')
          }
          if(p.afternoon_total_time) {
            p.afternoon_total_time = p.afternoon_total_time.replace('.', ',')
          }
          return p;
        });
        this.numDay = this.pack.days.length;
      });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isNew) {
        Params.create("packages", this.pack).then(() => {
          this.$router.push({ name: "admin-sessions-packages-index" });
          this.$snotify.success(
            `The pack "${this.pack.name}" has been created`
          );
        });
      } else {
        Params.update("packages", this.pack.id, this.pack).then(() => {
          this.$router.push({ name: "admin-sessions-packages-index" });
          this.$snotify.success(
            `The pack "${this.pack.name}" has been updated`
          );
        });
      }
    },
    changeNbDay() {
      this.numDay = parseInt(this.numDay);
      if (this.numDay > this.pack.days.length) {
        this.pack.days = Object.assign(
          [...Array(this.numDay).keys()].map((_, n) => {
            return {
              day: n + 1,
              morning_topic_id: "",
              morning_start: "",
              morning_end: "",
              morning_total_time: "",
              afternoon_topic_id: "",
              afternoon_start: "",
              afternoon_end: "",
              afternoon_total_time: "",
              morning_participants_start: "",
              morning_participants_end: "",
              afternoon_participants_start: "",
              afternoon_participants_end: ""
            };
          }),
          this.pack.days
        );
      } else {
        this.pack.days = this.pack.days.slice(0, this.numDay);
      }
    },
    updateConceptHours(item, field) {
      const topic = this.topics.filter(
        t => t.id === item[`${field}_topic_id`]
      )[0];
      if (topic) {
        item[`${field}_start`] = topic[`${field}_start`].slice(0);
        item[`${field}_end`] = topic[`${field}_end`].slice(0);
        item[`${field}_total_time`] = topic[`${field}_total_time`];
        item[`${field}_participants_start`] = topic[`${field}_participants_start`].slice(0);
        item[`${field}_participants_end`] = topic[`${field}_participants_end`].slice(0);
      } else {
        item[`${field}_start`] = "";
        item[`${field}_end`] = "";
        item[`${field}_total_time`] = "";
        item[`${field}_participants_start`] = "";
        item[`${field}_participants_end`] = "";
        item[`${field}_topic_id`] = "";
      }
    },
    updateDuration (d, type) {
      d[`${type}_total_time`] = (""+Math.round(this.getDecimalHours(d[`${type}_start`], d[`${type}_end`])*100)/100).replace('.', ',')
    }
  }
};
</script>
