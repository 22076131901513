<template>
  <div
    layout="column"
    layout-align="center center"
  >
    <div
      layout="column"
      class="field-width"
    >
      <div
        layout="row"
        layout-align="start center"
      >
        <v-select
          v-model="filters.application"
          v-focus
          solo
          single-line
          hide-details
          label="Application"
          :items="[
            {value: '', text: 'All'},
            {value: 'null', text: 'None'},
            {value: Consts.APPLICATION.BEESECURE, text: 'BEE SECURE'},
            {value: Consts.APPLICATION.FREELANCES, text: 'FREELANCES'}
          ]"
          class="mr-2"
          @change="fetch"
        />
        <v-select
          v-model="filters.topic_id"
          v-focus
          solo
          single-line
          hide-details
          label="Concept"
          :items="concepts"
          item-value="id"
          item-text="name"
          class="mr-2"
          @change="fetch"
        />
        <v-text-field
          v-model="filters.keywords"
          solo
          label="Keywords"
          hide-details
          @keyup.enter="fetch"
        />
        <v-btn
          depressed
          color="primary"
          @click="fetch"
        >
          Filter
        </v-btn>
        or
        <a
          href="#"
          class="ml-2 black--text"
          @click="resetFilters"
        >Cancel</a>
      </div>

      <div>
        <v-btn
          flat
          class="mt-3"
          :to="{ name: 'admin-settings-users-edit', params: { id: 'new' } }"
        >
          <v-icon
            left
            color="primary"
          >
            mdi-plus-box
          </v-icon>
          Add a new user
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="users"
        style="max-width: 1000px"
        :pagination.sync="pagination"
        :custom-sort="customSort"
      >
        <template
          slot="items"
          slot-scope="{ item }"
        >
          <td class="text-xs-center">
            <v-icon
              :color="
                item.is_enabled
                  ? 'success'
                  : item.validation_waiting
                    ? 'warning'
                    : 'error'
              "
              :title="
                item.is_enabled
                  ? 'Enabled'
                  : item.validation_waiting
                    ? 'Waiting'
                    : 'Disabled'
              "
            >
              mdi-circle-outline
            </v-icon>
          </td>
          <td class="text-xs-center">
            <v-icon
              v-if="[Consts.APPLICATION.ALL, Consts.APPLICATION.BEESECURE].includes(item.application)"
              color="black"
              class="mr-1"
              small
            >
              icon-bee48
            </v-icon>
            <v-icon
              v-if="[Consts.APPLICATION.ALL, Consts.APPLICATION.FREELANCES].includes(item.application)"
              color="black"
              class="mr-1"
              small
            >
              icon-kayak48
            </v-icon>
          </td>
          <td>
            <router-link
              :to="{
                name: 'admin-settings-users-show',
                params: { id: item.id }
              }"
            >
              {{ item.firstname }}
            </router-link>
          </td>
          <td>{{ item.lastname }}</td>
          <td>
            <div
              v-for="t in item.groups"
              :key="item.id + t.id"
            >
              {{ t.name }}
            </div>
          </td>
          <td>{{ item.email }}</td>
          <td layout="row">
            <v-btn
              class="mx-0"
              icon
              :to="{
                name: 'admin-settings-users-show-info',
                params: { id: item.id }
              }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <template v-if="item.validation_waiting">
              <v-btn
                class="mx-0"
                icon
                title="Validate"
                @click="toggle(item)"
              >
                <v-icon color="green">
                  mdi-account-check
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-0"
                icon
                title="Refuse"
                @click="remove(item)"
              >
                <v-icon color="red">
                  mdi-account-remove
                </v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="mx-0"
                icon
                :disabled="Auth.user.id == item.id"
                @click="toggle(item)"
              >
                <v-icon>
                  {{
                    item.is_enabled ? "mdi-lock" : "mdi-lock-open"
                  }}
                </v-icon>
              </v-btn>
            </template>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Params from "@/api/params";
import Auth from "@/plugins/auth";
import Consts from "@/consts";
import GlobalMixin from "@/mixins/global"

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        { text: "Status", value: "is_enabled", sortable: false, width: 40 },
        { text: "Application", value: "application", align: 'center', sortable: false, width: 75 },
        { text: "First name", value: "firstname" },
        { text: "Last name", value: "lastname" },
        { text: "Groups", value: "", sortable: false },
        { text: "E-mail", value: "email" },
        { text: "Actions", sortable: false, width: "80px" }
      ],
      filters: {
        application: '',
        keywords: null,
        topic_id: null,
      },
      concepts: [],
      users: [],
      Auth,
      Consts
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      Params.getList("users", this.filters).then(res => {
        this.users = res.data;
      });
      let conditions = {is_enabled: 1}
      if(this.filters.application !== '') {
        conditions.application = this.filters.application
      }
      Params.getList("topics", conditions).then(res => {
        this.concepts = res.data
        this.concepts.unshift({id: null, name: 'All'})
      })
    },
    resetFilters() {
      this.filters.keywords = null
      this.filters.application = ''
      this.filters.topic_id = null

      this.fetch();
    },
    toggle(item) {
      if (item.is_enabled) {
        Params.disable("users", item.id).then(() => {
          this.fetch();
          this.$snotify.success(
            `The user "${item.firstname} ${item.lastname}" has been disabled`
          );
        });
      } else if (!item.validation_waiting) {
        Params.enable("users", item.id).then(() => {
          this.fetch();
          this.$snotify.success(
            `The user "${item.firstname} ${item.lastname}" has been enabled`
          );
        });
      } else {
        Params.enable("users", item.id).then(() => {
          this.fetch();
          this.$snotify.success(
            `The user "${item.firstname} ${item.lastname}" has been validated`
          );
        });
      }
    },
    remove(item) {
      if (!item.is_enabled && item.validation_waiting) {
        Params.delete("users", item.id).then(() => {
          this.fetch();
          this.$snotify.success(
            `The user "${item.firstname} ${item.lastname}" has been rejected`
          );
        });
      }
    },
    customSort(items, index, isDescending) {
      if (index === null) return items;

      return items.sort((a, b) => {
        if (a.validation_waiting === b.validation_waiting) {
          let sortA = this.getObjectValueByPath(a, index);
          let sortB = this.getObjectValueByPath(b, index);

          if (isDescending) {
            [sortA, sortB] = [sortB, sortA];
          }

          // Check if both are numbers
          if (!isNaN(sortA) && !isNaN(sortB)) {
            return sortA - sortB;
          }

          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            return 0;
          }

          [sortA, sortB] = [sortA, sortB].map(s =>
            (s || "").toString().toLocaleLowerCase()
          );

          if (sortA > sortB) return 1;
          if (sortA < sortB) return -1;
        } else {
          return a.validation_waiting > b.validation_waiting ? -1 : 1;
        }

        return 0;
      });
    },
    getObjectValueByPath(obj, path, fallback) {
      // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
      if (!path || path.constructor !== String) return fallback;
      path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      path = path.replace(/^\./, ""); // strip a leading dot
      return this.getNestedValue(obj, path.split("."), fallback);
    },
    getNestedValue(obj, path, fallback) {
      const last = path.length - 1;

      if (last < 0) return obj === undefined ? fallback : obj;

      for (let i = 0; i < last; i++) {
        if (obj == null) {
          return fallback;
        }
        obj = obj[path[i]];
      }

      if (obj == null) return fallback;

      return obj[path[last]] === undefined ? fallback : obj[path[last]];
    }
  }
};
</script>
