<template>
  <div class="ma-3" v-if="form">
    <div class="title mb-3">
      Forms
      <v-icon>mdi-chevron-right</v-icon>
      {{ form.name }}
    </div>
    <router-link
      :to="{name: 'admin-forms-index'}"
      class="link-unstyled py-2 no-printable"
      style="width: max-content"
    >
      <v-icon
        color="primary"
        size="16"
      >
        mdi-arrow-left
      </v-icon>
      Back to the list
    </router-link>
    <section class="py2">
      <draggable
        :list="fields"
        draggable=".is_draggable"
        ghost-class="ghost"
        @end="endDrag($event)"
      >
        <field
          v-for="(f, idx) in fields"
          :key="`${f.id}-${f.date_ref}`"
          :form="form"
          :field="f"
          @save="editedF => onSaveField(idx, editedF)"
          @delete="editedF => onDeleteField(idx, editedF)"
          @archive="editedF => onArchiveField(idx, editedF)"
          :class="{is_draggable: f.id != null}"
        />
      </draggable>
    </section>
    <div v-if="addingFieldIsPossible">
      <v-btn @click="addNewField">Add a new field</v-btn>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import Field from '@/components/admin/forms/fields/Field.vue'
  import Consts from '@/consts'
  import draggable from 'vuedraggable'


  const defaultField = {
          id: null,
          name: Consts.FIELD_DEFAULTS_VALUE.NAME,
          required: false,
          tip: Consts.FIELD_DEFAULTS_VALUE.TIP,
          type: Consts.FIELD_TYPES.SIMPLE_TEXT,
          options: [],
          date_ref: "XXXX-XX-XX XX:XX:XX",
          has_been_used_in_evaluations: false
        }

  export default {
    components: {Field, draggable},
    data () {
      return {
        form: null,
        fields: [],
      }
    },
    async beforeRouteEnter(to, from, next) {
      let form = await axios.get(`/params/forms/${to.params.id}`)
      let fields = await axios.get(`/params/forms/${to.params.id}/fields`)

      next(vm => {
        vm.form = form.data
        vm.fields = fields.data.map((f) => {
          f.date_ref = f.updated_at ?? f.created_at // sert à la ref pour être sûr que ça refresh bien en remplaçant l'objet dans la liste
          if(f.tip === "") {
            f.tip = Consts.FIELD_DEFAULTS_VALUE.TIP
          }
          return f
        })
        if(vm.fields.length === 0) {
          vm.addNewField()
        }
      })
    },
    methods: {
      onSaveField (idx, f) {
        if(f.type !== Consts.FIELD_TYPES.SINGLE_CHOICE && f.type !== Consts.FIELD_TYPES.MULTIPLE_CHOICES && f.type !== Consts.FIELD_TYPES.RADIO_GROUP) {
          f.options = [] //reset des options
        }
        if(f.tip === Consts.FIELD_DEFAULTS_VALUE.TIP) {
          f.tip = "" //on nettoie
        }

        if(f.id === null) { //create
          axios.post(`/params/forms/${this.form.id}/fields`, f).then(res => {
            f = res.data
            f.date_ref = f.updated_at ?? f.created_at // sert à la ref pour être sûr que ça refresh bien en remplaçant l'objet dans la liste
            if(f.tip === "") {
              f.tip = Consts.FIELD_DEFAULTS_VALUE.TIP
            }
            if(f.has_been_used_in_evaluations === "0") { //petit hack après création il arrive en mode string
              f.has_been_used_in_evaluations = false
            }
            this.fields.splice(idx, 1, f)
            this.$snotify.success(`The field "${f.name}" has been successfully saved`)
          })
        }
        else { //update
          axios.put(`/params/forms/${this.form.id}/fields/${f.id}`, f).then(res => {
            f = res.data
            f.date_ref = f.updated_at ?? f.created_at // sert à la ref pour être sûr que ça refresh bien en remplaçant l'objet dans la liste
            if(f.tip === "") {
              f.tip = Consts.FIELD_DEFAULTS_VALUE.TIP
            }
            if(f.has_been_used_in_evaluations === "0") {
              f.has_been_used_in_evaluations = false
            }
            this.fields.splice(idx, 1, f)
            this.$snotify.success(`The field "${f.name}" has been successfully saved`)
          })
        }
      },
      onDeleteField (idx, f) {
        if(f.id === null) {
          this.fields.splice(idx, 1)
          if(this.fields.length === 0) {
            this.addNewField()
          }
        }
        else { //delete BE
          axios.delete(`/params/forms/${this.form.id}/fields/${f.id}`).then(() => {
            this.fields.splice(idx, 1)
            this.$snotify.success(`The field has been successfully deleted`)
            if(this.fields.length === 0) {
              this.addNewField()
            }
          })
        }
      },
      onArchiveField (idx, f) {
        axios.patch(`/params/forms/${this.form.id}/fields/${f.id}/archive`).then(() => {
          this.fields.splice(idx, 1)
          this.$snotify.success(`The field has been successfully archived`)
          if(this.fields.length === 0) {
            this.addNewField()
          }
        })
      },
      addNewField () {
        this.fields.push(Object.assign(defaultField))
      },
      endDrag(e) {
        if(e.oldIndex != e.newIndex) {
          const f = this.fields[e.newIndex] //il est déjà déplacé du coup
          f.position = e.newIndex+1
          axios.put(`/params/forms/${this.form.id}/fields/${f.id}`, f).then(() => {
            this.$snotify.success(`The field "${f.name}" has been successfully moved`)
          })
        }
      }
    },
    computed: {
      addingFieldIsPossible () {
        return ! this.fields.find(e => e.id === null)
      }
    }
  }
</script>

<style scoped lang="sass">
  section
    background-color: white
    padding: 20px
</style>
